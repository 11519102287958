import React, {useState, useContext} from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../Assets/event logo.png";
import { FaEnvelope } from "react-icons/fa";
import coverImage from "../Assets/Vector5.png";
import { DataContext } from "../../context/DataState";


function ForgotPassword() {
  const navigate = useNavigate();
  

  const {
    setButtonLoader,
    ButtonLoader,forgotPasswordApi
  } = useContext(DataContext);

  const [state, setState] = useState({
    email: "",
  });

  const [emailError, setEmailError] = useState(false);

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      setButtonLoader(false);
    }
  };
  
  const buttonDisabled = state.email !== "";

  const handleLogin = () => {
    setButtonLoader(true);
    forgotPasswordApi(state)
  };


  return (
    <>
      <div
        className="w-full h-screen flex items-center justify-center flex-col bg-contain bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${coverImage})`}}
      >
        <div className="w-[33%] xl:w-[90%] bg-whiterounded-md">
          <img
            src={logo}
            className=" w-[50%] m-auto pb-5 px-5 "
            alt="logo"
          ></img>
        </div>
        <div className="w-[33%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className="py-4 rounded-t-md">
            <h1 className="text-center text-[20px] font-semibold">Forgot Password</h1>
            <p className="text-center text-sm mb-3">
            Enter your Email and instructions will be sent to you!
            </p>
            {/* <img src={logo} className="w-16 h-16 bg-white rounded-[50%]  m-auto -mb-10 shadow-[#272525ce] shadow-sm" alt="favicon"></img> */}
          </div>
          <hr />
          <div className="p-10">
            <form>
              <label className="relative block text-[14px]">
                <span className="font-semibold">
                  <span className="text-theme-color">*</span> Email Address
                </span>
                <span className="absolute top-10 left-0 flex items-center pl-2">
                  <FaEnvelope className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type="email"
                  className="mt-1 block w-full px-3 pl-8 py-3 bg-white border rounded-md text-sm shadow-sm placeholder-slate-400  focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 font-poppins"
                  placeholder="Enter your email"
                  name="email"
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
              </label>
              {emailError && (
                <small className="text-left text-red-600">
                  Enter Valid Email
                </small>
              )}
              <div className="flex justify-center my-6 text-center">
                <button
                  onClick={() => handleLogin()}
                  type="button"
                  disabled={!buttonDisabled}
                  className="bg-theme-color w-full text-center disabled:opacity-40 flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 py-[.5rem] rounded-lg  font-bold cursor-pointer"
                >
                  <span
                    className={` ${
                      ButtonLoader
                        ? "border-t-transparent inline-block border-2 border-white animate-spin rounded-full w-5 h-5"
                        : ""
                    } `}
                  ></span>
                  Reset Password
                </button>
              </div>
              <div className="text-center">
                <p className="text-sm font-poppins">Remember It ? <Link to="/" className="text-theme-color font-medium underline" rel="noreferrer">Sign In here</Link></p>
              </div>
            </form>
          </div>
        </div>
        <small className=" mt-4">© 2023 Event Plus </small>
      </div>
    </>
  );
}

export default ForgotPassword;
