import React, { useContext, useEffect } from "react"
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { useLocation } from "react-router-dom";


const DetailModal=()=>{
  
  const location=useLocation()
  console.log(location.state.item,"item")
  const {setPreviewBtn,findOneAttendeeApi,getRegData, getRegistraition}=useContext(DataContext)
  const state=location.state.item

  function checkLogin(date1){
    var s = new Date(date1). toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
    
   return s;
  }
useEffect(()=>{
  getRegistraition()
  findOneAttendeeApi(state._id)
},[])
 
  return (
    <>
   

<div className="flex w-full ">
        <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/attendees"}>
              <div className="w-8 pt-1">
                <button className="border p-2" >
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">{`${state.Name===undefined ||state.Name==="" ? "" :state.Name}`} </h1>
            </div>
          </div>
          <div className="flex space-x-10 m-0 ">
            
            
          </div>
          <div className="flex flex-col rounded-lg bg-white border-[#D1D9E7] p-3">
          <div className=" flex space-x-10  ">
            <div className="w-1/2 space-y-3">
              
              <div className="">
                 
               
                
                  
               
              
               
             {
                  getRegData.length > 0 && getRegData.map((item, index) => (
                    <div>
                      <label
                        key={item?._id} htmlFor={item?.columnName}
                        className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                      >
                       
                        {item.columnName} 
                      </label>
                      <input
                        type="text"
                        id={item?.columnName}
                       value={state[item?.columnName]?state[item?.columnName]:"No Data"}
                        name={item.columnName} // Add the name attribute to match the state variable
                     
                        className={`w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9 `}
                        
                      />
                     
                    </div>
                  ))
                }
             
             <div className="mt-1 relative">
                <label
                  htmlFor="biography"
                  className="text-sm pt-2 font-semibold pb-1"
                >
                  Biography 
                </label>
                <textarea
                  rows="7"
                  id="biography"
                  name="biography"
                  className="w-full border pointer-events-none py-1 px-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                  value={state.biography===undefined ||state.biography==="" ? "No Data" :state.biography}
                  
                />
               
              </div> 
             
                
              

              </div>
            </div>
            <div className="w-1/2 ">
              
              
              
              
              

              
              <div className="">
                <p className="text-sm pt-2  font-semibold pb-2">
                  User Photo 
                </p>
                <div>
               {state.image===undefined ||state.image==="" ? <span className="text-[#8C9196] text-sm">No Data</span>: <img src={state.image} alt=""  className="w-32 h-24 rounded"/>}
                 
                </div>
                
                
              </div>
              <div className="mt-3">
                  <label
                    htmlFor="position"
                    className="pt-2 text-sm px-1 font-semibold "
                  >
                Designation
                  </label>
                  <input
                    type="text"
                    name="position" placeholder="country"
                    className="w-full border  pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.designation===undefined ||state.designation==="" ? "No Data" :state.designation}
                    
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="position"
                    className="pt-2 text-sm px-1 font-semibold "
                  >
                 Organization
                  </label>
                  <input
                    type="text"
                    name="position" placeholder="country"
                    className="w-full border  pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.organization===undefined ||state.organization==="" ? "No Data" :state.organization}
                    
                  />
                </div>

              {/* <div className="mt-2">
                  <label
                    htmlFor="instagram-profile"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                 Instagram
                  </label>
                  <input
                    id="instagram-profile"
                   type="text"
                    name="instagram-profile"
                    placeholder="Enter your email"
                    className="w-full border pointer-events-none  py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.instagram_profile===undefined ||state.instagram_profile==="" ? "No Data" :state.instagram_profile}
                  />
                </div> */}
             
                {/* <div>
                  <label
                    htmlFor="linkedin-profile"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Linkedin
                  </label>
                  <input
                    id="linkedin-profile"
                   type="text"
                    name="linkedin-profile"
                    placeholder="Enter your email"
                    className="w-full pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.linkedin_profile===undefined ||state.linkedin_profile==="" ? "No Data" :state.linkedin_profile}
                  />
                </div> */}
                <div className="">
                  <label
                    htmlFor="country"
                    className="pt-2 text-sm px-1 font-semibold "
                  >
                  Last Login
                  </label>
                  <input
                    type="text"
                    name="country"
                    placeholder="country"
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.lastLogin===undefined ||state.lastLogin==="" ? "No Logged in" :checkLogin(state?.lastLogin).split(",")[0]+", "+state?.lastLogin.slice(11,16)}
                    
                  />
                </div>
             
              
             
            </div>
            
            
          </div>
          </div>
         
            
        </div>
      </div>
    </>
  )
}
export default DetailModal;