import React, { useContext, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import logo from "../Assets/event logo.png";
import coverImage from "../Assets/Vector5.png"
import { FaEnvelope, FaLock, FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const copyrightYear= new Date().getFullYear();

  const {
    LoginApi,
    resultLoginState,
    setResultLoginState,
    ButtonLoader,
  } = useContext(DataContext);
  const [password, setPassword] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(state.email)) {
      setEmailError(true);
      setResultLoginState("");
    } else {
      setEmailError(false);
      setResultLoginState("");
    }
  };

  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
      setResultLoginState("");
    } else {
      setPasswordError(false);
      setResultLoginState("");
    }
  };

  const buttonDisabled = state.email !== "" && state.password !== "";

  const handleLogin = (e) => {
    e.preventDefault();
    LoginApi(state);
  };
  console.log(resultLoginState, "resultLoginState")

  return (
    <>
      <div
        className="w-full h-screen flex items-center justify-center flex-col bg-contain bg-top bg-no-repeat"
        style={{ backgroundImage: `url(${coverImage})` }}
      >
        <div className="w-[33%] xl:w-[90%] bg-whiterounded-md">
          <img
            src={logo}
            className=" w-[50%] m-auto pb-5 px-5 "
            alt="logo"
          ></img>
        </div>
        <div className="w-[33%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className=" py-4 rounded-t-md">
            <h1 className="text-center text-[20px] font-semibold">
              Sign in Event plus Admin
            </h1>
            {/* <p className="text-center text-white text-xl 2xl:text-base mb-3">
              Sign in to continue to Event Plus Admin
            </p> */}
            {/* <img src={logo} className="w-16 h-16 bg-white rounded-[50%]  m-auto -mb-10 shadow-[#272525ce] shadow-sm" alt="favicon"></img> */}
          </div>
          <hr />
          <div className="p-9 md:p-8">
            <form onSubmit={handleLogin}>
              <label className="relative block text-[14px]">
                <span className="font-semibold">
                  <span className="text-theme-color">*</span> Email Address
                </span>
                <span className="absolute top-10 left-0 flex items-center pl-2">
                  <FaEnvelope className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type="email"
                  className="mt-1 block w-full px-3 pl-8 py-3 bg-white border rounded-md text-sm shadow-sm placeholder-slate-400  focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1"
                  placeholder="Enter your email"
                  name="email"
                  value={state.email}
                  onChange={(e) => {
                    setState({ ...state, email: e.target.value });
                  }}
                  onKeyUp={() => validateEmail()}
                />
              </label>
              {emailError && (
                <small className="text-left text-red-600">
                  Enter Valid Email
                </small>
              )}
              <label className="relative block mt-6 text-[14px]">
                <span className="font-semibold"><span className="text-theme-color">*</span> Password</span>
                <span className="absolute top-10 left-0 flex items-center pl-2">
                  <FaLock className="h-4 w-4 fill-theme-color" />
                </span>
                <input
                  type={password === true ? "text" : "password"}
                  className="mt-1 block w-full pr-8 pl-8 py-3 bg-white border  rounded-md text-sm shadow-sm placeholder-slate-400 focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1"
                  placeholder="Password"
                  name="password"
                  value={state.password}
                  onChange={(e) => {
                    setState({ ...state, password: e.target.value });
                  }}
                  onKeyUp={() => validatePassword()}
                />
                <span
                  className="absolute top-10 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setPassword(!password)}
                >
                  {password === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {passwordError && (
                <small className="text-left text-red-600">Enter Password</small>
              )}
              <small className="text-left text-red-600">
                {resultLoginState}
              </small>
              <div className="flex  text-[13px] justify-between pt-2" >
                {/* <div className=" text-[#747474] ">
                  <input type="checkbox" /> Keep me logged in
                </div> */}
                <Link
                  to="/forgot-password"
                  className="text-theme-color  font-medium underline"
                  rel="noreferrer"
                >
                  Forgot Password
                </Link>
              </div>
              <div className="flex justify-center mt-6 text-center">
                <button
                  type="submit"
                  disabled={!buttonDisabled}
                  className="bg-theme-color w-full text-center disabled:opacity-40 flex items-center justify-center text-white transition ease-in-out delay-150 duration-300 p-[.5rem_7.5rem] rounded-lg  font-bold cursor-pointer"
                >
                  <span
                    className={` ${ButtonLoader
                        ? "border-t-transparent inline-block border-2 border-white animate-spin rounded-full w-5 h-5"
                        : ""
                      } `}
                  ></span>
                  Login
                </button>
                {/* <button
                  disabled={buttonDisabled}
                  onClick={() => handleLogin()}
                  type="button" 
                  className="bg-theme-color text-white p-2 w-80 rounded-lg  font-bold disabled:opacity-40">
                  Login
                </button> */}
              </div>
              {/* <div className="text-center">
                <p className="text-xl 2xl:text-sm ">Don't have an account? <Link to="/signup" className="text-theme-color font-medium underline" rel="noreferrer">Register</Link></p>
              </div> */}
            </form>
          </div>
        </div>
        <small className=" mt-4">© {copyrightYear} Event Plus </small>
      </div>
    </>
  );
};

export default Login;
