import React, { useContext ,useEffect} from "react"
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { useLocation } from "react-router-dom";


const ExhibitorDetails=()=>{
  const {findOneExhibitorApi}=useContext(DataContext)
  const location=useLocation()
  const state=location.state

useEffect(()=>{
  findOneExhibitorApi(state._id)
},[])
 console.log(state.galleryImage,"state")
  return (
    <>
    {/* <div className="flex w-full bg-white">
        <div className="w-full px-7  bg-white overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/exhibitors"}>
              <div className="w-8 pt-1">
                <button className="border p-2" >
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">Exhibitor Information </h1>
            </div>
          </div>
          <div className="flex space-x-10 m-0 ">
            <div className="w-1/2 space-y-3 ">
              <h2 className="font-semibold text-lg ">Exhibitor Details</h2>
              <hr />
              
            </div>
            
          </div>
          <div className="flex flex-col">
          <div className="grid grid-cols-2">
                <div className="flex flex-col gap-4  w-[85%] ">
                  <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Name</h2>
                       <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.name}`}</p>
                    </div>

                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Description</h2>
                       <p className="text-[#4B4B4B] text-sm font-normal p-2  text-justify">{`${state.description}`}</p>
                    </div>
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Website</h2>
                       
                       <p className="text-[#4B4B4B] text-sm font-normal p-2">{`${state.websiteUrl}`}</p>
                      
                    </div>
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Email</h2>
                     
                      <p className="text-[#4B4B4B] text-sm font-normal p-2">{state.email}</p>
                    </div>
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Contact Number</h2>
                       
                      <p className="text-[#4B4B4B] text-sm font-normal p-2">{state.contactNumber}</p> 
                      </div>
                      <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Banner image</h2>
                      <img src={state.bannerImage} alt={state.bannerImage} className="w-40 rounded-md h-40 m-2" />
                    </div>
                   
                   
                    </div>
                    
                  
                    

                    <div className="flex flex-col gap-4  w-full">
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Banner image</h2>
                      <img src={state.bannerImage} alt={state.bannerImage} className="w-40 rounded-md h-40" />
                    </div>
                    
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Logo</h2>
                        <img src={state.logo} alt={state.logo} className="w-40 rounded-md h-40 m-2 " />
                    </div>
                    <div className="mt-2">
                        <h2 className="text-[#6D7175] text-sm font-medium">Gallery Image</h2>
                        <div className="flex flex-wrap gap-2">
                        {
                          state.galleryImage.map((item)=>{
                            return  <img src={item} alt="galleryPhotos" className="w-24 rounded-md h-24 m-2 " />
                     
                          })
                        }
                        </div>
                        
                        
                       
                  
                   
                    </div>
               

            </div>
          </div>
         
            
        </div>
      </div>
      </div> */}
      <div className="flex w-full ">
        <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/exhibitors"}>
              <div className="w-8 pt-1">
                <button className="border p-2" >
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">{`${state.name}`}</h1>
            </div>
          </div>
          <div className="flex space-x-10 m-0 ">
            
            
          </div>
          <div className="flex flex-col rounded-lg bg-white border-[#D1D9E7] p-3">
          <div className=" flex space-x-10  ">
            <div className="w-1/2 space-y-3">
              
              <div className="">
                <div>
                  <label
                    htmlFor="name"
                    className="text-sm px-1 font-semibold "
                  >
                   Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    
                    className="w-full pointer-events-none border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 shadow-sm rounded-md text-[#8C9196]  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.name===undefined ||state.name==="" ? "No Data" :state.name}
                  />
                </div>
                <div>
                  <label
                    htmlFor="description"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Description
                  </label>
                  <textarea
                  rows="7"
                  id="description"
                  name="description"
                  className="w-full  pointer-events-none border py-1 px-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm "
                  value={state.description===undefined ||state.description==="" ? "No Data" :state.description}
                  
                />
                </div>
                <div>
                  <label
                    htmlFor="website-url"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Website Url
                  </label>
                  <input
                    id="website-url"
                    type="text"
                    name="website-url"
                    
                    className="w-full border  pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.websiteUrl===undefined ||state.websiteUrl==="" ? "No Data" :state.websiteUrl}
                  />
                  
                </div>
                <div>
                <label
                  htmlFor="email"
                  className=" text-sm px-1 font-semibold pb-1"
                >
                  Email Id
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"ˀ
                 
                  className="w-full border   pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] text-[#8C9196] border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                  value={state.email===undefined ||state.email==="" ? "No Data" :state.email}
                  
                />
                
              </div>
                <div>
                  <label
                    htmlFor="contact-number"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                   Contact Number
                  </label>
                  <input
                    type="text"
                    name="contact-number"
                    placeholder="contact-number"
                    className="w-full border  pointer-events-none  py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={state.contactNumber===undefined ||state.contactNumber==="" ? "No Data" :state.contactNumber}
                    
                  />
                </div>
              
                
                
                
               
              

              </div>
            </div>
            <div className="w-1/2 ">
              
              
              
              
             

              
              <div className="">
                <p className="text-sm pt-2  font-semibold pb-1">
                  Speaker Photo 
                </p>
                <div>
               {state.logo===undefined ||state.logo==="" ?  <input
                    type="text"
                    name="logo"
                    placeholder="logo"
                    className="w-full border pointer-events-none py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value="No Data"
                    
                  />: <img src={state.logo} alt=""  className="w-12 h-12 rounded"/>}
                 
                </div>
                
                
              </div>

              <div className="mt-2">
                <p className="text-sm pt-2  font-semibold pb-1">
                  Banner Image
                </p>
                <div>
               {state.bannerImage===undefined ||state.bannerImage==="" ? <span className="text-[#8C9196] text-sm">No Data</span>: <img src={state.bannerImage} alt=""  className="w-full h-44 rounded"/>}
                 
                </div>
                
                
              </div>

              <div className="mt-2">
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1"
                  >
                  Gallery Image
                  </label>
                 <div className="flex gap-3 flex-wrap ">
                  {
                    state.galleryImage.length===0 ? <input  className="w-full border py-1 px-3 mb-3 bg-[#FAFBFB] border-gray-300 text-[#8C9196] shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                    value={"No Data"} />
                    
                    :state.galleryImage.map((item)=>{
                      return (
                        <>
                        
                    <img src={item} alt="" className=" h-28 w-32 rounded "   />
                    

                  
                        </>
                      )
                    })
                  }
                  

                 </div>
                </div>
             
             
            </div>
            
            
          </div>
          </div>
         
            
        </div>
      </div>
    </>
  )
}
export default ExhibitorDetails;