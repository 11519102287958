import React from "react";

const Notification = () => {
    return (
        <>
            <div className="w-full flex">
                
                <div className="w-4/5 px-8 py-7">
                    <h1 className="font-poppins font-semibold text-[64px] 3xl:text-4xl 2xl:text-3xl leading-[96px] text-title-color">Notification</h1>
                </div>
            </div>
        </>
    )
}
export default Notification;