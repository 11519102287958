import React,{useEffect,useContext,useState} from "react"
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import dummyImage from "../Assets/Image.png"
import { FaCrown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const SponsorsView=()=>{
  const { getSingleSponsorReportApi,
    singleSponsorReport,currentPage,setCurrentPage,setOption,totalCount,pageCount,globalLoader,checkResponse}=useContext(DataContext);
    const limit = 10;
    const [firstResultCount, setFirstResultCount] = useState(1);
    const [secondResultCount, setSecondResultCount] = useState(limit);
  

    const location=useLocation()
    const sponsor=location.state.item ||location.state
    const id=sponsor._id
console.log(id,"id");
    useEffect(()=>{
      getSingleSponsorReportApi(id,1,limit)
    },[])
   console.log(sponsor,'sponsor') 
  return (
    <>
    
       <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={`${!location.state.item ? "/sponsors":"/reports"}`} >
            <div className="w-8 pt-1"  onClick={()=>{setOption("sponsors")}}>
           
              <button className="border p-2">
                <FaArrowLeft />
              </button>
              
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Sponsor Information </h1>
          </div>
        </div>
        {
         globalLoader ? <GlobalLoader/> : <>
         <div className="flex">
        <div className=" w-1/2  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Sponsor Details</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full ">
          <p className="text-sm font-medium">Name</p>
          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{sponsor?.sponsor_name}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Email</p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{sponsor?.email}</p>
        </div>
        
       </div>

       <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full ">
          <p className="text-sm font-medium">Contact number</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{sponsor?.phone}</p>
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Website Url</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{sponsor.url}</p>
        </div>
        
       </div>
       <div className="text-[#202223]  w-full mt-5 ">
            <label
              className="block relative mb-2 text-sm font-medium"
              typeof="description"
              htmlFor="description"
            >
              Description *
            </label>
            <textarea
             
              rows="8"

              className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-[#AEB4B9] "
              
              value={sponsor?.description}
             
            />
            
          </div>
       
      
              
              
        
        </div>

        <div className=" w-1/2  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Others</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          
        <div className="text-[#202223] flex-1 w-full ">
          <p className="text-sm font-medium pt-2">Sponsor level</p>
          {/* <p 
         
           className="border px-2 py-1  outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">
         <div className="bg-[#FFCD3B] flex p-1 w-16 rounded">
          <FaCrown className="text-[#FF930F]"/>
          <span className="text-[#202223] text-sm font-normal">Gold</span>
         </div>
         
            
           </p> */}
           <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{sponsor?.level_name}</p>
        </div>
        
        
      

       <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full  ">
          <p className="text-sm font-medium">Banner image</p>
          <img src={sponsor?.bannerimage} className="w-48 h-32 pt-2" alt="banner-picture" />
        </div>
        <div className="text-[#202223] flex-1 w-full">
          <p className="text-sm font-medium">Logo image</p>
          <div className="rounded border border=[#C9CCCF] w-16 h-16 mt-2">
          <img src={sponsor?.image} alt="logo-picture" />
          </div>
        
        </div>
        
       </div>
       
       
      
              
              
        
        </div>


        </div>
        
        <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base p-3">More Information</h1>
                      <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            

                            <th className="w-[15%] rounded-tl-lg px-4 py-2 ">
                              Name
                            </th>
                            <th className="w-[20%] px-4 py-2 ">
                            Email
                            </th>
                            <th className="w-[15%] px-4 py-2">Organization</th>
                            <th className="w-[15%] px-4 py-2 relative">
                            Banner Click
                            </th>
                            <th className="w-[10%] px-4 py-2">Profile Visit</th>
                            <th className="w-[20%] px-4 py-2">Website Click</th>
                           
                            {/* <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          
                            {singleSponsorReport?.profileVisit?.length==0 && checkResponse ?
                          <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>:
                              singleSponsorReport?.profileVisit?.map((item)=>{
                                return (
                                  <>
                                   <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                

                                <td className=" py-2 px-4">{item?.userInfo?.Name}</td>

                                <td className=" py-2 px-4">
                                 {item?.userInfo?.email}
                                </td>
                                <td className=" py-2 px-4">
                                 {item?.userInfo?.organization}
                                </td>
                               
                              <td className={`${item?.bannerVisit>0 ? "underline":""} py-2 px-4 text-theme-color`} > 
                              <Link to={item?.bannerVisit>0 ?"/date-time":""} state={{sponsor_id:id,clicks:"bannerClicks",user_id:item.userInfo._id,name:item?.userInfo?.Name,item:sponsor}}><span className="px-4">  {item?.bannerVisit}</span></Link>
                             </td>
                             <td className={`${item?.checkVisits>0 ? "underline":""} py-2 px-4 text-theme-color`}> 
                              <Link to={item?.checkVisits>0 ?"/date-time":""} state={{sponsor_id:id,clicks:"profileVisits",user_id:item?.userInfo._id,name:item?.userInfo?.Name,item:sponsor}}><span className="px-4">  {item?.checkVisits}</span></Link>
                             </td>
                             <td className={`${item?.websiteVisits>0 ? "underline":""} py-2 px-4 text-theme-color`}> 
                              <Link to={item?.websiteVisits>0 ?"/date-time":""} state={{sponsor_id:id,clicks:"websiteClicks",user_id:item?.userInfo._id,name:item?.userInfo?.Name,item:sponsor}}><span className="px-4">  {item?.websiteVisits}</span></Link>
                             </td>
                              
                               
                             
                              
                               
                               
                                 

                                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/sponsors-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}
                              </tr>
                                  </>
                                )
                              })
                            }
                             
                            
                        
                        </tbody>
                      </table>
                    </div>
         </>
          
        }
        

                    {/* <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              api={getSingleSponsorReportApi}
             id={id}
            />
          </div> */}
      </div>
    </>
  )
}
export default SponsorsView;