import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import dummyImage from "../Assets/Image.png"
import { FaCrown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import GlobalLoader from "../GlobalLoader/GlobalLoader";


const ExhibitorsOverviewViewPage=()=>

{
const {getSingleExhibitorReportApi,setOption,singleExhibitorReportcurrentPage,setCurrentPage,totalCount,pageCount,singleExhibitorReport,globalLoader,checkResponse}=useContext(DataContext)
const location=useLocation()
const exhibitor=location.state.item ||location.state
const id=exhibitor._id

const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);


useEffect(()=>{
  getSingleExhibitorReportApi(id,1,limit)
},[])
console.log(singleExhibitorReport?.docs,"singleExhibitorReport")
  return (
    <>
     <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={`${!location?.state?.item ? "/exhibitors":"/reports"}`}>
            <div className="w-8 pt-1">
             
              <button className="border p-2">
                <FaArrowLeft />
              </button>
             
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Exhibitor Information </h1>
          </div>
        </div>
        {
          globalLoader ? <GlobalLoader/> :
          <>
           <div className="flex">
        <div className=" w-1/2  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Exhibitor Details</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-1/2 ">
          <p className="text-sm font-medium">Name</p>
          <p 
         
           className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{exhibitor?.name}</p>
        </div>
        <div className="text-[#202223] flex-1 w-1/2">
          <p className="text-sm font-medium">Email</p>
          <p   className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{exhibitor?.email}</p>
        </div>
        
       </div>

       <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-1/2 ">
          <p className="text-sm font-medium">Contact number</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] w-full">{exhibitor?.contactNumber}</p>
        </div>
        <div className="text-[#202223] flex-1 w-1/2 ">
          <p className="text-sm font-medium">Website Url</p>
          <p  className="border-b outline-none text-base font-normal mt-2 border-[#AEB4B9] break-words pr-2 w-full">{exhibitor?.websiteUrl}</p>
        </div>
        
       </div>
       <div className="text-[#202223]  w-full mt-5 ">
            <label
              className="block relative mb-2 text-sm font-medium"
              typeof="description"
              htmlFor="description"
            >
              Description 
            </label>
            <textarea
             
              rows="8"

              className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-[#AEB4B9] "
              
              value={exhibitor?.description}
             
            />
            
          </div>
       
      
              
              
        
        </div>

        <div className=" w-1/2  m-0 bg-white p-3 rounded">
          <div className=" space-y-3 ">
            <h2 className="font-semibold text-lg ">Others</h2>
            <hr className=" border-1 border-[#BDBDBD]" />
           
          </div>

          
        
          <div className="text-[#202223] flex-1 w-full mt-3">
          <p className="text-sm font-medium">Logo image</p>
          <div className="rounded border border=[#C9CCCF] w-16 h-16 mt-1">
          <img src={exhibitor?.logo} alt="logo-picture" />
          </div>
        
        </div>
        
      <div className="flex">
      <div className=" w-full mt-5  gap-12">
        <div className="text-[#202223] flex-1 w-full  ">
          <p className="text-sm font-medium">Banner image</p>
          <img src={exhibitor?.bannerImage} className="w-52 h-32 mt-1" alt="banner-picture" />
        </div>
        
        
       </div>
       <div className=" w-full mt-5 flex gap-12">
        <div className="text-[#202223] flex-1 w-full  ">
          <p className="text-sm font-medium">Gallery image</p>
          <div className="flex flex-wrap">
          {
            exhibitor?.galleryImage?.length===0? <span className="text-sm fomt-medium mt-2 mx-2">No data</span>:
          exhibitor?.galleryImage?.map((item)=>{
            return (
              <img src={item} className="w-20 h-20 mt-1" alt="banner-picture" />
            )
          })
         }
          </div>
        
         
        </div>
        
        
       </div>
        
      </div>

       
       
       
      
              
              
        
        </div>


        </div>
        
        <div className="bg-white rounded-t mt-3 px-3">
                <h1 className="text-[#141824] font-semibold text-base p-3">More Information</h1>
                      <table className=" table-auto w-full bg-white text-start shadow-md  rounded-lg ">
                        <thead className="cursor-pointer">
                          <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                            <th className="w-[15%] px-4 py-2 rounded relative">
                           Name
                            </th>

                           
                            <th className="w-[25%] px-4 py-2 ">
                            Email
                            </th>
                            <th className="w-[20%] px-4 py-2">Organization</th>
                            
                            <th className="w-[12%] px-4 py-2">Profile Visit</th>
                            <th className="w-[12%] px-4 py-2">Website Click</th>
                           
                            {/* <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                              Action
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                          
                          {singleExhibitorReport?.length==0 && checkResponse ?
                          <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>:
                            singleExhibitorReport && singleExhibitorReport?.map((item)=>{
                              return (
                                <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                <td className="p-2">
                                {item?.userInfo?.Name}
                                </td>

                               

                                <td className=" py-2 px-4">
                                {item?.userInfo?.email}
                                </td>
                                
                                <td className=" py-2 px-4">
                                {item?.userInfo?.organization}
                                  </td>

                                  <td className={`${item?.checkVisits>0 ? "underline":""} py-2 px-4 text-theme-color`} > 
                              <Link to={item?.checkVisits>0 ?"/date-time":""} state={{sponsor_id:id,clicks:"profileVisits",user_id:item.userInfo._id,name:item?.userInfo?.Name,module:"exhibitors",item:exhibitor}}><span className="px-4">  {item?.checkVisits}</span></Link>
                             </td>

                             <td className={`${item?.websiteVisits>0 ? "underline":""} py-2 px-4 text-theme-color `} > 
                              <Link to={item?.websiteVisits>0 ?"/date-time":""} state={{sponsor_id:id,clicks:"websiteClicks",user_id:item.userInfo._id,name:item?.userInfo?.Name,module:"exhibitors",item:exhibitor}}><span className="px-4">  {item?.websiteVisits}</span></Link>
                             </td>
                               

                                {/* <td className="py-2 px-4">
                                  <div className="flex items-center  justify-center  gap-2 text-black ">
                                    <Link to="/sponsors-view">
                                      <AiOutlineEye className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                    </Link>
                                  </div>
                                </td> */}
                              </tr>
                              )
                            }

                            )
                          }
                            
                              
                            
                        
                        </tbody>
                      </table>
                    </div>
          </>
        }
       
                    {/* <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              id={id}
              api={getSingleExhibitorReportApi}
             
            />
          </div> */}
      
      </div>
    </>
  )
}
export default ExhibitorsOverviewViewPage;