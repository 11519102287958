import React from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";

const PointsInfo = () => {
  return (
    <>
      <div className="w-full px-7 py-2 overflow-y-scroll scrollbar-thin h-screen space-y-4">
        <div className="pt-3 flex gap-4 items-center">
        <Link to={"/leaderboard"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <h1 className="text-xl cursor-pointer text-[#202223] font-semibold">
            Points Information
          </h1>
        </div>

<div className="bg-white w-[35vw] p-3 rounded-lg shadow-md">
<table className="w-full table-auto bg-white text-start shadow-md  ">
          <thead className="cursor-pointer">
            <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
              <th className="w-[5%] px-4 py-2 rounded-tl ">Title</th>
              <th className="w-[5%] px-4 py-2  rounded-tr">Points</th>
            </tr>
          </thead>
          <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
            <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
              <td className="py-2 px-4">Post uploaded</td>
              <td className=" py-2 px-4">10</td>
            </tr>
            <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
              <td className="py-2 px-4">Post Liked</td>
              <td className=" py-2 px-4">2</td>
            </tr>
            <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
              <td className="py-2 px-4">Post Comment</td>
              <td className=" py-2 px-4">2</td>
            </tr>
            <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
              <td className="py-2 px-4">Poll Attended</td>
              <td className=" py-2 px-4">5</td>
            </tr>
            <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
              <td className="py-2 px-4 rounded-bl">Agenda Check In</td>
              <td className=" py-2 px-4 rounded-br">5</td>
            </tr>
          </tbody>
        </table>
</div>
       
      </div>
    </>
  );
};
export default PointsInfo;
