import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";

const ViewEvents = () => {
  const {getEventByIdApi , specificEvent}= useContext(DataContext);
  

  useEffect(()=>{
   
    getEventByIdApi();
  },[])
  return (
    <>

      <div className="flex h-screen  w-full">
        <div className="w-full px-7 bg-white overflow-y-scroll h-screen">
          <div className="flex justify-between items-center pb-5 pr-4">
            <h1 className="font-poppins font-semibold text-[32px] leading-[70px] text-[#ff8531] ">
              Event
            </h1>
          </div>
          <div className=" flex space-x-7">
                <div className="shadow-lg w-64 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 border border-gray-300 rounded-md ">
                <img
                  src={require("../Assets/baloon.avif")}
                  className="h-48 w-full rounded-t-md"
                  alt=""
                />
                <div className="px-2 capitalize py-3">
                  <h1 className="text-xl">{specificEvent.eventName}</h1>
                  <p className="text-gray-400">College Fest</p>
                </div>
                <div className="flex justify-between p-2">
                  <Link to="/event_detail">
                    <button
                      className="bg-theme-color p-1 px-5 rounded-md text-white text-sm"
                      type="button"
                    >
                      Edit Event
                    </button>
                  </Link>
                  <Link to="">
                    <button
                      className="bg-theme-color p-1 px-5 rounded-md text-white text-sm"
                      type="button"
                    >
                      View Event
                    </button>
                  </Link>
                </div>
              </div>
          

            {/* <div className="shadow-lg w-64 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 border border-gray-300  rounded-md ">
              <img
                src={require("../Assets/artistic-creative-business-cards-with-color-gradient.jpg")}
                className="h-48 w-full rounded-t-md"
              />
              <div className="px-2 capitalize py-3">
                <h1 className="text-xl">event2</h1>
                <p className="text-gray-400">event are good</p>
              </div>
            </div>
            <div className="shadow-lg w-64 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 border border-gray-300 rounded-md">
              <img
                src={require("../Assets/img2.jpg")}
                className="h-48 w-full rounded-t-md"
              />
              <div className="px-2 capitalize py-3">
                <h1 className="text-xl">event3</h1>
                <p className="text-gray-400">event are good</p>
              </div>
            </div>
            <div className="shadow-lg w-64 transition ease-in-out delay-150  hover:-translate-y-1 hover:scale-100 border border-gray-300 rounded-md">
              <img
                src={require("../Assets/baloon.avif")}
                className="h-48 w-full rounded-t-md "
              />
              <div className="px-2 capitalize py-3">
                <h1 className="text-xl">event4</h1>
                <p className="text-gray-400">event are good</p>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEvents;
