import React from "react";
import { FaSearch} from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";

const ContactEmailTemplate=({setStatus})=>{
  return(
    <>
    <div className=" flex justify-between pt-5 ">
              <div className="flex gap-1">
                <Link to="/create-template" state={{setStatus:setStatus}}>
                  <button
                    className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm"
                    
                  >
                    Create New Email Template
                  </button>
                </Link>
                
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  
                  className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"

                  
                />
              </div>
            </div>
            <table className="w-full table-auto bg-white shadow-md rounded-md ">
              <thead className="cursor-pointer">
                <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                  <th className=" w-[25%] p-2 rounded-tl-md relative">
                    <div className="flex gap-2 items-center">
                     
                      <span> Email Template Name</span>
                    </div>
                   
                 
                  </th>
                  <th className=" w-[25%] p-2 whitespace-nowrap">
                   Subject
                  </th>
                  <th className="w-[25%] p-2 ">Last modified</th>
                  
                  <th className="w-[15%] p-2 ">Action</th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
               
                    <tr className="border-t cursor-pointer group hover:shadow-tableRowsShadow text-[#202223] text-sm font-normal">
                      <td className="px-2 py-3  flex gap-2 items-center ">
                       
                      Welcome Email 
                      </td>
                      <td className="p-2  leading-5">
                      We would love to hear ... 
                      </td>
                      <td className="p-2 ">
                      2022-07-22 14:24:34
                      </td>
                     
                      <td className="p-2">
                        <div className="flex items-center  gap-2 text-black ">
                         
                         

                          <FiEdit2
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            
                          />

                          <FiTrash
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            
                          />
                        </div>

                       
                      </td>
                    </tr>
                  
              </tbody>
            </table>
    </>
  )
}
export default ContactEmailTemplate;