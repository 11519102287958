import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import dummyImage from "../../components/Assets/dummyimage.jpg";
import Pagination from "../Pagination/Pagination";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { AiOutlineEye } from "react-icons/ai";
import DetailModal from "./UserDetails";
import SendEmailModal from "../Contacts/SendEmailModal";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { Tooltip } from 'react-tooltip'
// import toasti

const Attendees = () => {
  const {
    getUserApi,
    user,
    setUser,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteUserApi,
    searchUserApi,
    attendeesPaginationApi,
    totalCount,
    pageCount,
   
    currentPage,
    setCurrentPage,
    checkResponse,
    isSendMail,
    setIsSendMail,
   
  } = useContext(DataContext);
  const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const [item, setItem] = useState(null);
  const [selectedItems,setSelectedItems]=useState([])

  
 

  const checkboxHandler = (e, item) => {
    const isSelected = e.target.checked;

    let value = item.email;

    if (isSelected) {
      setSelectedItems([...selectedItems, value]);
    } else {
      setSelectedItems((prevData) => {
        return prevData.filter((element) => {
          return element !== value;
        });
      });
    }
  };

  const checkAllboxHandler = (e) => {
    const isSelected = e.target.checked;
    let newArray = [];
    if (isSelected) {
      newArray = user.map((item) => {
        return item.email;
      });
      setSelectedItems([...newArray]);
    } else {
      setSelectedItems([]);
    }
    return newArray;
  };

  let obj = {
    name: "Name",
    email: "Email Address",
    organization: "Organization",
    position: "Position",
    speakerPhoto: "Speaker photo",
    mobileNumber: "Mobile Number",
    profileImage: "Profile Image",
    biography: "Biography",
  };

  function checkLogin(date1) {
    var s = new Date(date1).toLocaleString(undefined, {
      timeZone: "Asia/Kolkata",
    });

    return s;
  }

  useEffect(() => {
    setCurrentPage(1);
    attendeesPaginationApi(1, 10);
  }, []);

  const onKeyUpHandler = (e) => {
    searchUserApi(e.target.value, currentPage);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...user].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setUser(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };
  
  console.log(user.length,"length")
  console.log(checkResponse,"checkResponse")
  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteUserApi}
          deleteId={deleteBtnId}
          currentPage={currentPage}
          limit={limit}
          secondResultCount={secondResultCount}
          setSecondResultCount={setSecondResultCount}
        />
      ) : (
        ""
      )}
  {isSendMail ? <SendEmailModal selectedItems={selectedItems} setSelectedItems={setSelectedItems} module={"attendee"} /> : <></>}

  
 <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <h1 className="text-xl text-[#202223] font-semibold ">Attendees</h1>
            <div className=" flex justify-between space-x-2 pt-4">
              <div className="space-x-1">
                <Link to="/add-attendees">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New Attendee
                  </button>
                </Link>
                <Link to={"/import-attendees"}>
                <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Import XLS
                </button>
              </Link>
              <Link to={""}>
                <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
                onClick={()=>{setIsSendMail(true)}}>
                 Send Email
                </button>
              </Link>
               
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-1 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*--------- table list--------- */}
          {user?.length === 0 && checkResponse === true ? (
            <NoDataModal />
          ) : globalLoader ? (
            <GlobalLoader />
          ) : (
            <>
             <table className="w-full  table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                
                <th className="w-[10%] flex gap-2  px-4 py-2 rounded-tl-lg">
                <input 
                type="checkbox" 
                name="listName"  
                id="listname" 
                checked={
                  selectedItems.length === user.length && user.length!=0
                }
                onChange={(e) => {
                  checkAllboxHandler(e);
                }}
                
                />
                  <span>Photo</span>
                </th>
                <th className="w-[13%] px-4 py-2 rounded relative">
                  Name
                  <div className="px-[40px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "Name",
                          "asc"
                        )}`}
                        onClick={() => sortData("Name")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "Name",
                          "desc"
                        )}`}
                        onClick={() => sortData("Name")}
                      />
                    </span>
                  </div>
                </th>
                <th className="w-[20%] px-4 py-2 relative">
                  Email
                  <div className="px-[37px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "email",
                          "asc"
                        )}`}
                        onClick={() => sortData("email")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "email",
                          "desc"
                        )}`}
                        onClick={() => sortData("email")}
                      />
                    </span>
                  </div>
                </th>
                {/* <th className="w-[15%] px-4 py-2">Mobile Number</th> */}
                <th className="w-[15%] px-4 py-2 relative">
                  Organization
                  <div className="px-[80px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "organization",
                          "asc"
                        )}`}
                        onClick={() => sortData("organization")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "organization",
                          "desc"
                        )}`}
                        onClick={() => sortData("organization")}
                      />
                    </span>
                  </div>
                </th>
                <th className="w-[15%] px-4 py-2 relative">
                  Designation
                  <div className="px-[80px] ">
                    <span className="">
                      <FaSortUp
                        className={`top-[30%] absolute ${getSortArrowClassName(
                          "designation",
                          "asc"
                        )}`}
                        onClick={() => sortData("designation")}
                      />
                      <FaSortDown
                        className={`top-[40%] absolute ${getSortArrowClassName(
                          "designation",
                          "desc"
                        )}`}
                        onClick={() => sortData("designation")}
                      />
                    </span>
                  </div>
                </th>
                <th className="w-[10%] px-4 py-2">Last login</th>
                <th className="w-[10%]  text-center rounded-tr-lg px-4 py-2">
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {user?.map((item) => (
                <tr
                  className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer"
                  key={item?._id}
                >
                  
                  <td className="py-2 px-4 flex gap-3">
                  <input 
                  type="checkbox"
                   name="attendee" 
                    id="attendee" 
                    checked={selectedItems.includes(item.email)}
                            
                              onChange={(e) => {
                                checkboxHandler(e, item);
                              }}
                    />
                    <img
                      src={item?.image ? item?.image : dummyImage}
                      className="h-8 w-8 rounded-full"
                      alt=""
                    />
                  </td>
                  <td className="py-2 px-4">{item?.Name}</td>
                  <td className=" py-2 px-4">{item?.email}</td>
                  {/* <td className=" py-2 px-4">{item?.phone}</td> */}
                  <td className=" py-2 px-4">{item?.organization}</td>
                  <td className=" py-2 px-4">{item?.designation}</td>
                  <td className=" py-2 px-4 whitespace-nowrap">
                    {item?.lastLogin
                      ? checkLogin(item?.lastLogin).split(",")[0]+", "+item?.lastLogin.slice(11,16)
                      : "Not logged in"}
                  </td>
                  <td className="py-2 px-4">
                    <div className="flex items-center  justify-center   text-black ">
                    <Link to={"/attendeeoverview-view"} state={{item:item,module:"attendee"}}>
                            <HiOutlineChartSquareBar 
                              data-tooltip-id="my-tooltip" data-tooltip-content="Report"
                            size={30} className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                              <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </Link>
                      <Link
                        to={`/user/${item?._id}`}
                        state={{ item: item, obj: obj }}
                      >
                        <AiOutlineEye
                          data-tooltip-id="my-tooltip" data-tooltip-content="View"
                          onClick={() => {
                            item = { item };
                          }}
                          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] "
                        />
                         <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                      </Link>
                      <Link to={`/update_user/${item?._id}`} state={item}>
                        <FiEdit2 
                         data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                        className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                          <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                      </Link>
                      <FiTrash
                        data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                        className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                        onClick={() => {
                          setDeleteBtn(true);
                          setDeleteBtnId(item?._id);
                        }}
                      />
                        <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {
            !checkResponse  &&  <div className="flex justify-between items-start ">
           <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
             pageCount === 1 ? totalCount : secondResultCount
           } of ${totalCount} results`}</span>

           <Pagination
             firstResultCount={firstResultCount}
             setFirstResultCount={setFirstResultCount}
             secondResultCount={secondResultCount}
             setSecondResultCount={setSecondResultCount}
             limit={limit}
             api={attendeesPaginationApi}
           />
         </div>
          }
             
            </>
          )}
        </div>
      </div>
     
    </>
  );
};

export default Attendees;
