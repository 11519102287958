import React, { useContext, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";

const AddAttendees = () => {
  const { addAttendeesApi, disableSave, getRegistraition, getRegData } = useContext(DataContext);

  const initialFormState = {};

  getRegData?.forEach((item) => {
    initialFormState[item.columnName] = "";
  });
  const [formData, setFormData] = useState(initialFormState)
  const [state, setState] = useState({
    // Name: "",
    // email: "",
    organization: "",
    designation: "",
    biography: "",
    image: "",
    // phone: "",
  });
  const [count, setCount] = useState(0);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [indexKey, setIndexKey] = useState([])
  const [phoneLength,setPhoneLength]=useState(true)
  const [checkDisable,setCheckDisable]=useState([])
  const [count1,setCount1]=useState(0)

  const validationHandler = (data, index) => {
    if (data.validation == "mobilenumber") {
      validatePhoneno(formData[data.columnName], index)
    } else if (data.validation == "email") {
      validateEmail(formData[data.columnName], index)
    }
  }


  const validatePhoneno = (phoneData, index) => {
    setCount1(count1+1);
  const phoneno =/^(\+\d{1,3})?[-.\s]?\(?(\d{1,4})\)?[-.\s]?\d{1,}$/;
    setPhoneLength(phoneData?.length)
    if (phoneData?.match(phoneno) && phoneData?.length ) {
      if (indexKey.indexOf(index) !== -1 && count1==10) {
        indexKey.splice(indexKey.indexOf(index), 1);
        setPhoneFormatTest(false);
      }
      // if(phoneData.length==9)
      // {
      //   console.log("pop")
      //   indexKey.pop()
      // }
    } else if (phoneData === "") {
      setPhoneFormatTest(true);
      if (indexKey.indexOf(index) !== -1) {
        indexKey.splice(indexKey.indexOf(index), 1);
      }
    } else {
      return setPhoneFormatTest(true);
    }
  };

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = (email, index) => {
    console.log(index);
    if (!validEmail.test(email) && email !== "") {
      setEmailError(true);
      if (indexKey.indexOf(index) !== -1) {
        indexKey.splice(indexKey.indexOf(index), 1);
      }
    } else {
      setEmailError(false);
      if (indexKey.indexOf(index) !== -1) {
        indexKey.splice(indexKey.indexOf(index), 1);
      }
    }
  };

  const imageInput = useRef()
  const buttonDisabled =state.designation!=="" && state.organization!==""  
  const buttonDisable2=checkDisable.every((item)=>{ return formData[item]!==""&&formData[item]!==undefined })

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }

    if (
      event.target.files[0].name &&
      !event.target.files[0]?.name?.match(/\.(jpg|jpeg|png|webp)$/)
    ) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false);
    }
  };

  const handleChange = (e) => {
    setState({ ...state, biography: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };

  const handleSubmit = () => {
    if (!disableSave) addAttendeesApi(state, formData);
  };

  const handleDelete = () => {
    setState({ ...state, image: "" });
    setImage(null)
    imageInput.current.value = "";

  }

  const handleChangeData = (e, data) => {
    if (e.target.value.length <= data.maximumLength) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
      if(data.required)
      {
        
      }
    } 
    // else {
    //   alert(`The limit of this field is ${data.maximumLength}`)
    // }

  }
  
  useEffect(() => {
    getRegistraition()
  }, [])
  useEffect(()=>{
    const newArray=getRegData.filter((item)=>{return item.required==true}).map((item)=>{return item.columnName})
   setCheckDisable([...newArray])
   console.log(newArray);
  },[getRegData])

  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
          <div className="flex pt-3">
            <Link to={"/attendees"}>
              <div className="w-8 pt-1">
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </div>
            </Link>
            <div className="p-2">
              <h1 className=" text-xl px-2 ">Attendees Information </h1>
            </div>
          </div>
          <div className="flex flex-col p-4 border-[#D1D9E7] bg-white rounded-lg">
          <div className="flex space-x-10 m-0 ">
            <div className="w-1/2 space-y-3 ">
              <h2 className="font-semibold text-lg ">Attendees details</h2>
              <hr />
              <form className="">

                {
                  getRegData.length > 0 && getRegData.map((item, index) => (
                    <div>
                      <label
                        key={item?._id} htmlFor={item?.columnName}
                        className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                      >
                        {" "}
                        {item.columnName} {item?.required==true?" *":""}
                      </label>
                      <input
                        type="text"
                        id={item?.columnName}
                        name={item.columnName} // Add the name attribute to match the state variable
                        placeholder={`Enter your ${item?.columnName}`}
                        maxlength={item.validation=="mobilenumber"?10:""}
                        className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9 ${phoneFormatTest ? "" : "mb-4"}`}
                        onChange={(e) => {
                          console.log("inside onchnage")
                          if (!indexKey.includes(index) && (item.validation == "mobilenumber" || item.validation == "email")) {
                            indexKey.push(index)
                          }
                          // setState({ ...state, Name: e.target.value });
                          handleChangeData(e, item)
                        }}
                        onKeyUp={() => validationHandler(item, index)}
                      />
                      { item.validation == "email" && emailError ? (
                        <span className="text-left text-red-600 pt-0 text-xs ">
                          Please enter a valid Email address
                        </span>
                      ) : (<></>)}
                      { item.validation == "mobilenumber" && phoneFormatTest  && phoneLength<10 ? (
                        <span className="text-left text-red-600 mb-4 text-xs">
                          Please enter a valid 10 digit Number.
                        </span>
                      ) : (<></>)}
                    </div>
                  ))
                }


                {/* <div>
                  <label
                    htmlFor="name"
                    className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    {" "}
                    Name *
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your name"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9"
                    value={state.Name}
                    onChange={(e) => {
                      setState({ ...state, Name: e.target.value });
                    }}
                  />
                </div> */}

                {/* <label htmlFor="last-name" className='pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]'>Last name*</label>
              <input type='text' id="last-name" name="lastname" placeholder="Enter your last name" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 '
                value={state.lastname}
                onChange={(e) => { setState({ ...state, lastname: e.target.value }) }} /> */}
                {/* <div>
                  <label
                    htmlFor="email-address"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    Email Address *
                  </label>
                  <input
                    type="text"
                    id="email-address"
                    name="email"
                    placeholder="Enter your email address"
                    className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${emailError ? "" : "mb-4"
                      } `}
                    value={state.email}
                    onChange={(e) => {
                      setState({ ...state, email: e.target.value });
                    }}
                    onKeyUp={() => validateEmail()}
                  />
                  {emailError && (
                    <span className="text-left text-red-600 text-sm ">
                      Please enter a valid Email address
                    </span>
                  )}
                </div> */}
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    Organization *
                  </label>
                  <input
                    id="organization"
                    type="text "
                    name="organization"
                    placeholder="Enter your organization"
                    className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9`}
                    value={state.organization}
                    onChange={(e) => {
                      setState({ ...state, organization: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="designation"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                   Designation *
                  </label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    placeholder="Enter your designation"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9"
                    value={state.designation}
                    onChange={(e) => {
                      setState({ ...state, designation: e.target.value });
                    }}
                  />
                </div>
                {/* <div className="pb-2">
                  <label
                    htmlFor="phone"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    Mobile Number *
                  </label>
                  <input
                    type="text"
                    maxLength={10}
                    pattern="[0-9]{10}"
                    id="phone"
                    name="phone"
                    placeholder="Enter your phone"
                    className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9 ${phoneFormatTest ? "" : "mb-4"
                      }`}
                    value={state.phone}
                    onChange={(e) => {
                      setState({ ...state, phone: e.target.value });
                    }}
                    onKeyUp={() => validatePhoneno(state.phone)}
                  />
                  {phoneFormatTest ? (
                    <span className="text-left text-red-600 text-sm">
                      Please enter a valid 10 digit Number.
                    </span>
                  ) : (
                    <></>
                  )}
                </div> */}
              </form>
            </div>
            <div className="w-1/2 pt-14 ">
              <div className="relative mb-5">
                <label
                  htmlFor="biography"
                  rows="4"
                  className="pt-16 font-semibold text-sm pb-1 text-[#202223]"
                >
                  Biography
                </label>
                <textarea
                  rows="4"
                  id="biography"
                  name="biography"
                  className="w-full  pt-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm "
                  value={state.biography}
                  maxLength={2000}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500 ">
                    Provide some details about the speaker's
                    background,achievements and presentation goals.
                  </span>
                  <span className=" bottom-1 text-xs mt-1"> {count}/2000</span>
                </div>
              </div>
              <div>
                <label className="text-sm font-semibold mt-5 text-[#202223]">
                  Profile Image
                </label>
                <div className=" h-28 border-2 border-dashed rounded-md text-center flex flex-col justify-center items-center hover:border-theme-color">
                  <label
                    htmlFor="choose_banner"
                    accept="image/png, image/gif, image/jpeg, image/svg"
                    className="text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs  "
                  >
                    {" "}
                    {state.image === "" || imageError
                      ? "Choose profile image"
                      : state.image.name}
                    <input
                      id="choose_banner"
                      type="file"
                      ref={imageInput}
                      className="text-theme-color hidden   bg-orange-200 px-1 text-xs rounded-md "
                      onChange={(e) => {
                        setState({ ...state, image: e.target.files[0] });
                        onImageChange(e);
                      }}
                    />
                  </label>
                  <p className="text-sm pt-1 text-gray-400 leading-4 ">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                  </p>
                </div>
                <span className="text-xs text-red-600 font-normal">
                  {imageError ? imageError : ""}
                </span>
                <div className={image === null || imageError ? "hidden" : "relative w-28 h-28 mt-4"}>
                  <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2"
                    onClick={() => handleDelete()} />
                  <img
                    src={image}
                    alt="banner"
                    className="absolute w-full h-full object-cover"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <Link to="/attendees">
              <button className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button
              type="button"
              disabled={disableSave ? buttonDisabled && buttonDisable2 : !(buttonDisabled && buttonDisable2) }
              className="bg-theme-color disabled:opacity-50 px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3"
              onClick={() => {
                handleSubmit();
              }}
            >
              Save
            </button>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default AddAttendees;
