import React, { useContext, useState ,useEffect} from "react";


import { FaCircle } from "react-icons/fa";

import { FiMail } from "react-icons/fi";


import { FiBell } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import NoDataModal from "../NoDatatModal/NoDataModal";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { ToastContainer } from "react-toastify";

const Meetings = () => {
  const {getMeetingsListApi,meetingsList, globalLoader,checkResponse,  postMeetingEmailApi, meetingNotificationApi}=useContext(DataContext)

  const handleEmail=(slotId)=>{
    postMeetingEmailApi(slotId)
  }
const handleNotification=(slotId)=>{
  meetingNotificationApi(slotId)
}
  useEffect(()=>{
    getMeetingsListApi()
  },[])
 console.log(meetingsList.length,"length")
  return (
    <>
    <ToastContainer/>
      <div className="w-full px-7 py-2 overflow-y-scroll scrollbar-thin h-screen space-y-4">
        <div className="pt-3 ">
          <h1 className="text-xl text-[#202223] font-semibold">Meetings</h1>
        </div>
      
  {
    meetingsList.length===0  && checkResponse===true? <NoDataModal/> :globalLoader ? <GlobalLoader/>: 
    <table className="w-full table-auto bg-white text-start shadow-md  rounded-lg ">
    <thead className="cursor-pointer">
      <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal ">
        <th className="w-[15%] px-4 py-2 rounded-tl-lg">Attendee Name</th>
        <th className="w-[15%] px-4 py-2 rounded">Requested By</th>
        <th className="w-[15%] px-4 py-2">Requested On</th>
        <th className="w-[15%] px-4 py-2">Time slot</th>
        <th className="w-[10%] px-4 py-2">Status</th>
        <th className="w-[10%] px-4 py-2">Action</th>

      </tr>
    </thead>
    <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
      {
       meetingsList.length!==undefined && meetingsList?.map((item)=>{
          return (
            <>
            <tr className=" border-t text-left text-sm text-[#202223] font-normal hover:shadow-tableRowsShadow cursor-pointer" key={item?._id}>

<td className=" py-2 px-4 ">
{item?.userId?.Name}
</td>
<td className="py-2 px-4">
{item?.bookedBy?.Name}
</td>
<td className="py-2 px-4">{item?.date.slice(0,10)}</td>
<td className=" py-2 px-4">{item?.slot}</td>
<td className=" py-2 px-4">

<div className={`${item.status==="Accept" ? "bg-[#AEE9D1]" : item?.status==="Pending" ? "bg-[#FFEA8A]" :"bg-[#FED3D1]"} flex items-center gap-2  py-2 justify-center rounded-[10px]`}>
<span className={`${item.status==="Accept" ? "text-[#007F5F]" : item?.status==="Pending" ? "text-[#916A00] " : "text-[#D72C0D]"}`}><FaCircle /></span>
<span className="text-xs  font-normal">{`${item.status==="Accept" ? "Accepted" : item?.status==="Pending" ? "Pending " : "cancel"}`}</span>
</div>


</td>
<td className=" py-2 pl-4 pr-12 ">
<div className="flex items-center justify-between">
<span className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full p-2"><FiMail size={20}  onClick={()=>{handleEmail(item?._id)}}/></span>
<span className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full p-2"><FiBell size={20}  onClick={()=>{handleNotification(item?._id)}}/></span>
</div>



</td>
</tr>
            </>
          )
        })
      }
      
     



    </tbody>
  </table>

  }
      
       
      </div>
    </>
  )
}
export default Meetings;
