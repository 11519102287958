import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'
import { FiEdit2, FiTrash } from "react-icons/fi"
import fetchUtil from '../../utils/fetch.util'
import { DELETE_USER_BY_ID, GET_ALL_USERS } from '../../constants/apiEndPoints'
import { dateFormatter } from '../../utils'
import { toast } from 'react-toastify';
import Popover from '../atoms/Popover'
import GlobalLoader from '../GlobalLoader/GlobalLoader'
import NoDataModal from '../NoDatatModal/NoDataModal'
import no_image_dp from "../../assets/images/no_image_dp.svg";
import exportToXLS from '../../utils/exportXLS'

const tableHeader = [
  { label: 'Picture', value: 'image' },
  { label: 'Name', value: 'Name' },
  { label: 'Email', value: 'email' },
  { label: 'Role', value: 'role' },
  { label: 'Last login', value: 'lastLogin' },
  { label: 'Actions', value: 'actions' }
];

const Users = () => {
  const eventID = JSON.parse(localStorage.getItem("eventId")) || "";
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPopOpen, setIsPopOpen] = useState(false);
  const [userID, setUserID] = useState();


  const fetchAllUsers = () => {
    setLoading(true);
    fetchUtil.GET(`${GET_ALL_USERS}/${eventID}`)
      .then((response) => {
        console.log('userDetails-', response)
        setUserDetails(response.Result.docs);
      })
      .catch((err) => {
        console.error(err);
        toast.error(err);
      }).finally(() => setLoading(false));
    ;
  }
  useEffect(() => {
    fetchAllUsers();
  }, []);

  const handleEditAction = (id) => {
    navigate(`/edit_user/${id}`)
  }

  const handleDeleteAction = (id) => {
    setUserID(id);
    setIsPopOpen(true);
  }

  const handlePopAction = (action) => {
    if (action.target.name === 'no') setIsPopOpen(false);
    if (action.target.name === 'yes') {
      fetchUtil.DELETE(`${DELETE_USER_BY_ID}/${userID}`)
        .then((response) => {
          toast.success("Deleted successfully");
          setTimeout(() => { setIsPopOpen(false) }, 500);
          setTimeout(() => {
            fetchAllUsers();
          }, 1000);
        })
        .catch((err) => {
          console.error(err);
          toast.error(err);
        }).finally(() => setLoading(false));
    }
  }

  const handleExportXLS = () => {
    exportToXLS(tableHeader, userDetails, 'user_table');
  }

  return (
    <>
      <div className="w-full px-7 py-2 overflow-y-scroll h-screen">
        <div className="pt-3 ">
          <div>
            <h1 className="text-xl  font-semibold  ">
              Users
            </h1>
          </div>
          <div className="flex pt-5 text-sm">

            <Link to={"/user-roles"} >
              <button className=" hover:underline hover:text-theme-color pr-4">User Roles</button>
            </Link>
            <Link to={"/users"} >
              <button className="hover:underline text-theme-color underline hover:text-theme-color">Users</button>
            </Link>

          </div>
        </div>
        <div className=" flex justify-between pt-4 mb-4 items-start flex-wrap">
          <div className="flex gap-1">
            <Link to="/add_user">
              <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                Add User
              </button>
            </Link>
            <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
              onClick={handleExportXLS}>
              Export XLS
            </button>
          </div>
          <div className="float-right flex">
            <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
            <input type="text" placeholder="Search"
              className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded outline-none"
            />
          </div>
        </div>
        {loading ? <GlobalLoader /> :
          <table className="w-full table-auto bg-white shadow-md rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left bg-theme-light-color text-theme-color text-xs font-normal ">
                {tableHeader.map((item) => (
                  <th className="px-4 py-2">{item.label}</th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {userDetails.length > 0 ? userDetails.map((item) => (
                <tr className="border-t text-left text-sm text-[#202223] font-normal hover:shadow-tableRowsShadow  cursor-pointer">
                  <td className="px-4 py-2">
                    <img src={item.image || no_image_dp} className="w-8 h-8 rounded-[2px]" alt="speaker" />
                  </td>
                  <td className="px-4 py-2">{item.Name}</td>
                  <td className="px-4 py-2">{item.email}</td>
                  <td className="px-4 py-2">{item.role}</td>
                  <td className="px-4 py-2">{dateFormatter(item?.lastLogin)}</td>
                  <td className='px-4 py-2'>
                    <div className="flex items-center  justify-center  gap-2 text-black ">
                      <FiEdit2 className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' onClick={() => handleEditAction(item._id)} />
                      <FiTrash className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' onClick={() => handleDeleteAction(item._id)} />
                    </div>
                  </td>
                </tr>)) : <NoDataModal />}
            </tbody>
          </table>}
      </div>
      <Popover isPopOpen={isPopOpen} handleAction={handlePopAction} />
    </>
  )
}

export default Users
