import React, {useContext, useEffect} from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { DataContext } from "../../context/DataState";

const PreviewModal = ({ setPreviewDialog }) => {
    const { getRegistraition, getRegData } = useContext(DataContext);

    console.log(getRegData, "getRegData")

    const handleClose = () => {
        setPreviewDialog(false);
    }

    // useEffect(()=>{
    //     getRegistraition()
    // }, [])

    return (
        <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[1]">
            <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
                <div className="bg-white p-8 w-[400px] rounded z-10">
                    <AiOutlineCloseCircle className='absolute right-4 top-4 text-red-400' onClick={() => handleClose()} />
                    <h3 className='text-center font-medium text-[24px] pb-6'>Registration Form Preview</h3>
                    <div className='flex flex-col gap-4'>
                        {getRegData.length > 0 ? getRegData.map((item)=>(
                            <>
                             <div>
                            <label className='block pb-1' htmlFor="">{item.columnName}</label>
                            <input placeholder={`enter your ${item.columnName}`} className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1" type="text" readOnly />
                        </div>
                            </>
                        )) : ""}
                       
                        {/* <div>
                            <label className='block pb-1' htmlFor="">Email</label>
                            <input placeholder='enter your email' className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1" type="email" readOnly />
                        </div>
                        <div>
                            <label className='block pb-1' htmlFor="">Phone</label>
                            <input placeholder='enter your phone' className="w-full border py-2 px-3 border-gray-300 text-sm placeholder:text-slate-400   focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1" type="number" readOnly />
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreviewModal