import React, { useContext, useEffect, useState } from "react";
import { Link, useParams ,useLocation} from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const UpdateSponserLevel = () => {
  const { getSponsorLevelApi, sponsorsLevel ,updateSponsorLevelApi,disableSave,isSpinner} = useContext(DataContext);
  
  const { id } = useParams();

  const location = useLocation()
    const sponsorLevelEdit = location.state;
    const [editSponsorLevel, setEditSponsorLevel] = useState(sponsorLevelEdit);

  const handleUpdate = () => {
    if(!isSpinner)
    updateSponsorLevelApi(editSponsorLevel ,id);
    
  };
  

  const [number, setNumber] = useState(false);

  const validateNumber = (NumberData) => {
    var num = /\-?\d*\.?\d{1,2}/;
    if (NumberData.match(num)) {
      return setNumber(false)
    }
    else if (NumberData === '') {
      return setNumber(false)
    }
    else {
      return setNumber(true)

    }
  }

  const buttonDisabled = (
    // editSponsorLevel.id !== "" &&
    editSponsorLevel?.levelname !== "" &&
    editSponsorLevel?.levelColor !== "" &&
    editSponsorLevel?.order !== "" )
  

  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex items-center">
            <Link to="/sponsorlevel">
              <div className="shadow-xs border-2 p-2  ">
                <FaArrowLeft className="text-[20px] text-[#5C5F62]" />
              </div>
            </Link>
            <div>
              <h1 className="text-xl pl-3">{editSponsorLevel?.level_name}  </h1>
            </div>
          </div>
          
          <div className="flex flex-col rounded-lg p-3 border-[#D1D9E7] bg-white w-1/2">
          <div>
            <h2 className="font-semibold text-lg">Base Data</h2>
            <hr className="w-full" />
          </div>
          <div className="flex space-x-10">
            <div className="w-full space-y-3 pt-1">
              <form className="">
                
                <div className="pt-2">
                  <label
                    htmlFor="level_name"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Level Name
                  </label>
                  <input
                    type="text"
                    name="level_name"
                    placeholder="Enter level name"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                    value={editSponsorLevel?.level_name} onChange={(e)=>{setEditSponsorLevel({...editSponsorLevel,level_name:e.target.value})}}
                  />
                </div>
                <div className="relative pt-2">
                  <label
                    htmlFor="level_color"
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Level Color
                  </label>
                  <div className="border border-gray-300 rounded-md py-1 px-3">
                    <input
                      type="color"
                      
                      placeholder="Enter level color"
                      id="level_color"
                      onChange={(e)=>{setEditSponsorLevel({...editSponsorLevel,level_color:e.target.value})}}
                      value={editSponsorLevel?.level_color}
                      className=" absolute left-2 focus-visible:border-[#fb923c] placholder:text-slate-400 text-sm h-6"
                    />
                  
                    <span className=" text-sm px-12 font-semibold pb-1">
                      {editSponsorLevel.level_color}
                    </span>
                  </div>
                </div>
                <div className="pt-2">
                  <label
                    htmlFor="number"
                    required
                    className=" text-sm px-1 font-semibold pb-1"
                  >
                    Level Order
                  </label>
                  <input
                    type="text"
                    id="number"
                    placeholder="Enter your level order"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm h-9"
                    value={editSponsorLevel?.order} onChange={(e)=>{setEditSponsorLevel({...editSponsorLevel,order:e.target.value})}}
                    onKeyUp={ ()=>{validateNumber(editSponsorLevel.order)}}
                  />
                   {number ? <span className="text-red-600 text-sm">Please enter your level order in number</span>:<></>}
                </div>
                <div className="flex pt-6 gap-3">
                  <Link to={"/sponsors"}>
                    <button
                      type="button"
                      className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded"
                    >
                      Back
                    </button>
                  </Link>
                  <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleUpdate();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
                </div>
              </form>
            </div>
          </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default UpdateSponserLevel;
