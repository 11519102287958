import React,{useContext, useState} from "react"
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const UpdateContact=()=>{
  const {contactId}=useParams()
  const { updateContactApi,isSpinner}=useContext(DataContext)
  const location = useLocation()
  const contactEdit = location.state.item;
  const isContactActive=location.state.isContactActive
  const id=location.state.id 
  const name=location.state.name
  const [checkbox,setCheckbox]=useState(false)
 
  const [editContact, setEditContact] = useState(contactEdit);
  const [emailError,setEmailError]=useState(false)
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  console.log(editContact,"editContact")
  const handleUpdate=()=>{

if(!isSpinner) updateContactApi(editContact,contactId,id,name,isContactActive)
  }

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false)
    }
    else if (phoneData == '') {
      return setPhoneFormatTest(false)
    }
    else {
      return setPhoneFormatTest(true)

    }
  }

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const obj={
    attendeeTemplate:"Attendee Template",
    passwordReset:"Password Reset",
    subAdminTemplate:"SubAdmin Template",
    credentialsTemplate:"Credentials Template",
    meetingRequestTemplate:"Meeting Request Template",
  }

  const validateEmail = () => {
    if (!validEmail.test(editContact?.email) && editContact?.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  const btnDisabled =
  editContact?.firstName !== "" &&
  editContact?.email !== "" &&
  editContact?.lastName !== "" &&
 
  editContact?.phoneNumber !== "" &&
  
  !emailError &&
  !phoneFormatTest;
  console.log(editContact?.sendInvitationMail
    ,"invitation mail")
  
  return (
    <>
    <ToastContainer/>
     <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
            <Link to={"/addcontactlist"} state={{isContactActive:isContactActive,id:id,name:name}}>
              <button className="border p-2">
                <FaArrowLeft />
              </button>
              </Link>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Add Contact Manually </h1>
          </div>
        </div>
        <div className="flex space-x-10 m-0 bg-white p-3 rounded">
          <div className="w-1/2 space-y-3 ">
            <h2 className="font-semibold text-lg ">Contact details</h2>
            <hr className="w-[207%] border-1 border-[#BDBDBD]" />
            <form className="">
              <div> 
                <label
                  htmlFor="name"
                  className="text-sm px-1 font-medium pb-1 text-[#202223]"
                >
                  First Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                 
                  placeholder="Enter First Name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                  value={editContact?.firstName}
                  onChange={(e) => {
                    setEditContact({
                      ...editContact,
                     firstName: e.target.value,
                    });
                  }}
                />
              </div>
              <div>
                <label
                  htmlFor="lastname"
                  className="text-sm px-1 font-medium  text-[#202223]"
                >
                  Last Name *
                </label>
                <input
                  type="text"
                  id="lastame"
                  name="lastname"
                  
                  placeholder="Enter Last Name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                  value={editContact?.lastName}
                  onChange={(e) => {
                    setEditContact({
                      ...editContact,
                     lastName: e.target.value,
                    });
                  }}
                
                />
              </div>
              <div className="">
                <label
                  htmlFor="phonenumber"
                  className="text-sm px-1 font-medium  text-[#202223]"
                >
                  Phone Number *
                </label>
                <input
                  id="phonenumber"
                  type="tel"
                  name="phonenumber"
                  placeholder="Enter your contact number"
                  maxlength={10}
                 
                  pattern="[0-9]{10}"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                  value={editContact?.phoneNumber}
                  onChange={(e) => {
                    setEditContact({
                      ...editContact,
                     phoneNumber: e.target.value,
                    });
                  }}
                  onKeyUp={() => validatePhoneno(editContact.phoneNumber)}
                />
                {phoneFormatTest ? (
                  <span className="text-red-600 text-sm">
                    Please enter a valid 10 digit Number.
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="mt-3">
                <input
                  type="checkbox"
                  name="invitationEmail"
                  id="invitationEmail"
                  checked={(editContact?.sendInvitationMail || checkbox) ? "checked" : ""}
                  onChange={(e) => {
                    setCheckbox(!checkbox)
                  }}
                />
                <span className="mt-4 text-sm px-3 font-medium  text-[#202223]">
                  Send Invitation Email
                </span>
              </div>

              <div>
                <select
                  id="template"
                  type="text"
                  name="template"
                  defaultValue={obj[editContact?.sendInvitationMail]}
                  checked={editContact?.sendInvitaionEmail ? "checked" : ""}
                  onChange={(e)=>{
                   
                   setEditContact({...editContact,sendInvitationMail:e.target.value})}}
                  className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                >
                  {/* {
(editContact?.sendInvitaionMail && editContact?.sendInvitaionMail!="") &&  <option value={editContact?.sendInvitaionMail} >
{obj[editContact?.sendInvitaionMail]}
</option>
                  } */}
                  {/* {
                    editContact?.sendInvitaionMail=="" && <option value="" >
                  Choose Template
                    </option>
                  }  */}
                 <option value=""  >
                 {editContact?.sendInvitationMail ? obj[editContact?.sendInvitationMail] : "Choose Template"}
                    </option>
                
               <option value="attendeeTemplate" >Attendee Template</option> 
             <option value="passwordReset">Password Reset</option> 
              <option value="subAdminTemplate" >SubAdmin Template</option>
              <option value="credentialsTemplate" >Credentials Template</option>
              <option value="meetingRequestTemplate" >Meeting Request Template</option>
                </select>
              </div>

              <div>
                <div className="pt-5 flex gap-3">
                <Link to={"/addcontactlist"} state={{isContactActive:isContactActive,id:id,name:name}}>
                    <button className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded">
                      Back
                    </button>
                  </Link>
                 
                  <button type="button" 
disabled={!btnDisabled}
onClick={()=>{handleUpdate()}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
                </div>
              </div>
            </form>
          </div>
          <div className="w-1/2 pt-14 ">
            <div className="">
              <label
                htmlFor="organisation"
                className="pt-2 text-sm px-1 font-smedium  text-[#202223]"
              >
                Organisation
              </label>
              <input
                type="text"
                id="organisation"
                name="organisation"
               
                placeholder="Enter Organisation"
                className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                value={editContact?.organization}
                  onChange={(e) => {
                    setEditContact({
                      ...editContact,
                     organization: e.target.value,
                    });
                  }}
              
              />
            </div>
            <div className="mt-3">
              <label
                htmlFor="position"
                className="pt-2 text-sm px-1 font-medium  text-[#202223]"
              >
                Position
              </label>
              <input
                type="text"
                id="position"
                name="position"
             
                placeholder="Enter Position"
                className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                value={editContact?.position}
                onChange={(e) => {
                  setEditContact({
                    ...editContact,
                  position: e.target.value,
                  });
                }}
              
              
              />
            </div>
            <div className="mt-3">
              <label
                htmlFor="position"
                className="pt-2 text-sm px-1 font-medium  text-[#202223]"
              >
                Email Address *
              </label>
              <input
                type="email"
                id="email"
                name="email"
              
                placeholder="Enter Email Address"
                className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                value={editContact?.email}
                onChange={(e) => {
                  setEditContact({
                    ...editContact,
                  email: e.target.value,
                  });
                }}
                onKeyUp={() => validateEmail()}
              />
              {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default UpdateContact;