import React, { useContext, useState } from "react";
import { DataContext } from "../../context/DataState";

const SendEmailModal=({selectedItems,setSelectedItems,module})=>{
  const [state,setState]=useState({
    template:""
  })
 
  const buttonDisabled=state?.template!==""&& selectedItems.length>0
  const {setIsSendMail, sendEmailApi,sendAttendeeEmailApi,isSpinner}=useContext(DataContext)
  const isArrayOfObjects = selectedItems.every(item => typeof item === 'object');
  console.log(state,"state")
  console.log(buttonDisabled,"buttondisabled")
  return (
    <>
    <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[1]">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white px-8 py-5 w-[60vh] h-[38vh] rounded z-10">
            <div className="pt-2 ">
              <h1 className="text-xl text-[#202223] font-semibold">
               Send Email
              </h1>
            </div>
            <div className="mb-1 mt-3">
              <hr className="bg-[#0000001A] h-[2px]" />
            </div>

            <div className="mt-5">
                  <label
                    htmlFor="name"
                    className="text-sm px-1 font-semibold "
                  >
                 Template
                  </label>
                 
                  
                  <select
                    id="template"
                    type="text"
                    name="template"
                   
                    className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                   onChange={(e)=>{setState({...state,template:e.target.value})}}
                  >
                    
                     <option value="" selected disabled hidden>Choose Template</option>
              <option value="attendeeTemplate">Attendee Template</option>
              <option value="passwordReset">Password Reset</option>
              <option value="subAdminTemplate">SubAdmin Template</option>
              <option value="credentialsTemplate">Credentials Template</option>
              <option value="meetingRequestTemplate">Meeting Request Template</option>
              
                  </select>
                
               
                </div>
                {selectedItems.length==0 &&  <span className="text-left pb-6 text-red-600 text-xs">Please select atleast one user</span>}   

                <div className="flex gap-2 items-center mt-2">
                  <span className="py-1 px-2 text-theme-color bg-theme-light-color text-sm font-normal rounded" >{selectedItems.length}</span>
                  <span className="text-sm font-normal">{isArrayOfObjects ? "Users Selected" : "Contact lists Selected"}</span>
                </div>

            <div className="flex items-center justify-center mt-3 gap-2 ">
            <button className="border w-1/2  border-[#BABFC3] w-[200px] px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
          onClick={()=>{setIsSendMail(false)}}
            >

              
                 Back
                </button>
            
              <button type="button" 
disabled={!buttonDisabled}
onClick={()=>{
  setIsSendMail(false)
  if(module=="attendee" && !isSpinner)
  {
    sendAttendeeEmailApi(selectedItems,setSelectedItems)
  }
  else if(module!="attendee" && !isSpinner)
  {
    sendEmailApi(selectedItems,state,setSelectedItems)
  }
 
}}
className="py-2 px-4 w-1/2 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SendEmailModal;


