import React from "react";
import { useContext,useEffect,useState } from "react";

import { DataContext } from "../../context/DataState";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import dummyImage from "../Assets/dummyimage.jpg"
import NoDataModal from "../NoDatatModal/NoDataModal";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { FiMoreHorizontal } from "react-icons/fi";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsExclamationTriangle } from "react-icons/bs";

const Activity = () => {
  const {notificationList,getNotificationApi,checkResponse,globalLoader}=useContext(DataContext)
  const [currentSlide, setCurrentSlide] = useState(0);

  TimeAgo.addDefaultLocale(en)
  const timeAgo = new TimeAgo('en-US')

  const CustomDot = ({ active }) => (
    <div className={`custom-dot ${active ? " active" : ""}`}></div>
  );

  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    speed: 250,
    arrows: false,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    autoplay: true,
    appendDots: (dots) => <ul className="custom-dots">{dots}</ul>,
    customPaging: (i) => <CustomDot key={i} active={i === currentSlide} />,
  };
  const handleSlideChange = (next) => {
    setCurrentSlide(next);
  };

  useEffect(()=>{
    getNotificationApi()
  },[])
console.log(notificationList,"notificationlist")
  console.log(notificationList,"notificationList")

  return (
    <>
      <div className="w-full ">
        {
          notificationList.length===0 && checkResponse===true ? <NoDataModal/> :
           globalLoader ? (
             <GlobalLoader />
           ) : 
          notificationList.map((item)=>{
            return (
              <>
               <div className="bg-white rounded-md p-5">
                {
                  item?.reports_id.map((element)=>{
                    return (
                      <>
                       <div className="flex gap-3 items-center mb-2">
            <BsExclamationTriangle className="text-theme-color" size={36}/>
            <div className="flex flex-col text-sm">
              <div className="flex gap-1">
              <span className="text-theme-color">{element?.Name}</span>
              <span>reported</span>
              <span className="italic">{item?.post_id?.attendee_id?.Name}</span>
              <span>post</span>
              </div>
              <p className="text-[13px] font-normal text-[#747474] ">
              {timeAgo.format(
                          Date.now() -
                            (Date.now() - Date.parse(item?.createdAt)),
                          "round"
                        )} 
                      </p>
              
            </div>
          </div>
                      </>
                    )
                  })
                }
         

        <div className={`bg-[#FAFBFB]  w-full  rounded-md mt-4`}>
                <div className="px-6 pb-1 flex justify-between">
                  <div className="flex space-x-3 my-2 pt-3">
                    <img
                      src={(item?.post_id?.attendee_id?.image==undefined ||item?.post_id?.attendee_id?.image=="")?dummyImage : item?.post_id?.attendee_id?.image}
                      className="w-12 h-12 rounded-full "
                    />
                    <div>
                      <p className="text-[14px] font-semibold ">{item?.post_id?.attendee_id?.Name}</p>
                      <p className="text-[13px] font-normal text-[#747474] ">
                        {timeAgo.format(
                          Date.now() -
                            (Date.now() - Date.parse(item?.post_id?.createdAt)),
                          "round"
                        )} 
                      
                      </p>
                    </div>
                  </div>
                  <div className="text-[18px] text-[#868EAE] pt-4">
                    <div class="relative inline-block text-left">
                      

                    
                    </div>
                  </div>
                </div>

                <hr className="text-gray-50" />
                <div className="px-6 py-2">
                  {item?.post_id?.image?.length > 0 && (
                    <Slider {...settings} beforeChange={handleSlideChange}>
                      {item?.post_id?.image?.map((element, index) => (
                        <div key={index}>
                          <img
                            src={element}
                            alt={`Image ${index}`}
                            className="w-full object-scale-down h-[250px] rounded-lg"
                          />
                        </div>
                      ))}
                    </Slider>
                  )}

                  <div className="py-3">
                    <p className="text-sm  font-normal text-[#404040] w-[60%]">
                   {item?.post_id?.caption}
                    </p>
                  </div>
                </div>
              </div>
        </div>
              </>
            )
          })
        }
       

              
           
      </div>
    </>
  );
};

export default Activity;
