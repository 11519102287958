import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { FiPlus, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { DataContext } from "../../context/DataState";

import { ToastContainer } from "react-toastify";

const Addpolls = () => {
  // ----------------Add input text fuctional state---------//
  const {getAgendaById, agendaList,addPollApi,disableSave,specificEvent,getEventByIdApi,isSpinner}=useContext(DataContext)
  

  const [state,setState]=useState({
    pollText:"",
    startTime:"",
    endTime:"",
    agenda_id:"",
    
  });
  const [text, setText] = useState("");
  const [texts, setTexts] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
 
  const buttonDisabled=state.pollText !== "" && state.agenda_id !== "" && texts.length >=1 

  let startDate = new Date(specificEvent?.eventStartDate).toLocaleDateString('en-CA').split('T')[0];
  let endDate = new Date(specificEvent?.eventEndDate).toLocaleDateString('en-CA').split('T')[0];
console.log(specificEvent,"specific evnt")
 
  let todayDate=new Date().toISOString().split('T')[0]
  if (startDate.slice(8,10)<todayDate.slice(8,10) && (startDate.slice(0,4)===todayDate.slice(0,4)&& startDate.slice(5,7)===todayDate.slice(5,7)) )
  {
     startDate=todayDate;
  }
   

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleAdd = () => {
    if (texts.length < 4 && text.trim() !== "") {
      setTexts([...texts,{"option":text} ]);
      setText("");
      if (texts.length === 3) {
        setShowAddButton(false);
      }
    }
  };
  const handleDelete = (index) => {
    const updatedTexts = [...texts];
    updatedTexts.splice(index, 1);
    setTexts(updatedTexts);
    if (texts.length === 4) {
      setShowAddButton(true);
    }
  };
  const handleSubmit=()=>{
    if(!isSpinner)
    addPollApi(state,texts);
  }

  useEffect(()=>{
    getEventByIdApi()
   
    getAgendaById();
  },[])
 
  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <Link to={"/polls"}>
            <div className="flex">
              <div className="w-8 pt-1">
                <button className="border p-2">
                  {" "}
                  <FaArrowLeft />
                </button>
              </div>
              <div className="p-2 ">
                <h1 className="px-2 text-xl">Polls</h1>
              </div>
            </div>
          </Link>
            <div className="flex flex-col bg-white border-[#D1D9E7] p-3 rounded-lg">
            <div className="flex space-x-10">
            <div className="w-1/2 space-y-3 ">
              <h2 className="font-medium text-md">Question details</h2>
              <hr />
              <form className="">
                <div className="py-2">
                  <label
                    htmlFor="question"
                    className="text-fontSize font-medium text-[#202223] px-1  pb-1"
                  >
                    Question *
                    <input
                      type="text "
                      id="question"
                      className="w-full border  py-2 px-3 placeholder-slate-400 placeholder:text-slate-400 text-sm h-9 rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      value={state.pollText} onChange={(e)=>{setState({...state,pollText:e.target.value})}}
                      placeholder="Enter question for poll"
                    />
                  </label>
                </div>
                <div className="py-1">
                  <label
                    htmlFor="answers"
                    className="pt-2  px-1  pb-1 text-fontSize font-medium text-[#202223]"
                  >
                    Answers * (atleast 2 options)
                  </label>

                  <div className="">
                    <div className="space-2">
                      {texts.map((item, index) => (
                        <label key={index}>
                          <input
                            id="answers"
                            className="w-full border text-fontSize placeholder:text-slate-400 text-sm h-9 py-2 pl-2 pr-10 placeholder-slate-400  rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                            key={index}
                            type="text"
                            value={item.option}
                           
                          />
                          <FiTrash2
                            className="cursor-pointer text-[#DB4446] float-right relative -top-7 mr-3 text-lg"
                            onClick={() => handleDelete(index)}
                          />
                        </label>
                      ))}
                    </div>
                    {showAddButton && (
                      <label className="text-fontSize">
                        <div className="">
                          <input
                            type="text "
                            id="Answers"
                            className="w-full border border-dashed border-gray-300 py-2 pl-2 pr-10 placeholder-slate-400  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                            placeholder="Add answer"
                            value={text}
                            onChange={handleChange}
                          />
                          <FiPlus
                            className=" cursor-pointer relative float-right -top-8 mr-3 text-2xl text-theme-color"
                            onClick={handleAdd}
                            disabled={texts.length === 4}
                          />
                        </div>
                      </label>
                    )}
                  </div>
                </div>
              </form>
            </div>

            <div className="w-1/2  space-y-1">
              <h2 className="pb-2  font-medium text-md">Others</h2>
              <hr />
              <div className="pt-4">
                <label
                  htmlFor="session"
                  className="text-fontSize font-medium text-[#202223]"
                >
                  Session *
                  <select
                    type="text "
                    className="w-full border  py-2 px-3 placeholder-[#4B4B4B] rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    placeholder="Bittu yadav"
                    onChange={(e)=>{
                      setState({...state,agenda_id:e.target.value})
                      
                    }}
                  >
                    <option value="" selected disabled hidden>
                      Choose Session
                    </option>
                    {
                      (agendaList)?.map((item)=>{
                        return (
                          <>
                          <option value={item?._id} key={item?._id}>{item?.agenda_name}</option>
                          </>
                        )
                        
                      })
                    }
                    
                  </select>
                </label>
              </div>
              
              <div className="flex space-x-5 py-2">
                <div className="w-1/2">
                  <label className="pt-2 text-fontSize font-medium text-[#202223] px-1  pb-1">
                    Start at{" "}
                  </label>
                  <input
                    type="date"
                    id="start-date"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e)=>{
                      const date=new Date(e.target.value)
                      setState({...state,startTime:date})
                    }}
                    min={startDate}
                    max={endDate}
                  />
                </div>
                <div className="w-1/2">
                  <label className="pt-2 text-fontSize font-medium text-[#202223] px-1  pb-1">
                    End at{" "}
                  </label>
                  <input
                    type="date"
                    id="end-date"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e)=>{
                      const date=new Date(e.target.value)
                      setState({...state,endTime:date})
                    }}
                    min={startDate}
                    max={endDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-2 space-x-2 flex gap-3">
            <Link to={"/polls"}>
              <button className=" px-8 py-2  text-sm font-medium hover:bg-gray-300  rounded border-2 ">
                Back
              </button>
            </Link>
            <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
          </div>
            </div>
          
        </div>
      </div>
    </>
  
  );
};

export default Addpolls;
