import React, { useContext, useState, useEffect, useRef } from "react";
import { DataContext } from "../../context/DataState";

const TagsModal = (props) => {
  
  const { getEventByIdApi, specificEvent,updateEventApi } = useContext(DataContext);
  const [editList, setEditList] = useState(specificEvent);
  const [value,setValue]=useState(null)
 const handleChange=(val)=>{
//  setValue(val)
console.log(props.obj,"value")
if(props.obj.h2=="Location")
{
  console.log("hello1")
  editList.room[specificEvent.room.length]=val
}
else{
  editList.interest[specificEvent.interest.length]=val
}
 }

 const handleSave= ()=>{
  // if(props.obj.h2="Location")
  // {
   
  //    setEditList({...editList,room:[...editList.room,value]})
  // }
  // else
  // {
  //   console.log("hello2")
  //   setEditList({...editList,interest:[...editList.interest,value]}) 
  // }
  console.log(editList,"editlist")
  updateEventApi(editList,props.setFunction)
 }
  useEffect(() => {
    getEventByIdApi();
  }, []);
  
 
  return (
    <>
      <div className="absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 w-full h-screen bg-[#00000062] z-[1]">
        <div className="fixed top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4">
          <div className="bg-white px-8 py-5 w-[60vh] h-[45vh] rounded z-10">
            <div className="pt-3 ">
              <h1 className="text-xl text-[#202223] font-semibold">
                {props.obj.h1}
              </h1>
            </div>
            <div className="mb-1 mt-3">
              <hr className="bg-[#0000001A] h-[2px]" />
            </div>

            <div className="mt-5">
              <label htmlFor="name" className="text-sm px-1 font-semibold ">
                {props.obj.h2}
              </label>
              <input
                type="interest-tag"
                id="interest-tag"
                name="interest-tag"
                value={value}
                placeholder={props.obj.placeholder}
                className="w-full capitalize border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
                onChange={(e) => {
                  handleChange(e.target.value)
                }}
              />
              <p className="text-[#6D7175] text-sm font-normal">
                {props.obj.p}
              </p>
            </div>

            <div className="flex items-center justify-center mt-3 gap-2 ">
              <button
                onClick={() => {
                  props.setFunction(false);
                }}
                className="border   border-[#BABFC3] w-[200px] disabled:opacity-40 px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
              >
                Back
              </button>
              <button
                type="button"
                className="bg-theme-color disabled:opacity-40 text-white px-4 py-2 text-sm font-medium w-[200px] rounded"
                onClick={()=>{handleSave()}}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TagsModal;
