import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaArrowLeft, FaArrowCircleUp } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";

const UpdateExhibitor = () => {
  const { exhibitorList,setEditExhibitor, updateExhibitorApi, disableSave,exhibitorPaginationApi , findOneExhibitorApi,editExhibitor,isSpinner} = useContext(DataContext);
  const { exhibitorId } = useParams();

  const [logoImage, setLogoImage] = useState(null);
  const [bImage, setBImage] = useState(null);
  const [logoimageError, setLogoImageError] = useState(false);
  const [bannerImageError, setBannerImageError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [count, setCount] = useState(0);
  const [urlError, setUrlError] = useState(false)
  const [galleryImage, setGalleryImage] = useState([])
  const [galleryImageFront, setGalleryImageFront] = useState([])
  const logoImageInput = useRef()
  const bannerImageInput = useRef()
  // const location = useLocation()
  // const exhibitorEdit = location.state;
  // const [editExhibitor, setEditExhibitor] = useState({});
  const [galleryImageExists,setGalleryImageExists]=useState([])
 
 



  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if (phoneData.match(phoneno)) {
      return setPhoneFormatTest(false)
    }
    else if (phoneData == '') {
      return setPhoneFormatTest(false)
    }
    else {
      return setPhoneFormatTest(true)

    }
  }

  let galleryArray = []
  const onGalleryImageChange = (e) => {


    let selectedFiles = e.target.files

    galleryArray = [...galleryArray, ...Array.from(selectedFiles)]


    const newArr2 = galleryArray.map((item) => {
      return URL.createObjectURL(item)
    })
    setGalleryImageFront([...galleryImageFront, ...newArr2])

    setEditExhibitor({ ...editExhibitor, galleryImage: [...editExhibitor.galleryImage, ...galleryArray] })

  }

//  let galleryImageExists = editExhibitor?.galleryImage?.filter((item) => {

//     return typeof (item) === 'string'
//   })
//   console.log(galleryImageExists,"gallery image exists")

  const validEmail = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  const validateEmail = () => {
    if (!validEmail.test(editExhibitor?.email) && editExhibitor?.email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const urlPatternValidation = () => {
    const regex = new RegExp(/^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/);
    if (!regex.test(editExhibitor?.websiteUrl) && editExhibitor?.websiteUrl !== "") {
      setUrlError(true);
    } else {
      setUrlError(false);
    }

  }


  const onImageChange = (event) => {
    if (
      event.target.files[0] &&
      event.target.id === "choose_logo"

    ) {

      setLogoImage(URL.createObjectURL(event.target.files[0]));
    } else if (
      event.target.files[0] &&
      event.target.id === "choose_banner"
    ) {

      setBImage(URL.createObjectURL(event.target.files[0]));
    }

    

    const allowedExtensions = ["jpg", "jpeg", "png", "webp"];

    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName?.substring(fileName.lastIndexOf(".") + 1);
      return allowedExtensions.some((extension) => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(
      event.target.files[0]?.name,
      allowedExtensions
    );

    if (!isAllowed && event.target.id === "choose_logo") {
      setLogoImageError("Please select valid image format.");
      return false;
    }

    if (!isAllowed && event.target.id === "choose_banner") {
      setBannerImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      isAllowed &&
      event.target.id === "choose_logo" &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setLogoImageError("Please select image less than 1 mb");
    } else {
      setLogoImageError(false);
    }
    if (
      event.target.files[0].size &&
      isAllowed &&
      event.target.id === "choose_banner" &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setBannerImageError("Please select image less than 1 mb");
    } else {
      setBannerImageError(false);
    }


  };

  const handleChange = (e) => {
    setEditExhibitor({ ...editExhibitor, description: e.target.value })
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  }

  const handleSubmit = (id) => {
    if(!isSpinner)
    updateExhibitorApi(editExhibitor, id,galleryImageExists);
  };
  const handleBannerDelete = () => {
    setEditExhibitor({ ...editExhibitor, bannerimage: "" });
    setBImage(null)
    bannerImageInput.current.value = "";

  }
  const handleLogoDelete = () => {
    setEditExhibitor({ ...editExhibitor, logo: "" });
    setLogoImage(null)
    logoImageInput.current.value = "";

  }

  const buttonDisabled =
    editExhibitor.name !== "" &&
    editExhibitor.email !== "" &&
    editExhibitor.description !== "" &&
    editExhibitor.websiteUrl &&
    editExhibitor.contactNumber !== "" &&
    editExhibitor.logo !== "" &&
    editExhibitor.bannerImage !== "" &&
    editExhibitor.galleryImage.length !== 0 &&
    !logoimageError &&
    !bannerImageError &&
    !emailError &&
    !phoneFormatTest && !urlError;


  useEffect(() => {

   
    findOneExhibitorApi(exhibitorId)
    // setGalleryImageFront([...editExhibitor?.galleryImage])
   
   
  }, []);

  const onDeleteGalleryImage = (item, index) => {
   const deletedImage=editExhibitor?.galleryImage[index]
    const newGalleryImage = [...editExhibitor.galleryImage];
   
    newGalleryImage.splice(index, 1); // Remove the item at the specified index
    setEditExhibitor({
      ...editExhibitor,
      galleryImage: newGalleryImage
    });
    
  setGalleryImageExists([...galleryImageExists,deletedImage])
  }

 

  const newStringArray = editExhibitor?.galleryImage?.filter((item) => {
    return typeof (item) === "object"
  })
console.log(editExhibitor,"editExhibitor")
  return (
    <>
      <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={"/exhibitors"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">{editExhibitor?.name} </h1>
          </div>
        </div>
        <div className="flex flex-col bg-white border-[#D1D9E7] p-3 rounded-lg"
         >
<div className="flex space-x-10 m-0 ">
          <div className="w-1/2 space-y-3 ">
            <h2 className="font-semibold text-lg ">Base data</h2>
            <hr />
            <form className="">
              <div>
                <label
                  htmlFor="name"
                  className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  {" "}
                  Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm  h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                  value={editExhibitor?.name} onChange={(e) => { setEditExhibitor({ ...editExhibitor, name: e.target.value }) }}
                />
              </div>


              <div className="mb-5">
                <label
                  htmlFor="biography"
                  className="font-semibold text-sm pb-1 text-[#202223]"
                >
                  Description *
                </label>
                <textarea
                  id="description"
                  name="description"
                  placeholder="Enter description about exhibitor"
                  rows={"4"}
                  className="w-full  pt-1 px-3 border border-gray-300 placeholder:text-slate-400 text-sm focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                  value={editExhibitor?.description} onChange={(e) => {


                    handleChange(e);
                  }}
                  maxLength={2000}
                />
                <div className='flex justify-between mt-1'>
                  <span className='text-sm text-[#6D7175]'>Provide description about heading</span>
                  <span className=" text-xs left-[60.7rem]">{count}/2000</span>
                </div>
              </div>

              <div>
                <label
                  htmlFor="designation"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Website Url *
                </label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="Enter your website url"
                  className="w-full  py-1 px-3 border border-gray-300 placeholder:text-slate-400 text-sm h-9 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 "
                  value={editExhibitor?.websiteUrl} onChange={(e) => { setEditExhibitor({ ...editExhibitor, websiteUrl: e.target.value }) }}
                  onKeyUp={() => { urlPatternValidation() }}
                />
                {urlError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid url
                  </span>
                )}
              </div>


              {/* <label htmlFor="last-name" className='pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]'>Last name*</label>
          <input type='text' id="last-name" name="lastname" placeholder="Enter your last name" className='w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 '
            value={state.lastname}
            onChange={(e) => { setState({ ...state, lastname: e.target.value }) }} /> */}
              <div className="mt-3">
                <label
                  htmlFor="email-address"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Email Address *
                </label>
                <input
                  type="text"
                  id="email-address"
                  name="email"
                  placeholder="Enter your email address"
                  className="w-full  py-1 px-3 border border-gray-300 placeholder:text-slate-400 text-sm h-9 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1  "
                  value={editExhibitor?.email} onChange={(e) => {

                    setEditExhibitor({ ...editExhibitor, email: e.target.value })
                  }} onKeyUp={() => validateEmail()}
                />
                {emailError && (
                  <span className="text-left text-red-600 text-sm ">
                    Please enter a valid Email
                  </span>
                )}
              </div>

              <div className="mt-3">
                <label
                  htmlFor="contact"
                  className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                >
                  Contact Number *
                </label>
                <input
                  id="contact"
                  type="tel "
                  name="contact"
                  placeholder="Enter your contact number"
                  className="w-full  py-1 px-3 border border-gray-300 placeholder:text-slate-400 text-sm h-9 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1  " maxLength={10} pattern="[0-9]{10}"
                  value={editExhibitor?.contactNumber} onChange={(e) => { setEditExhibitor({ ...editExhibitor, contactNumber: e.target.value }) }}
                  onKeyUp={() => validatePhoneno(editExhibitor.contactNumber)}
                />
                {phoneFormatTest ? <span className="text-red-600 text-sm">Please enter a valid 10 digit Number.</span> : <></>}
              </div>

              <div className="pt-2">
                <p className="pt-2 text-sm font-semibold pb-1 text-[#202223]">
                  Logo *
                </p>
                <div className="w-full h-32 text-center px-2 py-2 border-2 border-dashed border-[#BABFC3] rounded-md hover:border-theme-color relative">
                  <label
                    htmlFor="choose_logo"
                    accept="image/png, image/gif, image/jpeg, image/svg"
                    className="text-theme-color cursor-pointer  text-sm h-full flex flex-col justify-center items-center "
                  >
                    <span className=" bg-theme-light-color px-2 py-1">
                      {" "}
                      {editExhibitor?.logo?.name === undefined
                        ? "Choose logo"
                        : editExhibitor?.logo?.name}
                    </span>
                    <p className="text-sm pt-1 text-gray-400">
                      Accepts jpeg, jpg, png and webp
                    </p>
                    <p className="text-[11px] font-normal text-gray-400">
                      Maximum Upload size: 1Mb
                    </p>
                    <input
                      id="choose_logo"
                      type="file"
                      ref={logoImageInput}
                      // accept="image/jpeg, image/png, image/jpg"
                      className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                      onChange={(e) => {
                        setEditExhibitor({
                          ...editExhibitor,
                          logo: e.target.files[0],
                        });
                        onImageChange(e);
                      }}
                    />
                  </label>
                </div>
                {/* <img src={state?.image} className="w-[100px]"></img> */}
              </div>
              <span className="text-xs text-red-600 font-normal">
                {logoimageError ? logoimageError : ""}
              </span>
              <div
                className={(logoImage === null && !logoimageError) && editExhibitor?.logo !== "" ? "relative w-28 h-28 mt-4" : "hidden"}
              >
                <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleLogoDelete()} />
                <img
                  src={logoImage === null ? editExhibitor.logo : logoImage}
                  alt="banner"
                  className="absolute w-full h-full object-cover"
                />
              </div>

              <div
                className={logoImage != null && !logoimageError ? "relative w-28 h-28 mt-4" : "hidden"}
              >
                <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleLogoDelete()} />
                <img
                  src={logoImage}
                  alt="banner"
                  className="absolute w-full h-full object-cover"
                />
              </div>

              <div>
                <div className="pt-5 flex gap-3">
                  <Link to={"/exhibitors"}>
                    <button className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded">
                      Back
                    </button>
                  </Link>
                  
                  
                  <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
  handleSubmit(editExhibitor._id);
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
                </div>
              </div>
            </form>
          </div>
          <div className="w-1/2 pt-14 ">


            <div className="pt-3">
              <h1 className="text-sm  font-semibold pb-1">Banner Image *</h1>
              <div className=" h-32 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color">
                <label
                  htmlFor="choose_banner"
                  // accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer  text-sm flex flex-col items-center justify-center h-full"
                >
                  <span className=" bg-theme-light-color px-2 py-1">
                    {editExhibitor?.bannerImage?.name === undefined
                      ? "Choose banner image"
                      : editExhibitor?.bannerImage?.name}
                  </span>
                  <p className="text-sm pt-1 text-gray-400">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                  </p>
                  <input
                    id="choose_banner"
                    type="file"
                    ref={bannerImageInput}
                    // accept="image/jpeg, image/png, image/jpg"
                    className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      setEditExhibitor({
                        ...editExhibitor,
                        bannerImage: e.target.files[0],
                      });
                      onImageChange(e);
                    }}
                  />
                </label>
              </div>
            </div>
            {/* <div className={(image===null || imageError ) ?  "hidden"  : ""}>
                <img src={image} alt="banner" className="w-32 h-28 object-contain pt-2"/>
              </div> */}
            <span className="text-xs text-red-600 font-normal">
              {bannerImageError ? bannerImageError : ""}
            </span>
            <div
              className={(bImage === null && !bannerImageError) && editExhibitor?.bannerimage !== "" ? "relative w-28 h-28 mt-4" : "hidden"}
            >
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleBannerDelete()} />
              <img
                src={bImage === null ? editExhibitor.bannerImage : bImage}
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>

            <div
              className={bImage != null && !bannerImageError ? "relative w-28 h-28 mt-4" : "hidden"}
            >
              <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2" onClick={() => handleBannerDelete()} />
              <img
                src={bImage}
                alt="banner"
                className="absolute w-full h-full object-cover"
              />
            </div>

            <div className="pt-3">
              <h1 className="text-sm  font-semibold pb-1">Gallery Image *</h1>
              <div className=" h-32 border-2 border-dashed rounded-md text-center pt-5 hover:border-theme-color">
                <label
                  htmlFor="choose_gallery"
                  // accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer  text-sm flex flex-col justify-center items-center h-full"
                >
                  <span className=" bg-theme-light-color px-2 py-1">
                    Choose  gallery image
                  </span>
                  <p className="text-sm pt-1 text-gray-400">
                    Accepts jpeg, jpg, png and webp
                  </p>
                  <p className="text-[11px] font-normal text-gray-400">
                    Maximum Upload size: 1Mb
                  </p>
                  <input
                    id="choose_gallery"
                    type="file"
                    // accept="image/jpeg, image/png, image/jpg"
                    className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                    multiple
                    onChange={(e) => { onGalleryImageChange(e) }}
                  />
                </label>
              </div>
            </div>
            {/* <div
              className={`${
                editExhibitor?.galleryImage?.length === 0  ? "hidden" : ""
              } flex gap-2 flex-wrap pt-1`}
            >
              {editExhibitor?.galleryImage?.map((item) => {
                return (
                  <>
                    <img
                      src={item}
                      alt="banner"
                      className="w-32 h-28 object-contain pt-2"
                    />
                  </>
                );
              })}
            </div> */}
            <div className="flex gap-2 flex-wrap pt-1">
              {editExhibitor?.galleryImage?.map((item, index) => {
                return (
                  <div className="relative w-28 h-28 mt-4" key={index}>
                    <MdClose
                      className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2"
                      onClick={() => onDeleteGalleryImage(item, index)}
                    />
                    {typeof item === 'string' ? (
                      <>
                      <img
                        src={item}
                        alt={`Gallery Image ${index}`}
                        className="w-full h-full object-cover"
                      />
                      {console.log("gallrey image")}
                      </>
                    ) : (
                      <img className="w-full h-full object-cover" src={URL.createObjectURL(item)} alt={`Image ${index}`} />
                    )}
                  </div>
                );
              })}
            </div>

            {/* <div className={(gImage===null || GimageError ) ?  "hidden"  : ""}>
                <img src={gImage} alt="banner" className="w-32 h-28 object-contain pt-2"/>
              </div> */}
          </div>
        </div>
        </div>
        
      </div>
    </>
  );
};

export default UpdateExhibitor;
