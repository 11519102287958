import { toast } from "react-toastify";

// const BASE_URL = `${process.env.REACT_APP_API_PATH}/api/`;
const BASE_URL=process.env.REACT_APP_NODE_BASE_URL
const unauthorisedError = "Session timed out";
const token = JSON.parse(localStorage.getItem("token")) || "";

const fetchUtil = {
  GET: (endPoint, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      fetch(url, {
        method: "GET",
        mode: "cors",
        headers: headers,
        redirect: "follow",
      })
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  POST: (endPoint, payload, payloadType, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const modifiedPayload = payloadType === "formData" ? payload : JSON.stringify(payload);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        token: token,
      };
      const formHeaders = {
        token: token,
      };
      const modifiedHeaders = payloadType === "formData" ? formHeaders : headers;
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: modifiedHeaders,
        body: modifiedPayload,
        redirect: "follow",
      };
      console.log("body-->", requestOptions);
      fetch(url, requestOptions)
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  PUT: (endPoint, payload, payloadType, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const modifiedPayload = payloadType === "formData" ? payload : JSON.stringify(payload);
      const headers = {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        token: token,
      };
      const formHeaders = {
        token: token,
      };
      const modifiedHeaders = payloadType === "formData" ? formHeaders : headers;
      const requestOptions = {
        method: "PUT",
        mode: "cors",
        headers: modifiedHeaders,
        body: modifiedPayload,
        redirect: "follow",
      };
      console.log("body-->", requestOptions);
      fetch(url, requestOptions)
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
  DELETE: (endPoint, query) => {
    return new Promise((res, rej) => {
      // const url = BASE_URL + endPoint + JSON.stringify(query) || "";
      const url = BASE_URL + endPoint;
      const headers = {
        "Content-Type": "application/json",
        token: token,
      };
      fetch(url, {
        method: "DELETE",
        mode: "cors",
        headers: headers,
        redirect: "follow",
      })
        .then((response) => {
          if (response.statusCode === 401) {
            toast.error(unauthorisedError);
          } else return response.json();
        })
        .then((data) => {
          console.log(data);
          if (data.statusCode === 200) {
            res(data);
          } else {
            rej(data.message);
          }
        })
        .catch((err) => {
          rej(err.message);
        });
    });
  },
};

export default fetchUtil;
