import React from 'react'
import { Link } from 'react-router-dom'
import { FaArrowLeft } from "react-icons/fa";

const ImportAgenda = () => {
  return (
    <>
      <div className="flex w-full">

        <div className="w-full px-7 py-2 ">
          <div className="flex">
          <Link to={"/agenda-list"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                
                <FaArrowLeft />
              
              </button>
            </div>
            </Link>
            <div className="p-2 ">
              <h1 className="px-2 text-xl">Session Information</h1>
            </div>
          </div>

          <h1 className="text-xl text-theme-color  py-3">
            Agenda Import
          </h1>
          <div className="bg-white p-5 rounded-md">
          <div className="w-full h-72 border-2 border-dashed border-gray-500 rounded-md text-center">
            <label htmlFor="choose_file" accept="image/png, image/gif, image/jpeg, image/svg" className=" flex justify-center items-center w-full h-full ">
              <div className="flex flex-col gap-2">
                <span className='text-theme-color cursor-pointer bg-theme-light-color p-2 py-1 text-sm rounded'> Choose Excel File </span>
                <span className='text-base'> Accepts .xls , .xlxs </span></div>
              <input id="choose_file" type="file" className="text-sm hidden text-gray-400 relative top-44  " />
            </label>
            </div>
          </div>
          <div className="pt-2">
            <Link to={"/agenda-list"}>
              <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
                Back
              </button>
            </Link>
            <button className="bg-theme-color px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3">
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default ImportAgenda
