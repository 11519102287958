import React, { useContext, useState } from "react"
import { DataContext } from "../../context/DataState"
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import ReactPaginate from 'react-paginate';
import { motion } from "framer-motion"




const Pagination=({setFirstResultCount,limit,setSecondResultCount,api,id,state})=>{
  
  const {currentPage,setCurrentPage,pageCount,publishPaginationApi,totalCount}=useContext(DataContext)
  const handlePageClick=(e)=>{
    console.log(limit,"limit")
    if(id)
    api(id,e.selected+1,limit)
    else if(state)
    {
      api(state,e.selected+1,limit) 
    }
    else
    api(e.selected+1,limit)
  
    setCurrentPage(e.selected+1)
    setFirstResultCount(e.selected*limit+1)
    console.log(e.selected+1,"page")
    if(e.selected+1===pageCount ||pageCount===1)
    {
      setSecondResultCount(totalCount)
    }
    else{
      setSecondResultCount((e.selected+1)*limit)
    }
    console.log(currentPage,"current page")
  }
  const paginationVariants = {
    hidden: {
      opacity: 0,
      y: 200,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 5,
      },
    },
  };

  return (
    <motion.div
    variants={paginationVariants}
    initial="hidden"
    animate="visible"
  >
    <ReactPaginate
        breakLabel={<span className="mr-4">...</span>}
        nextLabel={
        
          <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md">
            <BsChevronRight />
          </span>
       
      }
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel={
          
            <span className="w-10 h-10 flex items-center justify-center bg-lightGray rounded-md mr-4">
              <BsChevronLeft />
            </span>
         
        }
        forcePage={currentPage - 1}
        containerClassName="flex items-center justify-center  mb-4"
        pageClassName="block border- border-solid border-lightGray   w-6 h-6 text-sm flex items-center justify-center rounded-full mr-4" 
        activeClassName="bg-theme-color text-white"
      />
    
    </motion.div>
  )
}
export default Pagination;