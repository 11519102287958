import React, { useRef, useEffect, useState } from 'react'
import { Bar, Line, Pie, PolarArea } from 'react-chartjs-2';
import {Chart as ChartJS} from 'chart.js/auto'

const BarChart = ({ chartData }) => {

    console.log(chartData, "chartData")
    const options = {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date', // Custom label for the x-axis
             
            },
             
            
          },
          y: {
            title: {
              display: true,
              text: 'Number of users created', // Custom label for the y-axis
            },
          },
        },
      };

    return (
    <>
    <div className="chart-container">
    <Bar data={chartData} options={options}/>
    </div>
    
    </>
    )
    
}
export default BarChart;