import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from "react-icons/fa";

const ImportSponsor = () => {
    return (
        <>
            <div className="flex w-full">

                <div className="w-full px-7 py-2 ">
                    <div className='flex items-center '>
                    <Link to={"/sponsors"}> 
                    <div className='shadow-sm border-2 border-gray-300 p-2  '>
                           
                            < FaArrowLeft className=' text-[20px] text-[#5C5F62]'/>
                           
                        </div> 
                        </Link>
                        <div>
                            <h1 className="text-xl px-5 py-4">
                                Sponsor Import
                            </h1>
                        </div>
                    </div>
                    <div className="bg-white p-5 rounded-md">
                        <div className="w-full flex justify-center items-center h-72 border-2 border-dashed border-gray-500 hover:border-theme-color rounded-md text-center">
                            
                                <label htmlFor="choose_file" accept="image/png, image/gif, image/jpeg, image/svg" className=" flex justify-center items-center w-full h-full ">
                                    <div className="flex flex-col gap-2">
                                   <span className='text-theme-color cursor-pointer bg-theme-light-color p-2 py-1 text-sm rounded'> Choose Excel File </span>
                                   <span className='text-base'> Accepts .XLM , .xlxs , csv </span></div>
                                <input id="choose_file" type="file" className="text-sm hidden text-gray-400 relative top-44  "/>

                                
                                </label>
                        </div>
                    </div>
                    <div className="flex py-2">
                        <Link to={"/sponsors"}>
                            <button className="border-2 px-8 text-sm py-1 hover:bg-gray-300 rounded">
                                Back
                            </button>
                        </Link>
                        <button className="bg-theme-color px-8 text-sm py-1 rounded hover:bg-orange-400 text-white ml-3">
                            Save
                        </button>
                    </div>
                </div>
            </div >

        </>
    )
}

export default ImportSponsor
