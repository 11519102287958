import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { FiPlus, FiTrash2 } from "react-icons/fi";

import { useContext } from "react";
import { DataContext } from "../../context/DataState";
import { useParams ,useLocation} from "react-router-dom";
import { ToastContainer } from "react-toastify";

const UpdatePolls = () => {
  const { getPollApi, pollList, getAgendaById, agendaList, updatePollApi, disableSave, specificEvent ,isSpinner} = useContext(DataContext);
  const { pollId } = useParams();
  
  const [newArr, setNewArr] = useState([]);
  const [date, setDate] = useState("");
  const [text, setText] = useState("");
  const [texts, setTexts] = useState([]);
  const [showAddButton, setShowAddButton] = useState(true);
  const location = useLocation()
  const pollEdit = location.state;
  const [editPoll, setEditPoll] = useState(pollEdit);

  let startDate = new Date(specificEvent?.eventStartDate).toLocaleDateString('en-CA').split('T')[0];
  let endDate = new Date(specificEvent?.eventEndDate).toLocaleDateString('en-CA').split('T')[0];

  let todayDate = new Date().toISOString().split('T')[0]
  if (startDate.slice(8,10)<todayDate.slice(8,10) && (startDate.slice(0,4)===todayDate.slice(0,4)&& startDate.slice(5,7)===todayDate.slice(5,7)) )
  {
     startDate=todayDate;
  }



  const handleChange = (e, pollId) => {
    console.log("hello")
    console.log(pollId, "pollIdtext")

    for (let key of texts) {
      console.log(key.option, "key.option")
      if (pollId !== undefined) {
        if (key._id === pollId) {
          key.option = e.target.value;
        }
      } else {
        setText(e.target.value)
      }
    }
  };


  const handleAdd = () => {
    if (texts.length <= 4 && text.trim() !== "") {
      setTexts([...texts, { option: text }]);
      setText("");
      if (texts.length === 3) {
        setShowAddButton(false);
      }
    }
    console.log(texts, "texts in handle add")
  };

  const handleDelete = (index) => {

    // const newArr = [...editPoll?.options];
    // newArr.splice(index, 1);
    // setEditPoll([...texts]);
    const updatedTexts = [...texts];
    updatedTexts.splice(index, 1);
    setTexts(updatedTexts);
    if (texts.length === 4) {
      setShowAddButton(true);
    }

  };

  //startDate format
  var newStartDate = new Date(editPoll?.startTime).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });
  newStartDate = newStartDate.split(",");
  newStartDate = new Date(newStartDate).toLocaleDateString("fr-CA");


  //endDate format
  var newEndDate = new Date(editPoll?.endTime).toLocaleString("en-US", {
    timeZone: "Asia/Calcutta",
  });
  newEndDate = newEndDate.split(",");
  newEndDate = new Date(newEndDate).toLocaleDateString("fr-CA");

  const optionArrayFunc = (id) => {

  }
  const buttonDisabled=editPoll.pollText !== "" && editPoll.agenda_id !== "" && texts.length >=1 

  useEffect(() => {
    
    setTexts([...texts, ...pollEdit?.options])

    getAgendaById();
  }, []);

  // let pollOptionsList = [...texts];


  const handleUpdateApi = () => {
    if(!isSpinner)
    updatePollApi(editPoll, pollId, texts);
  }
  
  return (
    <>
      <div className="flex w-full ">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <Link to={"/polls"}>
            <div className="flex">
              <div className="w-8 pt-1">
                <button className="border p-2">
                  {" "}
                  <FaArrowLeft />
                </button>
              </div>
              <div className="p-2 ">
                <h1 className="px-2 text-xl">Polls</h1>
              </div>
            </div>
          </Link>
          <div className="flex flex-col bg-white border-[#D1D9E7] p-3 rounded-lg">
          <div className="flex space-x-10">
            <div className="w-1/2 space-y-3 ">
              <h2 className="font-medium text-md">Question details</h2>
              <hr />
              <form className="">
                <div className="py-2">
                  <label
                    htmlFor="question"
                    className="text-fontSize font-medium text-[#202223] px-1  pb-1"
                  >
                    Question *
                    <input
                      type="text "
                      id="question"
                      className="w-full border  py-2 px-3 placeholder-slate-400  rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      placeholder="Enter question for poll"
                      value={editPoll?.pollText}
                      onChange={(e) => { setEditPoll({ ...editPoll, pollText: e.target.value }) }}
                    />
                  </label>
                </div>
                <div className="py-1">
                  <label
                    htmlFor="options"
                    className="pt-2  px-1  pb-1 text-fontSize font-medium text-[#202223]"
                  >
                    Options *
                  </label>

                  <div className="">
                    <div className="space-2">
                      {texts?.map((item, index) => (
                        <label key={item?._id}>
                          <input
                            id="options"
                            className="w-full border text-fontSize  py-2 pl-2 pr-10 placeholder-slate-400  rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                            type="text"
                            defaultValue={item?.option}
                            // onChange={(e) => {setTexts({...text, option: e.target.value })}}
                            onChange={(e) => {
                              handleChange(e, item?._id)
                            }}
                          />
                          <FiTrash2
                            className="cursor-pointer text-[#DB4446] float-right relative -top-7 mr-3 text-lg"
                            onClick={() => {
                              handleDelete(index)
                            }}
                          />
                        </label>
                      ))}
                    </div>
                  </div>
                  {showAddButton && (
                    <label className="text-fontSize">
                      <div className="">
                        <input
                          type="text "
                          id="Answers"
                          className={`${texts.length >= 4 ? "hidden" : ""} w-full border border-dashed border-gray-300 py-2 pl-2 pr-10 placeholder-slate-400  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1`}
                          placeholder="Add answer"
                          value={text}
                          onChange={(e) => { handleChange(e) }}

                        />
                        <FiPlus
                          className={`${texts.length >= 4 ? "hidden" : ""}  cursor-pointer relative float-right -top-8 mr-3 text-2xl text-theme-color`}
                          onClick={handleAdd}
                          disabled={texts.length >= 4}
                        />
                      </div>
                    </label>
                  )}
                </div>
              </form>
            </div>

            <div className="w-1/2 space-y-1">
              <h2 className="pb-2  font-medium text-md">Others</h2>
              <hr />
              <div className="pt-4">
                <label
                  htmlFor="session"
                  className="text-fontSize font-medium text-[#202223]"
                >
                  Session
                  <select
                    type="text "
                    className="w-full border  py-2 px-3 placeholder-[#4B4B4B] rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    onChange={(e) => { setEditPoll({ ...editPoll, agenda_id: e.target.value }) }}
                  >
                    <option value="" disabled>
                      Choose Session
                    </option>
                    {agendaList?.map((item) => {
                      return (
                        <>
                          <option value={item?._id} key={item?._id} selected={editPoll?.agenda_id === item?._id ? "selected" : ""} >
                            {item?.agenda_name}</option>
                        </>
                      );
                    })}
                  </select>
                </label>
              </div>

              <div className="flex space-x-5 py-2">
                <div className="w-1/2">
                  <label className="pt-2 text-fontSize font-medium text-[#202223] px-1  pb-1">
                    Start at{" "}
                  </label>

                  <input
                    type="date"
                    id="start-date"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    value={newStartDate}
                    onChange={(e) => { setEditPoll({ ...editPoll, startTime: e.target.value }) }}
                    min={startDate}
                    max={endDate}
                  />
                </div>
                <div className="w-1/2">
                  <label className="pt-2 text-fontSize font-medium text-[#202223] px-1  pb-1">
                    End at{" "}
                  </label>
                  <input
                    type="date"
                    id="end-date"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    value={newEndDate}
                    onChange={(e) => { setEditPoll({ ...editPoll, endTime: e.target.value }) }}
                    min={startDate}
                    max={endDate}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="pt-2 space-x-2">
            <Link to={"/polls"}>
              <button type="button" className=" px-4 py-2 w-36 text-sm font-medium  hover:bg-slate-100 border border-black rounded">
                Back
              </button>
            </Link>
            <button type="button" 
disabled={!buttonDisabled}
onClick={() => {
handleUpdateApi();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
   Update
</button>
          </div>
          </div>

          
        </div>
      </div>
    </>
  );
};
export default UpdatePolls;
