import React, { useContext, useEffect, useState } from "react";
import BarChart from "../Dashboard/Charts/BarChart";
import LineChart from "../Dashboard/Charts/LineChart";
import { Link } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import dummyImage from "../Assets/Image.png";
import { FiUsers } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from 'react-tooltip'
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const SponsorsOverview=({state})=>{
  const {getSponsorReportApi,sponsorReport,totalCount,pageCount,
    currentPage,setCurrentPage,globalLoader,checkResponse}=useContext(DataContext)
  const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [graphData, setGraphData] = useState([]);

  const userData = {
    data: [
      {
        id: 1,
        year: 2016,
        userGain: 80000,
        userLost: 823,
      },
      {
        id: 2,
        year: 2017,
        userGain: 45677,
        userLost: 345,
      },
      {
        id: 3,
        year: 2018,
        userGain: 78888,
        userLost: 555,
      },
      {
        id: 4,
        year: 2019,
        userGain: 90000,
        userLost: 4555,
      },
      {
        id: 5,
        year: 2020,
        userGain: 4300,
        userLost: 234,
      },
    ],
    backgroundColor: [
      "rgba(255, 99, 132, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(255, 205, 86, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(153, 102, 255, 0.2)",
      "rgba(201, 203, 207, 0.2)",
    ],
    borderColor: [
      "rgb(255, 99, 132)",
      "rgb(255, 159, 64)",
      "rgb(255, 205, 86)",
      "rgb(75, 192, 192)",
      "rgb(54, 162, 235)",
      "rgb(153, 102, 255)",
      "rgb(201, 203, 207)",
    ],
  };

  const [barChartData, setBarChartData] = useState({
    labels: graphData && graphData?.map((data) => data.date),
    datasets: [
      {
        label: "Testing",
        data: graphData && graphData.map((element) => element.no_ofData),
        backgroundColor: userData.backgroundColor,
        borderColor: userData.borderColor,
        borderWidth: 1,
      },
    ],
  });

  const [lineChartData, setLineChartData] = useState({
    labels: graphData && graphData?.map((data) => data.date),
    datasets: [
      {
        label: "Testing",
        data: graphData && graphData.map((element) => element.no_ofData),
        backgroundColor: userData.backgroundColor,
        borderColor: userData.borderColor,
        borderWidth: 1,
      },
    ],
  });

  const updateGraph = () => {
    setLineChartData({
      labels: graphData && graphData?.map((data) => data.date),
      datasets: [
        {
          label: "Speaker Data",
          data: graphData && graphData.map((element) => element.no_ofData),
          backgroundColor: userData.backgroundColor,
          borderColor: userData.borderColor,
          borderWidth: 1,
          tension: 0.1,
        },
      ],
    });

    setBarChartData({
      labels: graphData && graphData?.map((data) => data.date),
      datasets: [
        {
          label: "Speaker Data",
          data: graphData && graphData.map((element) => element.no_ofData),
          backgroundColor: userData.backgroundColor,
          borderColor: userData.borderColor,
          borderWidth: 1,
          tension: 0.1,
        },
      ],
    });
  };

  useEffect(() => {
    if (graphData.length > 0) {
      updateGraph();
    }
  }, [graphData.length > 0]);

  useEffect(() => {
    getSponsorReportApi(state, 1, 10);
  }, []);
  console.log(sponsorReport?.sponserLevelResponse?.sponsors, "hello");

  useEffect(() => {
    if (sponsorReport?.sponserLevelResponse?.sponsors?.length > 0) {
      const groupedData = sponsorReport?.sponserLevelResponse?.sponsors.reduce(
        (acc, item) => {
          const date = item.item.createdAt.split("T")[0];

          const existingEntry = acc.find((entry) => entry.date === date);

          if (existingEntry) {
            existingEntry.count += 1;
          } else {
            acc.push({ date, count: 1 });
          }

          return acc;
        },
        []
      );

      const result = groupedData.map(({ date, count }, id) => ({
        id: id + 1,
        date,
        no_ofData: count,
      }));

      setGraphData(result);
      console.log(result, "resultresult");
    }
  }, [sponsorReport]);

  return (
    <>
      <div className=" w-full flex h-screen ">
        <div className="w-[100%] ">
          <div className="bg-theme-white w-full">
            <div className="mx-3">
              <div
                className="flex flex-col gap-4 
              "
              >
                <div className="flex flex-col">
                  
                 {
                 globalLoader ? <GlobalLoader/>:<div>
                 <div className="flex justify-between items-center mt-3">
                   <p className="text-[#202223] text-base font-semibold">
                     Overall Analytics
                   </p>
                   {/* <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                     Export XLS
                   </button> */}
                 </div>
                 <div className="w-full flex justify-center items-center mt-5 ">
                   <div className="w-1/2 h-32 bg-white flex flex-col justify-center items-center rounded-tl border-x border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                      {sponsorReport?.totalSponser}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                       Total Sponsors
                     </span>
                   </div>
                   
                   <div className="w-1/2 h-32 bg-white flex flex-col justify-center rounded-tr items-center border-r border-b border-[#E3E6ED]">
                     <span className="text-[#202223] text-3xl font-bold">
                   {  sponsorReport?.totalProfileVisit?.length}
                     </span>
                     <span className="text-[#31374A] text-xs font-normal">
                      Total Profile visits
                     </span>
                   </div>
                   <div></div>
                 </div>
                 

                 <div className="flex justify-between  pt-8 ">
                   <div className="bg-white w-1/2 ">
                     <div className="flex gap-2 items-center py-2 px-3">
                       <FiUsers className="text-[#0097EB] text-[24px]"/>
                       <span className="text-[#6E7891] text-sm font-medium ">Total sponsor View</span>
                       <div className="bg-[#D9FBD0] border border-[#BEE8B4] px-2 py-1 rounded"><span className="text-[#1C6C09] text-xs font-bold">+ 24.5%</span></div>
                     </div>
                     <div className="px-3">
                     <BarChart chartData={barChartData} />
                     </div>
                  
                   </div>
                   <div className="bg-white w-1/2 ">
                   <div className="flex gap-2 items-center py-2 px-3">
                       <FiUsers className="text-[#0097EB] text-[24px]"/>
                       <span className="text-[#6E7891] text-sm font-medium ">Total Profile Clicks</span>
                       <div className="bg-[#D9FBD0] border border-[#BEE8B4] px-2 py-1 rounded"><span className="text-[#1C6C09] text-xs font-bold">+ 67.5%</span></div>
                     </div>
                     <div className="px-3">
                     <LineChart chartData={lineChartData} />
                     </div>
                   


                   </div>
              
               

             </div>
             <div className="bg-white rounded-t mt-3 px-3">
               <h1 className="text-[#141824] font-semibold text-base p-3">Sponsors</h1>
                     <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
                       <thead className="cursor-pointer">
                         <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                           <th className="w-[13%] px-4 py-2 rounded relative">
                            Logo
                           </th>

                           <th className="w-[20%] px-4 py-2 ">
                             Sponsor Name
                           </th>
                           <th className="w-[20%] px-4 py-2 ">
                            Level
                           </th>
                           <th className="w-[15%] px-4 py-2">Profile Visit</th>
                           <th className="w-[20%] px-4 py-2 relative">
                           Banner Click
                           </th>
                           <th className="w-[15%] px-4 py-2">Website Click</th>
                           <th className="w-[15%] px-4 py-2">Website</th>
                           <th className="w-[15%]  text-center rounded-tr-lg px-4 py-2">
                             Action
                           </th>
                         </tr>
                       </thead>
                       <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                         
                           {sponsorReport?.sponserLevelResponse?.sponsors?.length==0 && checkResponse ?
                          <tr className="bg-white ">  <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">No data found</h1></tr>:
                             sponsorReport?.sponserLevelResponse?.sponsors?.map((element)=>{
                               return (
                                 <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                                 <td className="p-2">
                                 <img
                             src={element?.item?.image}
                             className="w-12 h-12 rounded-md border-2 border-gray"
                             alt="Sponsor"
                           />
                                 </td>
 
                                 <td className=" py-2 px-4">{element?.item?.sponsor_name}</td>
 
                                 <td className=" py-2 px-4">
                                  {element?.item?.level_name
}
                                 </td>
                                 <td className=" py-2 px-4">
                                   {element?.profileVisit}
                                 </td>
                                 <td className=" py-2 px-4">{element?.bannerVisit}</td>
 
                                 <td className=" py-2 px-4">
                                 {element?.websiteVisit}
                                 </td>
                                 <td className=" py-2 px-4">
                                  {element?.item?.url}
                                 </td>
 
                                 <td className="py-2 px-4">
                                   <div className="flex items-center  justify-center  gap-2 text-black ">
                                     <Link to="/sponsors-view"  state={element}>
                                       <AiOutlineEye 
                                         data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                       className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                         <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                                     </Link>
                                   </div>
                                 </td>
                               </tr>
                               )
                             })
                           }
                             
                           
                       
                       </tbody>
                     </table>
                    
                   </div>
                 
                 </div>
                 
                 }
                  
                </div>
                {sponsorReport?.sponserLevelResponse?.sponsors?.length!==0 && !checkResponse &&
                   <div className="flex justify-between items-center ">
                   <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
                   <Pagination
                     firstResultCount={firstResultCount}
                     setFirstResultCount={setFirstResultCount}
                     secondResultCount={secondResultCount}
                     setSecondResultCount={setSecondResultCount}
                     limit={limit}
                     api={getSponsorReportApi}
                     state={state}
                   />
                 </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SponsorsOverview;
