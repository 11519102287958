import React from "react";
import NoDataImage from "../Assets/noDtata.png"
const NoDataModal=()=>{
  return (
    <>
    <div className="flex w-full bg-white ">
        <div className="w-full p-8  bg-white rounded-md h-[45vh] ">
         <div className="border border-dashed border-[#BABFC3] rounded flex flex-col justify-center items-center h-[35vh]">
          <img src={NoDataImage} alt="NoDataImage" />
          <span classname="text-[#6D7175] text-sm font-normal"> 
            No Data Available</span>

         </div>
        </div>
      </div>
    </>
  )
}
export default NoDataModal;