import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import noResult from "../Assets/No Result.png";
import DeleteModal from "../DeleteModal/DeleteModal";
import Pagination from "../Pagination/Pagination"
import PollModal from "./PollModal";
import NoDataModal from "../NoDatatModal/NoDataModal";

const Polls = () => {
  const {  pollList, globalLoader, deleteBtn, setDeleteBtn, setDeleteBtnId, deleteBtnId, deletePollApi ,enablePollApi,pollStatus, disablePollApi,pollPaginationApi,totalCount,pageCount,pollModalBtn,setPollModalBtn,currentPage,checkResponse} = useContext(DataContext);

  const [toggle, setToggle] = useState(null);
  const [optionId,setOptionId]=useState(null)
  const [pollId,setpollId]=useState(null)

  const limit=15;
  const [firstResultCount,setFirstResultCount]=useState(1)
  const [secondResultCount,setSecondResultCount]=useState(limit)
   
  let handleResponse=(id)=>{
    if(toggle===id){
        setToggle(!null);
        return false 
    }
   setToggle(id); 
  }
  
  useEffect(() => {
   
    pollPaginationApi(1,limit)
  }, []);

  const handleEnable = (pollId) => {
    enablePollApi(pollId,currentPage,limit,secondResultCount,setSecondResultCount);
  }

  const handleDisable = (pollId) => {
    disablePollApi(pollId,currentPage,limit,secondResultCount,setSecondResultCount);
  }

console.log(pollList,"poll")
  return (
    <>
   
      {deleteBtn ? <DeleteModal deleteApi={deletePollApi} deleteId={deleteBtnId} currentPage={currentPage} limit={limit}
        secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount}   /> : ""}
      {pollModalBtn ? <PollModal pollId={pollId} optionId={optionId} /> : ""}
      <div className="w-full px-8 pt-[26px] overflow-y-scroll h-screen space-y-2">
        <div className=" items-center">
          <div className="flex justify-between pb-5">
            <div>
              <h1 className="text-xl text-[#202223] font-semibold">Polls</h1>
            </div>
            <div className="">
              <Link to={"/add-polls"}>
                <button className="border  text-sm bg-theme-color text-white px-4 py-2 rounded font-medium">
                  Add New Question
                </button>
              </Link>
            </div>
          </div>
        </div>
        {/* {globalLoader ? (
          <GlobalLoader />
        ) : ( */}
      
         {
          pollList.length === 0 && checkResponse===true ? <NoDataModal/> : globalLoader ? <GlobalLoader/>:
          <div>
          {
           
            pollList.map((item) => {
              return (
                <>
                  <div className={`${item?.pollStatus =="Disable" ||item?.pollStatus =="false" ? "bg-[#FAFBFB] opacity-40" : "bg-white"} shadow-[0_0px_6px_0px_rgba(0,0,0,0.12)] rounded-md  flex justify-between py-3 px-3 mb-3`}
                    key={item?._id}>
                    <div className="w-4/5">
                      <p className="text-[16px] font-medium">
                        {item?.pollText}
                      </p>
                      <div className="px-5 py-3">
                        <ol className="list-decimal text-sm font-medium space-y-2">
                          {(item?.options).map((element,index) => {
                            return (
                              <>
                                <li key={index}>{element?.option} </li>
                              </>
                            );
                          })}
                        </ol>
                      </div>
                      
                      {
                        ((item?._id) === toggle) ? (<div className="">
                          <div className=" ">
                            <p className="text-[16px] font-medium">
                              {item?.pollText}
                            </p>
                          </div>
                          <ul className=" py-3 text-[12px] font-medium space-y-2 w-3/4">
                            {(item?.options).map((element,index) => {
                              return (
                              <li className={`flex justify-between items-center h-9 py-2 px-3 border rounded
                              ${element?.count > 0 ? "border-[#7BD6AA] bg-[#7bd6aa33]" : "bg-[#FF853133] border-theme-color"}`} key={index}>
                                <span className="text-xs font-normal">{element?.option}</span>
                                <span className="flex">
                                  {
                                    element?.user_id.length>0 && element?.user_id?.slice(0,5)?.map((element)=>{
                                      return <img src={element?.image} className="w-6 h-6 rounded-full" alt="image"/>

                                    })
                                  }
                                  
                                  <span className={`text-theme-color font-semibold text-xs underline cursor-pointer pl-2`} onClick={()=>{setPollModalBtn(true)
                                  setOptionId(element?._id)
                                  }}> {`show more`}</span>
                                </span>
                              </li>
                              );
                            })}
                          </ul>
                          <p className="pr-2 text-[12px] font-medium w-3/4 text-right text-[#5E5E5E]">{item?.totalCount} {item?.totalCount > 1 ? "Votes" : "Vote" }</p>
                        </div>) : ""
                      }

                    </div>

                    <div className="w-1/5 mr-3">
                      <ul className=" flex space-x-2 text-[14px] pr-2">
                        <Link className="flex gap-1">
                          <li
                            className={`hover:underline hover:text-theme-color transition-all ease-in-out 
                            ${toggle === item?._id ? "text-theme-color underline" : ""}`}
                            onClick={() => {
                              handleResponse(item?._id);
                              setpollId(item?._id)
                            }}
                          >
                            Response
                          </li>
                          <span>{`(${item.totalCount})`}</span>
                        </Link>
                        {item?.pollStatus == "Disable" ||item?.pollStatus == "false" ? 
                          <li className={`hover:underline hover:text-theme-color transition-all ease-in-out cursor-pointer`} 

                          onClick={()=>{handleEnable(item?._id)}} 
                          title="Click To Disable Poll">
                            Enable
                          </li>
                          :
                          <li className={`hover:underline hover:text-theme-color transition-all ease-in-out cursor-pointer`} 
                           
                            onClick={()=>{handleDisable(item?._id)}} 
                            
                            title="Click To Enable Poll">
                           Disable
                          </li>
                        }
                        <Link to={`/update-polls/${item?._id}`} state={item}>
                        <li className="hover:underline hover:text-theme-color transition-all ease-in-out cursor-pointer" disabled={true}>
                          Edit
                        </li>
                        </Link>
                        <li className="hover:underline hover:text-theme-color transition-all ease-in-out cursor-pointer "
                          onClick={()=>{
                            setDeleteBtn(true)
                            setDeleteBtnId(item?._id)
                          }}>
                          Delete
                        </li>
                      </ul>
                    </div>
                  </div>
                
                </>
              );
            })
          }
        </div>
         }
         
         
        
       {
      !checkResponse && <div className="flex justify-between items-start ">
       <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount}  of ${totalCount} results`}</span>
      <Pagination   firstResultCount={firstResultCount} setFirstResultCount={setFirstResultCount} secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} limit={limit} api={pollPaginationApi}/>
      </div>
       }
        
      </div>
    </>
  );
};

export default Polls;
