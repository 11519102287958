import React, { useContext, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

import ExcelLogo from "../Assets/excel logo.png";
import { DataContext } from "../../context/DataState";

const ImportContact = () => {
  const { createContactApi, isSpinner } = useContext(DataContext);
  const location = useLocation();

  const isContactActive = location.state.isContactActive;
  const id = location.state.id;
  const name = location.state.name;
  const [state, setState] = useState({
    file: "",
  });
  const [fileError, setFileError] = useState(false);
  const onFileChange = (event) => {
    setState({ ...state, file: event.target.files[0] });
    if (
      event.target.files[0].name &&
      !event.target.files[0].name.match(/\.(xls|xlsx)$/)
    ) {
      setFileError("only .xls and .xlsx file format are supported");
      return false;
    } else {
      setFileError(false);
    }
  };
  const onButtonClick = () => {
    fetch("AddContactSample.xlsx").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "AddContactSample.xlsx";
        alink.click();
      });
    });
  };
  const buttonDisabled = !fileError && state.file !== "";

  const handleSubmit = () => {
    if(!isSpinner) createContactApi(state, id);
  };
  return (
    <>
      <div className="flex w-full">
        <div className="w-full px-7 py-2 ">
          <div className="flex py-2 items-center justify-between">
            <div className="flex">
              <Link
                to={"/addcontactlist"}
                state={{ isContactActive: isContactActive, id: id, name: name }}
              >
                <div className="w-8 pt-1">
                  <button className="border p-2">
                    <FaArrowLeft />
                  </button>
                </div>
              </Link>
              <div className="p-2">
                <h1 className="text-xl px-2 ">Import Contacts</h1>
              </div>
            </div>
            <button
              type="button"
              className="flex items-center bg-[#34a85314] gap-2 h-9 rounded cursor-pointer px-2"
              onClick={() => {
                onButtonClick();
              }}
            >
              <img src={ExcelLogo} alt="excel-logo" />
              <span className="text-[#34A853] font-medium  text-sm ">
                Download Sample
              </span>
            </button>
          </div>
          <div className="bg-white p-5 rounded-md">
            <div className="w-full h-72 border border-dashed hover:border-theme-color border-[#BABFC3] rounded-md text-center">
              <div className="flex justify-center pt-32">
                <label
                  htmlFor="choose_banner"
                  accept="image/png, image/gif, image/jpeg, image/svg"
                  className="text-theme-color cursor-pointer px-2 py-1  text-sm "
                >
                  <span className="bg-theme-light-color px-2 py-1">
                    {state.file === "" ? "No File choosen" : state.file.name}
                  </span>
                  <p className="text-sm pt-1 text-gray-400">
                    Accepts .xls and .xlsx
                  </p>
                  <input
                    id="choose_banner"
                    type="file"
                    className="text-theme-color hidden  bg-orange-200 px-1 text-sm rounded-md "
                    onChange={(e) => {
                      onFileChange(e);
                    }}
                  />
                </label>
              </div>
              <span className="text-xs text-red-600 font-normal">
                {fileError ? fileError : ""}
              </span>
            </div>
          </div>

          <div className="pt-2">
            <Link
              to={"/addcontactlist"}
              state={{ isContactActive: isContactActive, id: id, name: name }}
            >
              <button className="border-2 hover:bg-gray-300  border-[#BABFC3] px-8 text-sm py-2 rounded">
                Back
              </button>
            </Link>
            <button
              className="bg-theme-color px-8 text-sm py-2 disabled:opacity-50 rounded hover:bg-orange-500 text-white ml-3"
              disabled={!buttonDisabled}
              onClick={() => {
                handleSubmit();
              }}
            >
              <svg
                width="40"
                height="20"
                fill="currentColor"
                className={`${isSpinner ? "" : "hidden"} mr-2 animate-spin`}
                viewBox="0 0 1792 1792"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
              </svg>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default ImportContact;
