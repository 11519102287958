import React ,{useState,useContext , useEffect} from 'react'
import { DataContext } from "../../context/DataState";
import { ToastContainer } from 'react-toastify';

const  EventTermsCondition=() => {
  const{eventTermsAndConditionApi,getEventByIdApi,disableSave,specificEvent} = useContext(DataContext)
  const [editterms,setEditterms] =useState({});
  const [count,setCount]=useState(0)

  const handleChange=(e)=>{
    setEditterms({...editterms,termsAndConditions:e.target.value})
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  }

  const handleUpdate=()=>{
    if(!disableSave)
    eventTermsAndConditionApi(editterms)
  }

  useEffect(() => {
    getEventByIdApi(setEditterms({ ...specificEvent }));
  setCount(specificEvent?.termsAndConditions?.split("").length);
  }, []);

  return (
    <>
    <ToastContainer/>
        <div className="bg-white w-full px-4 rounded-md">
        <div className="py-4 ">
          <hr/>
          </div>
          <div className="">
            <label
              className="block mb-2 text-sm"
              typeof="description"
              htmlFor="description"
            >
              Description *
              <textarea
                type="name"
                rows={"12"}
                className="mt-2 block w-full  px-3 py-1 bg-white border placeholder:text-slate-400   rounded-md text-sm  placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-200 "
                placeholder="Enter your description"
                value={editterms?.termsAndConditions} 
                onChange={(e)=>{handleChange(e)}}  
                maxLength={6000}
              />
            </label>
          </div>
          <div className='flex justify-between mt-1'>
               <span className='text-sm text-[#6D7175]'>Input the Terms and condition policy</span>
              <span className=" text-xs ">{count}/6000</span>
               </div>
          <div className="py-6">
          <button
              type="button" 
              className=" px-4 py-2 w-48 text-sm font-medium  bg-theme-color hover:bg-orange-500 text-white rounded"
              onClick={()=>{handleUpdate()}}>
              Update
            </button>
          </div>
        </div>
    </>
  )
};
export default EventTermsCondition;
