import React, { useContext , useState, useEffect} from "react";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const EventPrivacyPolicy = () => {
  const{eventPrivacyPolicyApi,disableSave,getEventByIdApi,specificEvent} = useContext(DataContext)
  const [editPrivacy,setEditPrivacy] =useState({});
  const [count,setCount]=useState(0);

  const handleUpdate=()=>{
    if(!disableSave)
    eventPrivacyPolicyApi(editPrivacy)
   
  }
 
  const handleChange=(e)=>{
    setEditPrivacy({...editPrivacy,privacyPolicy:e.target.value})
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  }
  
  useEffect(() => {
    getEventByIdApi(setEditPrivacy({ ...specificEvent }));
    setCount(specificEvent?.privacyPolicy?.split("").length);
  }, []);

   const buttonDisabled =
   editPrivacy.privacyPolicy !== "" ;
 
  return (
    <>
    <ToastContainer/>
        <div className="bg-white w-full px-4 rounded-md">
          <div className="py-4 ">
          <hr/>
          </div>
          <div className="">
            <label
              className="block mb-2 text-sm"
              typeof="description"
              htmlFor="description"
            >
              Description *
              <textarea
                type="name"
                rows={"12"}
                className=" mt-2 block w-full  px-3 py-1 bg-white border placeholder:text-slate-400   rounded-md text-sm  placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-200 "
                placeholder="Enter your description"
                value={editPrivacy?.privacyPolicy} 
                onChange={(e)=>{handleChange(e)}}  
                maxLength={6000}
              />
            </label>
          </div>
          <div className='flex justify-between mt-1'>
               <span className='text-sm text-[#6D7175]'>Input the privacy policy</span>
              <span className=" text-xs ">{count}/6000</span>
               </div>
          <div className="py-6">
          <button
              type="button" disabled={!buttonDisabled}
              className=" px-4 py-2 w-48 text-sm font-medium  bg-theme-color hover:bg-orange-500 text-white rounded disabled:opacity-40" 
              onClick={()=>{handleUpdate()}} >
              Update
            </button>
          </div>
        </div>
    </>
  );
};
export default EventPrivacyPolicy;
