import React, { useContext, useEffect,useState } from "react";
import { AiFillCrown } from "react-icons/ai";
import { FiEdit2, FiTrash } from "react-icons/fi";
import { Link } from "react-router-dom";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { DataContext } from "../../context/DataState";
import dummyImage from "../Assets/dummyimage.jpg"
import Pagination from "../Pagination/Pagination"
import NoDataModal from "../NoDatatModal/NoDataModal";
import { BsInfoCircleFill } from "react-icons/bs";
import { Tooltip } from 'react-tooltip'


const Leaderboard = () => {
  const {  leaderboardList, globalLoader ,leaderboardPaginationApi,pageCount,totalCount,checkResponse} =
    useContext(DataContext);

    const limit=15;
  const [firstResultCount,setFirstResultCount]=useState(1)
  const [secondResultCount,setSecondResultCount]=useState(limit)
 

  useEffect(() => {
    
    leaderboardPaginationApi(1,limit)
  }, []);

 console.log(leaderboardList,"leaderboardList")
  
  return (
    <>
      <div className="w-full px-7 py-2 overflow-y-scroll scrollbar-thin h-screen space-y-4">
        <div className="pt-3 flex gap-4 items-center">
          <h1 className="text-xl text-[#202223] font-semibold">Leaderboard</h1>
          <Link to="/points-info">

          <BsInfoCircleFill  data-tooltip-id="my-tooltip" data-tooltip-content="Points info" className="text-theme-color " size={18}/>
          <Tooltip  place="right" id="my-tooltip"  className="custom-tooltip" />
          </Link>
         
         
        </div>
     
        {
        leaderboardList.length===0 && checkResponse===true ? <NoDataModal/> :
        globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
          <table className="w-full table-auto bg-white text-start shadow-md  rounded-lg ">
            <thead className="cursor-pointer">
              <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                <th className="w-[5%] px-4 py-2 rounded-tl-lg">Position</th>
                <th className="w-[5%] px-4 py-2 rounded">Photo</th>
                <th className="w-[20%] px-4 py-2">Full name</th>
                <th className="w-[25%] px-4 py-2">Email</th>
                <th className="w-[20%] px-4 py-2 rounded-tr-lg">Points</th>
              
              </tr>
            </thead>
            <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
              {leaderboardList.map((item) => {
                return (
                  <>
                    <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer" key={item?._id}>
                      {/* <td className='px-4 py-2 text-center'>
                    <input type="checkbox" />
                  </td> */}
                      <td className=" py-2 px-4 font-semibold text-base ">
                        <AiFillCrown
                          className={`rounded-md text-white text-2xl ${
                            item?.rank ===1
                              ? "bg-[#FFD54B]"
                              : item?.rank === 2
                              ? "bg-[#E1E1E2]"
                              : item?.rank === 3
                              ? "bg-[#F6B191]"
                              : "hidden"
                          }`}
                        /> {item?.rank!==1 &&item.rank!==2 &&item?.rank!==3 ? item?.rank : ""}
                      </td>
                      {
                        item?.detail.map((element,index)=>{
                          return (
                            <>
                            <td className="p-2" key={index}>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={element?.image == "" ? dummyImage: element?.image} 
                        />
                      </td>
                      <td className="py-2 px-4">{element?.Name}</td>
                      <td className=" py-2 px-4">{element?.email}</td>
                            </>
                          )
                        })
                      }
                      
                      <td className=" py-2 px-4">
                        <span className="bg-theme-color px-2 py-1 rounded-xl text-black text-[12px]">
                         {item?.score}
                        </span>{" "}
                      </td>
                    </tr>
                  </>
                );
              })}

              {/* <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
               
                <td className=" py-2 px-4">
                  <AiFillCrown className="bg-[#ffff31] rounded-md text-white text-2xl" />
                </td>
                <td className="p-2">
                  <img
                    className="h-8 w-8 rounded-full"
                    src={require("../Assets/speaker1.jpg")}
                  />
                </td>
                <td className="py-2 px-4">James Devine</td>
                <td className=" py-2 px-4">Jamesdevine@hotmail.com</td>
                <td className=" py-2 px-4">
                  <span className="bg-[#FF8531] px-2 py-1 rounded-xl text-black text-[12px]">
                    632
                  </span>{" "}
                </td>
              </tr> */}
            </tbody>
          </table>
          {
            (leaderboardList.length>limit && !checkResponse) && <div className="flex justify-between items-start ">
            <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount}  of ${totalCount} results`}</span>
           <Pagination   firstResultCount={firstResultCount} setFirstResultCount={setFirstResultCount} secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} limit={limit} api={leaderboardPaginationApi}/>
           </div>
          }
          
          </>
        )}
      </div>
    </>
  );
};

export default Leaderboard;
