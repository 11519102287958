import React,{useContext, useState} from "react";
import { useMemo } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import ImageResize from 'quill-image-resize-module-react';
import { useMediaQuery } from 'react-responsive';
import { DataContext } from "../../context/DataState";

const NewTemplate = () => {
  const{setStatus}=useContext(DataContext)
  const [sendEmailData, setSendEmailData] = useState("")
  const quillRef = useRef();
  const handleBody = (e) => {
    // setSendEmailData({ ...sendEmailData, body: e })
    setSendEmailData(e)
  };

  Quill.register('modules/imageResize', ImageResize);
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"];
  Quill.register(Size, true);
  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', "strike"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['image', "link"],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }],
        [{ 'size': ['small', false, 'large', 'huge'] }]
        [{ script: "sub" }, { script: "super" }],
        [{ font: [] }, { 'size': ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"] }],
      ]
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  }), []);
  return (
    <>
      <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
              <Link to={"/contactlist"} onClick={()=>{setStatus("template")}}>
                <button className="border p-2">
                  <FaArrowLeft />
                </button>
              </Link>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">Email template </h1>
          </div>
        </div>
        <div className="flex space-x-10 m-0   p-3 rounded">
          <div className="w-1/2 bg-white  border-[#D1D9E7] rounded-lg p-3  space-y-3 ">
            <form className="">
              <div>
                <label
                  htmlFor="templateName"
                  className="text-sm px-1 font-medium pb-1 text-[#202223]"
                >
                  Email Template name
                </label>
                <input
                  type="text"
                  id="templateName"
                  name="templateName"
                  placeholder="Enter Template Name"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                />
              </div>
              <div>
                <label
                  htmlFor="subject"
                  className="text-sm px-1 font-medium  text-[#202223]"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  placeholder="Enter Subject"
                  className="w-full  py-1 px-3 border placeholder:text-slate-400 text-sm h-9 border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 "
                />
              </div>
              <div className="text-[#202223]  w-full ">
                <label
                  className="block relative  text-sm px-1 font-medium pb-1 text-[#202223]"
                  typeof="description"
                  htmlFor="description"
                >
                  Description (Body)
                </label>
                <ReactQuill
            className="4xl:h-[420px_!important]"
            // value={sendEmailData}
            onChange={(e) => { handleBody(e) }}
            modules={modules}
            ref={quillRef} />
              </div>

              <div>
                <div className="pt-5">
                  <Link to={"/contactlist"} onClick={()=>{setStatus("template")}}>
                    <button className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded">
                      Back
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="bg-theme-color px-0 text-sm py-2 rounded disabled:opacity-40 hover:bg-orange-500 text-white ml-3"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default NewTemplate;
