import * as XLSX from "xlsx";

const exportToXLS = (tableHeader, tableData, nameOfFile) => {
  const headers = tableHeader.flatMap((th) =>
    th.value !== "actions" ? th.value : []
  );
  const dataForExport = tableData.map((row) =>
    headers.map((header) => row[header])
  );
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.aoa_to_sheet([headers, ...dataForExport]);
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
  
  return XLSX.writeFile(wb, `${nameOfFile}.xls`);
};

export default exportToXLS;
