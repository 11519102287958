import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Login from "./components/Login/Login";
import SignUp from "./components/SignUp/SignUp";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import TermsCondition from "./components/TermsAndCondition/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Dashboard from "./components/Dashboard/Dashboard";
// import EventList from "./components/Event/EventList";
// import Speaker from "./components/Speaker/Speaker";
import Sponsors from "./components/Sponsors/Sponsors";
import SponsorLevel from "./components/Sponsors/SponsorLevel";
import UpdateSponsorLevel from "./components/Sponsors/UpdateSponserLevel";
import ImportSponsor from "./components/Sponsors/ImportSponsor";
import AddSponsor from "./components/Sponsors/AddSponsor";
import UpdateSponsor from "./components/Sponsors/UpdateSponsor";
import Notification from "./components/Notification/Notification";
import Chat from "./components/Chat/Chat";
import DataProvider from "./context/DataState";
import EventInformation from "./components/Event/EventInformation";
// import EveAgenda from "./components/Event/EventAgenda";
// import AgendaNote from "./components/Event/AgendaNote";
import ViewEvents from "./components/Event/ViewEvents";
import SpeakerList from "./components/Speaker/SpeakerList";
import AddSpeaker from "./components/Speaker/AddSpeaker";
// import ImportSpeak from "./components/Users/ImportSpeaker";÷
import AddSponsorLevel from "./components/Sponsors/AddSponsorLevel";
import Sidebar from "./components/Sidebar/Sidebar";
import AddAgenda from "./components/Agenda/AgendaList";
import NewSession from "./components/Agenda/NewSession";
import ImportAgenda from "./components/Agenda/ImportAgenda";
import CreatePassword from "./components/CreatePassword/CreatePassword";
import Attendees from "./components/Attendees/Attendees";
import AddAttendees from "./components/Attendees/AddAttendees";
import UpdateUser from "./components/Attendees/UpdateUser";
import Faq from "./components/Faq/Faq";
import ChangePassword from "./components/Event/ChangePassword";
import Polls from "./components/Polls/Polls";
import Addpolls from "./components/Polls/Addpolls";
import AddFaq from "./components/Faq/AddFaq";
import UpdateSpeaker from "./components/Speaker/UpdateSpeaker";
import UpdateFaq from "../src/components/Faq/UpdateFaq"

import UpdatePolls from "./components/Polls/UpdatePolls";
import PublishedLog from "./components/PublishedLog/PublishedLog";
import UpdateAgenda from "./components/Agenda/UpdateAgenda";
import Announcement from "./components/Announcements/Announcement";
import AddAnnouncements from "./components/Announcements/AddAnnouncements";
import ImportAttendees from "./components/Attendees/ImportAttendees";
import Leaderboard from "./components/Leaderboard/Leaderboard";
import Socialwall from "./components/Socialwall/Socialwall";
import Exhibitors from "./components/Exhibitors/Exhibitors";
import Tutorial from "./components/Tutorial/Tutorial";

import AddExhibitors from "./components/Exhibitors/AddExhibitors";
import UpdateAnnouncement from "./components/Announcements/UpdateAnnouncement";
import UpdateExhibitor from "./components/Exhibitors/UpdateExhibitor";
import Users from "./components/Users/Users";
import UserRoles from "./components/Users/UsersRoles";
import TimeLine from "./components/Socialwall/TimeLine";
import Activity from "./components/Socialwall/Activity";
import EventDetails from "./components/Event/EventDetails";
import EventPrivacyPolicy from "./components/Event/EventPrivacyPolicy";
import NewRole from "./components/Users/NewRole";
import AddUser from "./components/Users/AddUser";
import EventTermsCondition from "./components/Event/EventTermsCondition";
import Meetings from "./components/Meetings/Meetings";
import Registration from "./components/Registration/Registration";
import EmailEditorComponent from "./components/EmailTemp/EmailTemplate";
import DetailModal from "./components/Attendees/UserDetails";
import SpeakerDetails from "./components/Speaker/SpeakerDetails";
import AgendaDetails from "./components/Agenda/AgendaDetails";
import SponsorDetails from "./components/Sponsors/SponsorDetails";
import ExhibitorDetails from "./components/Exhibitors/ExhibitorDetails";
import CheckIn from "./components/Agenda/CheckIn";
import ContactList from "./components/Contacts/ContactList";
import ImportContact from "./components/Contacts/ImportContact";
import AddContact from "./components/Contacts/AddContact";
import AddContactList from "./components/Contacts/AddContactList";
import CreateContact from "./components/Contacts/CreateContact";
import UpdateContact from "./components/Contacts/UpdateContact";
import ViewContact from "./components/Contacts/ViewContact";


import Report from "./components/Reports/Report";
import EventOverviewViewPage from "./components/Reports/EventOverviewViewPage";
import SponsorsView from "./components/Reports/SponsorsView";
import ExhibitorsOverviewViewPage from "./components/Reports/ExhibitorsOverviewViewPage";
import AgendaOverviewViewPage from "./components/Reports/AgendaOverviewViewPage";
import SpeakersOverviewViewPage from "./components/Reports/SpeakersOverViewViewPage";
import AttendeeOverviewViewPage from "./components/Reports/AttendeeOverviewViewPage";
import NewTemplate from "./components/Contacts/NewTemplate";
import DateAndTime from "./components/Reports/DateAndTime";
import PointsInfo from "./components/Leaderboard/PointsInfo";
import DeleteAcc from "./DeleteAcc/DeleteAcc";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      theme="light"
      />
      <Router>
        <DataProvider>
          <div className="flex w-full">
            <Sidebar/>
          <Routes>
          <Route exact path="/delete-account" element={<DeleteAcc />} />
            <Route exact path="/" element={<Login />} />
            <Route exact path="/signup" element={<SignUp />} />
            <Route exact path="/forgot-password" element={<ForgotPassword />} />
            <Route exact path="/create-password" element={<CreatePassword/>} />
            <Route exact path="/reset-password/:userId" element={<ResetPassword />} />
            <Route exact path="/terms-conditions" element={<TermsCondition />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            {/* <Route exact path="/event" element={<EventList />} /> */}
            {/* <Route exact path="/speaker" element={<Speaker />} /> */}
            <Route exact path="/sponsors" element={<Sponsors />} />
            <Route exact path="/update-sponsor/:id" element={<UpdateSponsor/>}/>
            <Route exact path="/sponsorlevel" element={<SponsorLevel/>}/>
            <Route exact path="/update-sponsorlevel/:id" element={<UpdateSponsorLevel/>}/>
            <Route exact path="/importsponsor" element={<ImportSponsor/>}/>
            <Route exact path="/addsponsor" element={<AddSponsor/>}/>
            <Route exact path="/addsponsorlevel" element={<AddSponsorLevel/>}/>
            <Route exact path="/update_speaker/:id" element={<UpdateSpeaker/>}/>
            <Route exact path="/notification" element={<Notification />} />
            <Route exact path="/chat" element={<Chat />} />
            <Route exact path="/view-events" element={<ViewEvents />} />
            <Route exact path="/event_information" element={<EventInformation />} />
            {/* <Route exact path="/event_agenda" element={<EveAgenda />} /> */}
            {/* <Route exact path="/agenda_note" element={<AgendaNote />} /> */}
            <Route exact path="/speaker_list" element={<SpeakerList/>} />
            <Route exact path="/add_speaker" element={<AddSpeaker/>} />
            <Route exact path="/import-attendees" element={<ImportAttendees/>} />
            <Route exact path="/agenda-list" element={<AddAgenda/>} />
            <Route exact path="/update-agenda/:agendaId" element={<UpdateAgenda/>} />
            <Route exact path="/new_session" element={<NewSession/>} />
            <Route exact path="/import_agenda" element={<ImportAgenda/>} />
            <Route exact path="/attendees" element={<Attendees/>} />
            <Route exact path="/add-attendees" element={<AddAttendees/>} />
            <Route exact path="/update_user/:id" element={<UpdateUser/>}/>
            <Route exact path="/user/:id" element={<DetailModal/>}/>
            <Route exact path="/speaker/:id" element={<SpeakerDetails/>}/>
            <Route exact path="/agenda/:id" element={<AgendaDetails/>}/>
            <Route exact path="/sponsor/:id" element={<SponsorDetails/>}/>
            <Route exact path="/exhibitor/:id" element={<ExhibitorDetails/>}/>
            <Route exact path="/faq" element={<Faq/>} />
            <Route exact path="/add-faq" element={<AddFaq/>} />
            <Route exact path="/update-faq/:id" element={<UpdateFaq/>} />
            <Route exact path="/reset-password" element={<ResetPassword/>} />
            <Route exact path="/change-password" element={<ChangePassword/>} />
            <Route exact path="/addspeaker" element={<AddSpeaker/>} />
            <Route exact path="/polls" element={<Polls/>} />
            <Route exact path="/add-polls" element={<Addpolls/>} />
            <Route exact path="/add-polls" element={<Addpolls/>} />
            <Route exact path="/update-polls/:pollId" element={<UpdatePolls/>} />          
            <Route exact path="/published-log" element={<PublishedLog/>} />          
            <Route exact path="/announcement" element={<Announcement/>} />          
            <Route exact path="/add-announcement" element={<AddAnnouncements/>} /> 
            <Route exact path="/update-announcement/:announcementId" element={<UpdateAnnouncement/>} />        
            <Route exact path="/leaderboard" element={<Leaderboard/>} />        
            <Route exact path="/socialwall" element={<Socialwall/>} />        
            <Route exact path="/exhibitors" element={<Exhibitors/>} />        
                  
            <Route exact path="/tutorial" element={<Tutorial/>} />        
            <Route exact path="/add-exhibitor" element={<AddExhibitors/>} /> 
            <Route exact path="/update-exhibitor/:exhibitorId" element={<UpdateExhibitor/>} /> 
            <Route exact path="/users" element={<Users/>} /> 
            <Route exact path = "/user-roles" element = {<UserRoles/>} />
            <Route exact path = "/time-line" element = {<TimeLine/>} />
            <Route exact path = "/activity" element = {<Activity/>} />
            <Route exact path = "/event-details" element = {<EventDetails />} />
            <Route exact path = "/event-privacypolicy" element = {<EventPrivacyPolicy />} />
            <Route exact path = "/new-role" element = {<NewRole/>} />
            <Route exact path = "/add_user" element = {<AddUser/>} />
            <Route exact path = "/edit_user/:id" element = {<AddUser/>} />
           <Route exact path = "/event-termscondition" element = {<EventTermsCondition/>}/> 
           <Route exact path = "/meetings" element = {<Meetings/>}/> 
           <Route exact path = "/registration" element = {<Registration/>}/> 
           <Route exact path = "/email-template" element = {<EmailEditorComponent/>}/> 
           <Route exact path = "/checkin" element = {<CheckIn/>}/> 
           <Route exact path = "/contactlist" element = {<ContactList/>}/> 
           <Route exact path = "/import-contacts" element = {<ImportContact/>}/> 
           <Route exact path = "/addcontact" element = {<AddContact/>}/> 
           <Route exact path = "/addcontactlist" element = {<AddContactList/>}/> 
           <Route exact path = "/createcontact" element = {<CreateContact/>}/> 
           <Route exact path="/update-contact/:contactId" element={<UpdateContact/>} /> 
           <Route exact path="/view-contact" element={<ViewContact/>} /> 
           <Route exact path="/reports" element={<Report/>} />    
           <Route exact path="/eventoverview-view" element={<EventOverviewViewPage/>} /> 
           <Route exact path="/sponsors-view" element={<SponsorsView/>} /> 
           <Route exact path="/exhibitorsoverview-view" element={<ExhibitorsOverviewViewPage/>} />
           <Route exact path="/agendaoverview-view" element={<AgendaOverviewViewPage/>} />    
           <Route exact path="/speakeroverview-view" element={<SpeakersOverviewViewPage/>} />   
           <Route exact path="/attendeeoverview-view" element={<AttendeeOverviewViewPage/>} />  
           <Route exact path="/create-template" element={<NewTemplate/>} />     
           <Route exact path="/date-time" element={<DateAndTime/>} />    
           <Route exact path="/points-info" element={<PointsInfo/>} />     
          </Routes>
          </div>
        </DataProvider>
      </Router>
    </div>
  );
}

export default App;
