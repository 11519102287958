import { React, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import Pagination from "../Pagination/Pagination";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { AiOutlineEye } from "react-icons/ai";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { Tooltip } from 'react-tooltip'

const Sponsors = () => {
  const {
    getSponsorList,
    sponsorsList,
    setSponsorsList,
    deleteBtn,
    setDeleteBtn,
    setDeleteBtnId,
    deleteBtnId,
    deleteSponsorApi,
    searchSponsorApi,
    globalLoader,
    sponsorPaginationApi,
    totalCount,pageCount,
    currentPage,setCurrentPage,checkResponse
  } = useContext(DataContext);
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);

  useEffect(() => {
   setCurrentPage(1)
    sponsorPaginationApi(1, limit);
  }, []);

  const onKeyUpHandler = (e) => {
    searchSponsorApi(e.target.value,currentPage);
  };
  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...sponsorsList].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setSponsorsList(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };
  console.log(sponsorsList,"sponsorsorlist")
  console.log(globalLoader,"globalLoader")
  return (
    <>
      {deleteBtn ? (
        <DeleteModal deleteApi={deleteSponsorApi} deleteId={deleteBtnId} currentPage={currentPage} limit={limit}
        secondResultCount={secondResultCount} setSecondResultCount={setSecondResultCount} />
      ) : (
        ""
      )}
      <div className="w-full flex">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-1">
          <div className="pt-3 ">
            <div>
              <h1 className="text-xl  font-semibold ">Sponsor</h1>
            </div>
            <div className="flex pt-5 text-sm">
              <Link to="/sponsorlevel">
                <button className="pr-4 hover:underline hover:text-theme-color">
                  {" "}
                  Sponsor Level
                </button>
              </Link>
              <Link to="/sponsors">
                <button className="hover:underline text-theme-color underline hover:text-theme-color">
                  Sponsor
                </button>
              </Link>
            </div>
            <div className=" flex justify-between space-x-2 py-5 text-sm">
              <div className="space-x-2">
                <Link to="/addsponsor">
                  <button className="border shadow-btnshd bg-theme-color text-white px-4 py-2 rounded text-sm">
                    Add New Sponsor
                  </button>
                </Link>
               
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-1 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
         
          {
          sponsorsList.length===0  && checkResponse===true ? <NoDataModal/> :
          globalLoader ? (
            <GlobalLoader />
          ) : <>
            <table className="table-auto bg-white rounded-lg shadow-md text-start w-full">
              <thead className=" cursor-pointer ">
                <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                  <th className="w-2/12 px-4  py-2 text-[12px] rounded-tl-lg">
                    Logo
                  </th>
                  <th className="w-2/12 py-2 px-4 text-[12px] relative">
                    Sponsor Name
                    <div className="px-[90px] ">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] absolute ${getSortArrowClassName(
                            "sponsor_name",
                            "asc"
                          )}`}
                          onClick={() => sortData("sponsor_name")}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute ${getSortArrowClassName(
                            "sponsor_name",
                            "desc"
                          )}`}
                          onClick={() => sortData("sponsor_name")}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="w-3/12 px-4 py-2 text-[12px] relative">
                     Level
                    <div className="px-[35px] ">
                      <span className="">
                        <FaSortUp
                          className={`top-[30%] absolute ${getSortArrowClassName(
                            "level_name",
                            "asc"
                          )}`}
                          onClick={() => sortData("level_name")}
                        />
                        <FaSortDown
                          className={`top-[40%] absolute ${getSortArrowClassName(
                            "level_name",
                            "desc"
                          )}`}
                          onClick={() => sortData("level_name")}
                        />
                      </span>
                    </div>
                  </th>
                  <th className="w-2/12 px-4 py-2 text-[12px]">
                    Contact Number
                  </th>
                  <th className="w-4/12 px-4 py-2 text-[12px]">Website Url</th>
                  <th className="w-3/12  py-2 text-[12px] text-start px-2 rounded-tr-lg">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {sponsorsList?.map((item,index) => {
                  // item.sponsors.length > 0 && item.sponsors.map((sItems) => {
                  return (
                    <tr className="border-y  text-start text-sm group hover:shadow-tableRowsShadow cursor-pointer" key={index}>
                      <td className="p-2 px-4">
                        <Link>
                          <img
                            src={item?.image}
                            className="w-10 h-8 rounded-md border-2 border-gray"
                            alt="Sponsor"
                          />
                        </Link>
                      </td>
                      <td className="px-4">{item.sponsor_name}</td>
                      <td className="px-4">{item.level_name}</td>
                      <td className=" px-4">{item.phone}</td>
                      <td className=" px-4">{item.url}</td>
                      <td className=" ">
                        <div className="flex items-center  justify-center  text-black ">
                        <Link to={"/sponsors-view"} state={item}>
                            <HiOutlineChartSquareBar size={30}
                              data-tooltip-id="my-tooltip" data-tooltip-content="Report"
                            className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                             <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </Link>
                        <Link to={`/sponsor/${item?._id}`} state={item}>
                          <AiOutlineEye
                           data-tooltip-id="my-tooltip" data-tooltip-content="View"
                          className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] "/>
                           <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                        </Link>
                          <Link to={`/update-sponsor/${item?._id}`}>
                            <FiEdit2
                             data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                               <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                          </Link>
                          <FiTrash
                           data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              setDeleteBtn(true);
                              setDeleteBtnId(item?._id);
                            }}
                          />
                              <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                        </div>
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </table>
          {
           !checkResponse &&  <div className="flex justify-between items-center ">
            <span className="font-[150] text-sm mt-2">{`showing ${firstResultCount} to ${pageCount===1 ? totalCount: secondResultCount} of ${totalCount} results`}</span>
            <Pagination
              firstResultCount={firstResultCount}
              setFirstResultCount={setFirstResultCount}
              secondResultCount={secondResultCount}
              setSecondResultCount={setSecondResultCount}
              limit={limit}
              api={sponsorPaginationApi}
            />
          </div>
          }
         
          </>
}
        </div>
      </div>
    </>
  );
};
export default Sponsors;
