import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FiLock } from "react-icons/fi";
import logo from "../Assets/logo.png";
import coverImage from "../Assets/Vector5.png"
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { useParams } from "react-router-dom";



const ResetPassword=()=>{
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const {CreatePasswordApi} = useContext(DataContext);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [matchPasswordError, setMatchPasswordError] = useState(false);
  const {setButtonLoader,resetPasswordApi}=useContext(DataContext)
  const [userId, setUserId] = useState("");
  // const {userId}=useParams()
 

  const [state, setState] = useState({
    password : "",
    confirmPassword : ""
  });

  const validatePassword = () => {
    if (state.password === "") {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }
  }

  const validateConfirmPassword = () => {
    if (state.confirmPassword === "") {
      setConfirmPasswordError(true);
    } else {
      setConfirmPasswordError(false);
    }
  }

  const matchPassword = () => {
    if (state.confirmPassword === "") {
      setMatchPasswordError(false);
    } else if (state.password  != state.confirmPassword){
      setMatchPasswordError(true);
    } else {
      setMatchPasswordError(false);
    }
  }

  const buttonDisabled = (state.password === "" || state.confirmPassword === "" || state.password != state.confirmPassword);

  const handleChangePassword = () => {
    if(state.password === state.confirmPassword){
      setMatchPasswordError(false);
      setButtonLoader(true)
      const queryParams = new URLSearchParams(window.location.search);
      setUserId(queryParams.get("user_id"));
      resetPasswordApi(state,userId)
    }else{
      setMatchPasswordError(true);
    }
  };

    return (
        <>
        <div className="w-full h-screen flex items-center justify-center flex-col bg-contain bg-top bg-no-repeat" 
        style={{ backgroundImage: `url(${coverImage})`}} > 
        <div className="w-[33%] xl:w-[90%] flex items-center ">
       <img src={require("../Assets/event logo.png")} className=" w-[30%] m-auto pb-5 px-5 " alt="favicon"></img>
      </div>
        <div className="w-[33%] xl:w-[90%] bg-white shadow hover:shadow-lg rounded-md">
          <div className=" py-2 rounded-t-md">
            <h1 className="text-center text-[20px] 2xl:text-[30px]  font-bold  mb-2">Reset Password</h1>
            <p className="text-center text-sm mb-3 px-9">Strong password include number, letter, and punctuation Marks.</p>
          </div>
          <hr/>
          <div className="p-9 md:p-8">
            <form>
              <label className="relative block text-sm font-medium "><span className=" text-theme-color ">*</span> Password
                <input
                  type={password === true ? "text" : "password"}
                  className=" block w-full px-3 pl-4 py-3 mt-2 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 "
                  placeholder="Enter Password"
                  name="password"
                  onChange={(e) => {
                    setState({...state, password: e.target.value});
                  }}
                  onKeyUp={() => validatePassword()}
                />
                <span
                  className="absolute bottom-3 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setPassword(!password)}
                >
                  {password === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
                {passwordError && (
                  <small className="text-left text-red-600">Enter Password</small>
                )}
              <label className="relative block mt-6 text-sm font-medium"><span className=" text-theme-color ">*</span>Confirm Password
                <input
                  type={confirmPassword === true ? "text" : "password"}
                  className="mt-2 block w-full px-3 pl-4 py-3 bg-white border border-theme-color rounded-md text-sm shadow-sm placeholder-slate-400 focus-visible:border-inputBorder focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-1 "
                  placeholder="Enter Confirm Password"
                  name="confirmPassword"
                  onChange={(e) => {
                    setState({...state, confirmPassword: e.target.value});
                  }}
                  onKeyUp={() => {validateConfirmPassword(); matchPassword();}}
                />
                <span
                  className="absolute bottom-3 right-0 flex items-center pr-2 cursor-pointer"
                  onClick={() => setConfirmPassword(!confirmPassword)}
                >
                  {confirmPassword === true ? (
                    <FaRegEye className="h-5 w-5 fill-theme-color" />
                  ) : (
                    <FaRegEyeSlash className="h-5 w-5 fill-theme-color" />
                  )}
                </span>
              </label>
              {confirmPasswordError && (
                  <div><small className="text-left text-red-600">Enter Confirm Password</small></div>
                )}
                
              {matchPasswordError && (
                  <div><small className="text-left text-red-600">Password and Confirm Password not match</small></div>
                )}  
              <div className="flex justify-center mt-8 mb-2">
                <button type="button" className="bg-theme-color text-white p-2 w-full rounded-lg  font-medium disabled:opacity-40 cursor-pointer"
                onClick={() => handleChangePassword()}
                disabled={buttonDisabled}>
                  Reset Password
                </button>
              </div>
            </form>
          </div>
        </div>
        <small className=" mt-4">© 2023 Event Plus </small>
      </div>
        </>
    )
}
export default ResetPassword;