import { useContext, useEffect, useMemo, useState } from "react";
import { useRef } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from 'quill-image-resize-module-react';
import EmailEditor from 'react-email-editor';
import EmailEditorProps from 'react-email-editor'
import EditorRef from 'react-email-editor'
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const EmailEditorComponent = () => {
  const { getEmailTemplate, emailTemplate, updateEmailTemplate } = useContext(DataContext);
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    const unlayer = emailEditorRef.current?.editor;
    console.log(unlayer+"unlayer")

    unlayer?.exportHtml((data) => {
      const { design, html } = data;
      console.log('exportHtml', html);
    });
  };

  const onReady = (unlayer) => {

    const templateJson = {  };
    unlayer.loadDesign(templateJson);
  };

  const quillRef = useRef();
  Quill.register('modules/imageResize', ImageResize);
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"];
  Quill.register(Size, true);

  const [sendEmailData, setSendEmailData] = useState("")
  const [emailID, setEmailID] = useState("")

  const isSmallScreen = useMediaQuery({ query: '(max-width: 990px)' });
  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', "strike"],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['image', "link"],
        [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }],
        [{ 'size': ['small', false, 'large', 'huge'] }]
        [{ script: "sub" }, { script: "super" }],
        [{ font: [] }, { 'size': ["8px", "10px", "11px", "12px", "13px", '14px', "15px", '16px', '18px', "24px", "36px", "48px"] }],
      ]
    },
    imageResize: {
      parchment: Quill.import('parchment'),
      modules: ['Resize', 'DisplaySize']
    }
  }), []);

  const handleBody = (e) => {
    // setSendEmailData({ ...sendEmailData, body: e })
    setSendEmailData(e)
  };

  const templateDataFilter = (e) => {
    console.log("hello1")
    console.log(emailTemplate,"emailTenolate")
    emailTemplate.length && emailTemplate.map((item) => {
      console.log(item.templateName,"item template name")
      console.log(e.target.value,"e.target.value")
      if (item.templateName == e.target.value) {
       console.log("hello 2")
        setSendEmailData(item.html)
        setEmailID(item._id)
      }
    })
  }

  useEffect(() => {
    getEmailTemplate()
   
  }, [])
  useEffect(()=>{
    emailTemplate.length && emailTemplate.map((item)=>{
      if(item.templateName=="attendeeTemplate")
      {
        setSendEmailData(item.html)
        setEmailID(item._id)
      }
     })
  },[emailTemplate])

  console.log(sendEmailData, "sendEmailData")
console.log(emailTemplate,"emailTemplate")
  return (
    <>
      <div className="flex w-full bg-white">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex justify-between">
            {/* <Link to={"/agenda-list"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link> */}
            <div className="p-2 ">
              <div>
                <button 
                disabled={sendEmailData==""?true:false}
                className="border shadow-btnshd bg-theme-color disabled:opacity-50 text-white   px-4 py-2 rounded text-sm"
                  onClick={() => { updateEmailTemplate(sendEmailData, emailID) }}
                >Update HTML</button>
              </div>
            </div>
          </div>

          <div>
            <select name="template" id="template" className="w-full border py-1 px-3 mb-3 border-gray-300 shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none   focus-visible:shadow-focusshadow focus-visible:border-2 placholder:text-slate-400 text-sm h-9"
              onChange={(e) => {
                templateDataFilter(e)
              }}>
             
              <option value="attendeeTemplate" selected>Attendee Template</option>
              <option value="passwordReset">Password Reset</option>
              <option value="subAdminTemplate">SubAdmin Template</option>
              <option value="credentialsTemplate">Credentials Template</option>
              <option value="meetingRequestTemplate">Meeting Request Template</option>
            </select>
          </div>







          <ReactQuill
            className="4xl:h-[420px_!important]"
            value={sendEmailData}
            onChange={(e) => { handleBody(e) }}
            modules={modules}
            ref={quillRef} />
         
        </div>
      </div>
    </>
  );
}

export default EmailEditorComponent;