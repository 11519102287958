import React from "react";

const CustomButton = ({
  category,
  className: classes,
  children,
  loading,
  rounded,
  ...attributes
}) => {
  let buttonSize = ``;
  const { transparent } = { ...attributes };
  switch (category) {
    case "small":
      buttonSize = `w-[96px] `;
      break;
    case "medium":
      buttonSize = `w-[143px]`;
      break;
    case "large":
      buttonSize = `w-[160px]`;
      break;
    default:
      buttonSize = `w-[96px]`;
      break;
  }

  return (
    <button
      className={`${buttonSize} ${loading
        ? `bg-[#D8D9D2]`
        : !!transparent
          ? `bg-transparent border-[1px] border-[#BABFC3] hover:bg-gray-300 text-black`
          : `bg-theme-color disabled:opacity-40 hover:bg-orange-500 text-white`
        } ${rounded ? 'rounded-[21px]' : 'rounded-[4px]'} h-[36px] text-[14px] text-center ${classes} flex justify-center items-center gap-[4px] md:gap-[8px]`}
      {...attributes}
    >
      {children}
    </button>
  );
};

export default CustomButton;
