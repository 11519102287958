import React, { useContext, useEffect, useState, useRef } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";
import { MdClose } from "react-icons/md";

const UpdateUser = () => {
  const { getUserApi, user, updateUserApi, getRegData, getRegistraition, disableSave } = useContext(DataContext);
  const [count, setCount] = useState(0);
  const { id } = useParams();
  const imageInput = useRef()
  const location = useLocation()
  const userEdit = location.state;

  const [editUser, setEditUser] = useState(userEdit);
  const initialFormState = {};

  getRegData?.forEach((item) => {
    initialFormState[item.columnName] = editUser[item?.columnName];
  });
  const [formData, setFormData] = useState(initialFormState)

  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneFormatTest, setPhoneFormatTest] = useState(false);
  const [indexKey, setIndexKey] = useState([])
  const [phoneLength, setPhoneLength] = useState(true)
  const [checkDisable, setCheckDisable] = useState([])


  const validationHandler = (data, index) => {
    if (data.validation == "mobilenumber") {
      validatePhoneno(formData[data.columnName], index)
    } else if (data.validation == "email") {
      validateEmail(formData[data.columnName], index)
    }
  }

  const validatePhoneno = (phoneData) => {
    var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

    // Check for a 10-digit number without formatting
    const isValidPhone = phoneData.match(phoneno);
    const isEmpty = phoneData === '';

    if (isValidPhone || isEmpty) {
      setPhoneFormatTest(false);
      setPhoneLength(phoneData.length === 10); // Ensure phoneLength is true only if length is 10
    } else {
      setPhoneFormatTest(true);
      setPhoneLength(false); // Set phoneLength to false for invalid input
    }
  };

  const validateEmail = (email) => {
    const validEmail = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    if (!validEmail.test(email) && email !== "") {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };
  
  const handleUpdate = () => {
    if (!disableSave)
      updateUserApi(editUser, id, "userapi", formData);
  };

  const handleDelete = () => {
    setEditUser({ ...editUser, image: "" });
    setImage(null)
    imageInput.current.value = "";

  }


  const handleChange = (e) => {
    setEditUser({ ...editUser, biography: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };

  const handleChangeData = (e, data) => {
    const { name, value } = e.target;

    // Check for maximum length (for mobile numbers)
    if (value.length <= data.maximumLength) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));

      // Validate phone number when its field changes
      if (data.validation === "mobilenumber") {
        validatePhoneno(value);
      }

      // Validate email if applicable
      if (data.validation === "email") {
        validateEmail(value);
      }
    }
  };

  const onImageChange = (event) => {
    const allowedExtensions = ["jpg", "jpeg", "png", "webp"];

    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
      return allowedExtensions.some((extension) => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(
      event.target.files[0].name,
      allowedExtensions
    );

    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
    // setEditUser({
    //   ...editUser,
    //   image: event.target.files[0],
    // });
    if (!isAllowed) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError(false)
      setEditUser({
        ...editUser, image: event.target.files[0],
      });
    }
  };

  useEffect(() => {
    getRegistraition()
    setCount(editUser?.biography?.split("").length);

  }, []);

  const buttonDisabled =
    editUser?.Name !== "" &&
    editUser?.email !== "" &&
    editUser?.organization !== "" &&
    editUser?.designation !== "" &&


    editUser?.phone !== "";
  console.log(getRegData, "retRegdata")
  console.log(editUser, "editUser")
  console.log(formData, "FormData")
  return (
    <>
      <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={"/attendees"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">{editUser?.Name}</h1>
          </div>
        </div>
        <div className="flex flex-col p-4 bg-white rounded-lg border-[#D1D9E7]">
          <div className="flex space-x-10 m-0 ">
            <div className="w-1/2 space-y-3 ">
              <h2 className="font-semibold text-lg ">Attendees details</h2>
              <hr />
              <form className="">
                {
                  getRegData.length > 0 && getRegData.map((item, index) => (
                    <div>
                      <label
                        key={item?._id} htmlFor={item?.columnName}
                        className="text-sm px-1 font-semibold pb-1 text-[#202223]"
                      >
                        {" "}
                        {item.columnName} {item?.required == true ? " *" : ""}
                      </label>
                      <input
                        type="text"
                        id={item?.columnName}
                        value={formData[item?.columnName]}
                        name={item.columnName} // Add the name attribute to match the state variable
                        placeholder={`Enter your ${item?.columnName}`}
                        maxlength={item.validation == "mobilenumber" ? 10 : ""}
                        className={`w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9 ${phoneFormatTest ? "" : "mb-4"}`}
                        onChange={(e) => {
                          if (!indexKey.includes(index) && (item.validation == "mobilenumber" || item.validation == "email")) {
                            indexKey.push(index)

                          }
                          // setState({ ...state, Name: e.target.value });
                          handleChangeData(e, item)
                        }}
                        onKeyUp={() => validationHandler(item, index)}
                      />
                      { item.validation == "email" && emailError ? (
                        <span className="text-left text-red-600 pt-0 text-xs ">
                          Please enter a valid Email address
                        </span>
                      ) : (<></>)}
                      { item.validation == "mobilenumber" && phoneFormatTest  && phoneLength<10 ? (
                        <span className="text-left text-red-600 mb-4 text-xs">
                          Please enter a valid 10 digit Number.
                        </span>
                      ) : (<></>)}
                    </div>
                  ))
                }
                <div>
                  <label
                    htmlFor="organization"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    Organization *
                  </label>
                  <input
                    id="organization"
                    type="text "
                    name="organization"
                    placeholder="Enter your organization"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9"
                    value={editUser?.organization}
                    onChange={(e) => {
                      setEditUser({ ...editUser, organization: e.target.value });
                    }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="designation"
                    className="pt-2 text-sm px-1 font-semibold pb-1 text-[#202223]"
                  >
                    Designation *
                  </label>
                  <input
                    type="text"
                    id="designation"
                    name="designation"
                    placeholder="Enter your designation"
                    className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 mb-4 placholder:text-slate-400 text-sm h-9 "
                    value={editUser?.designation}
                    onChange={(e) => {
                      setEditUser({ ...editUser, designation: e.target.value });
                    }}
                  />
                </div>

                <div>
                  <div className="">
                    <Link to="/attendees">
                      <button className="border-2 px-8 py-2 text-sm  hover:bg-gray-300 rounded">
                        Back
                      </button>
                    </Link>
                    <button
                      type="button"
                      disabled={disableSave ? buttonDisabled : !buttonDisabled}
                      className="bg-theme-color px-8 text-sm py-2 rounded hover:bg-orange-400 text-white ml-3 disabled:opacity-50"
                      onClick={handleUpdate}>
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="w-1/2 pt-14">
              <div className="pb-4">
                <label
                  htmlFor="biography"
                  className="pt-16 font-semibold text-sm pb-1 text-[#202223]"
                >
                  Biography
                </label>
                <textarea rows="4"
                  id="biography"
                  name="biography"
                  className="w-full  py-1 px-3 border border-gray-300 focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm rounded-md  focus-visible:shadow-focusshadow focus-visible:border-1 placholder:text-slate-400 text-sm"
                  value={editUser?.biography}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  maxLength={2000}
                />
                <div className="flex justify-between">
                  <span className="text-xs text-gray-500 ">
                    Provide some details about the speaker's background,
                    achievements and presentation goals.
                  </span>
                  <span className="bottom-1 text-xs mt-1">
                    {count}/2000
                  </span>
                </div>
              </div>

              <p className="text-sm font-semibold pb-1 text-[#202223]">
                Profile Image
              </p>
              <div className=" h-28 border-2 border-dashed rounded-md text-center flex flex-col justify-center items-center hover:border-theme-color">
                <label
                  htmlFor="choose_banner"
                  accept="image/png,image/jpeg, image/jpg"
                  className="text-theme-color cursor-pointer bg-theme-light-color px-2 py-1  text-xs"
                >
                  {(image === null) ? "Choose profile image" : editUser?.image?.name}
                  {/* {editUser.image == "" ? "Add files" : editUser?.image?.name} */}
                  <input
                    id="choose_banner"
                    type="file"
                    ref={imageInput}
                    className="text-theme-color hidden   bg-orange-200 px-1 text-xs rounded-md "
                    onChange={(e) => {
                      onImageChange(e);
                    }}
                  />
                </label>
                <p className="text-xs pt-1 text-gray-400 leading-4">
                  Accepts jpeg, jpg, png and webp
                </p>
                <p className="text-[11px] font-normal text-gray-400 ">
                  Maximum Upload size: 1Mb
                </p>
              </div>
              <span className="text-xs text-red-600 font-normal">
                {imageError ? imageError : ""}
              </span>
              <div className={(image === null && !imageError) && editUser?.image !== "" ? "relative w-28 h-28 mt-4" : "hidden"}>
                <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2"
                  onClick={() => handleDelete()} />
                <img
                  src={image === null ? editUser?.image : image}
                  alt="image"
                  className="absolute w-full h-full object-cover"
                />
              </div>

              <div className={image != null && !imageError ? "relative w-28 h-28 mt-4" : "hidden"}>
                <MdClose className="absolute cursor-pointer text-[18px] z-10 p-[2px] bg-red-500 text-white rounded-full border border-red-500 -top-2 -right-2"
                  onClick={() => handleDelete()} />
                <img
                  src={image}
                  alt="image"
                  className="absolute w-full h-full object-cover"
                />
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default UpdateUser;
