import React, { useEffect, useRef, useState } from 'react'
import { IMAGE_ICON } from './icons'
import { toast } from 'react-toastify';

const FileDragAndDrop = ({ onUpload, receivedFile }) => {
  const inputRef = useRef(null);
  const dndRef = useRef(null);
  const initialState = {
    image: '',
  }
  const [preview, setPreview] = useState(initialState);
  const handleInputFile = (e) => {
    const files = e.target.files;
    if (files && files.length) {
      if (files[0].type.includes('jpeg') || files[0].type.includes('jpg') || files[0].type.includes('png') || files[0].type.includes('webp')) {
        onUpload(files);
        setPreview({
          image: URL.createObjectURL(files[0])
        });
      }
      else {
        toast.error('Required file types allowed only.');
      }
    }
  };
  const handleBoxClick = () => {
    inputRef.current.click();
  }

  useEffect(() => {
    const instance = dndRef.current;
    instance.addEventListener('dragover', handleDragOver);
    instance.addEventListener('drop', handleDrop);

    return () => {
      instance.removeEventListener('dragover', handleDragOver);
      instance.removeEventListener('drop', handleDrop);
    };
  }, []);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      if (files[0].type.includes('jpeg') || files[0].type.includes('jpg') || files[0].type.includes('png') || files[0].type.includes('webp')) {
        onUpload(files);
        setPreview({
          image: URL.createObjectURL(files[0])
        });
      }
      else {
        toast.error('Required file types allowed only.');
      }
    }
  };

  // const deleteFile = () => {
  //   setPreview(initialState);
  // }
  // const handleChangeFile = (e) => {
  //   inputRef.current.click();
  //   console.log(e)

  // }

  return (
    <>
      {/* {
        (preview.image) &&
        <div className='flex justify-end'>
          <CustomButton transparent category={'extraSmall'} onClick={handleBoxClick}>Edit</CustomButton>
          <CustomButton transparent category={'extraSmall'} onClick={deleteFile}>Delete</CustomButton>
        </div>} */}

      <div ref={dndRef} className='border-dotted border-2 border-[#A8A8A0] w-[50%] h-[240px] flex justify-center items-center cursor-pointer' onClick={handleBoxClick}>
        <input type='file' accept='image/*' ref={inputRef} className='hidden' onChange={handleInputFile} />

        {(receivedFile || preview.image) && <img src={receivedFile || preview.image} className='w-full h-[234px]' />}
        {
          !receivedFile && !preview.image &&
          <div className='flex flex-col justify-center items-center'>
            <IMAGE_ICON className={'mb-[16px]'} />
            <p className='text-[14px] leading-[20px] mb-[12px]'>Click to upload or drag-drop here</p>
            <p className='text-[11px] leading-[16px]'>Supports JPEG, JPG, PNG, WEBP</p>
          </div>
        }
      </div>
    </>
  )
}

export default React.memo(FileDragAndDrop)
