import React, { useState, useContext, useEffect } from "react";
import { FiCamera } from "react-icons/fi";
import { DataContext } from "../../context/DataState";
import ChangePassword from "./ChangePassword";
import { ToastContainer } from "react-toastify";
import EventDetails from "./EventDetails";
import EventPrivacyPolicy from "./EventPrivacyPolicy";
import EventTermsCondition from "./EventTermsCondition";
import dummyImage from "../Assets/dummyimage.jpg";
import { Link } from "react-router-dom";

const EventInformation = () => {
  const {
    updateUserApi,
    passModal,
    setIsPassModal,
    specificEvent,
    getEventByIdApi,
    getAudienceById,
    adminInfo,
    setAdminInfo,
    disableSave
  } = useContext(DataContext);
  const [state, setState] = useState({});
  // const [bannerImage, setBannerImage] = useState(null);
  // const [bannerImageError, setBannerImageError] = useState(false);
  const [image, setImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [activeTab, setActiveTab] = useState(0);

  // var formatedStartDate = new Date(
  //   specificEvent?.eventStartDate
  // ).toLocaleDateString(
  //   "en-CA"

  // );

  // var formatedEndDate = new Date(
  //   specificEvent?.eventEndDate
  // ).toLocaleDateString("en-CA");

  // //BannerImage validation
  // const onBannerImageChange = (event) => {
  //   const allowedExtensions = ["jpg", "jpeg", "png"];

  //   function isFileExtensionAllowed(fileName, allowedExtensions) {
  //     const fileExtension = fileName.substring(fileName.lastIndexOf(".") + 1);
  //     return allowedExtensions.some((extension) => extension === fileExtension);
  //   }
  //   const isAllowed = isFileExtensionAllowed(
  //     event.target.files[0].name,
  //     allowedExtensions
  //   );

  //   if (event.target.files && event.target.files[0]) {
  //     setBannerImage(URL.createObjectURL(event.target.files[0]));
  //   }

  //   if (!isAllowed) {
  //     setBannerImageError("Please select valid image format.");
  //     return false;
  //   }

  //   if (
  //     event.target.files[0].size &&
  //     event.target.files[0].size / 1024 > 1024
  //   ) {
  //     setBannerImageError("Please select image less than 1 mb");
  //   } else {
  //     setBannerImageError(false);
  //     setState({ ...state, bannerImage: event.target.files[0] });
  //   }
  // };

  const onImageChange = (event) => {
    const allowedExtensions = ["jpg", "jpeg", "png"];

    function isFileExtensionAllowed(fileName, allowedExtensions) {
      const fileExtension = fileName?.substring(fileName.lastIndexOf(".") + 1);
      return allowedExtensions.some((extension) => extension === fileExtension);
    }
    const isAllowed = isFileExtensionAllowed(
      event.target.files[0]?.name,
      allowedExtensions
    );

    if (event.target.files && event.target.files[0] && isAllowed) {
      setImage(URL.createObjectURL(event.target.files[0]));
      setState({ ...state, image: event.target.files[0] });
      setImageError("");
    }

    if (!isAllowed) {
      setImageError("Please select valid image format.");
      return false;
    }

    if (
      event.target.files[0].size &&
      event.target.files[0].size / 1024 > 1024
    ) {
      setImageError("Please select image less than 1 mb");
    } else {
      setImageError("");
      setAdminInfo({ ...adminInfo, image: event.target.files[0] });
    }
  };
  console.log(image, "setted");

  const handleUpdate = () => {
    // updateEventApi(state);
    if (!disableSave)
      updateUserApi(state, adminInfo._id);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    getAudienceById();
    getEventByIdApi(setState({ ...specificEvent }));
  }, []);
  console.log(adminInfo, "admin info");
  return (
    <>
      {passModal ? <ChangePassword /> : ""}
      <>
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-3">
          <div className="pt-3">
            <h1 className=" text-xl text-[#202223] font-semibold">
              Event Information
            </h1>
          </div>
          <div className="flex space-x-4 ">
            <div className="w-[70%] space-y-3 bg-white shadow-md rounded-md">
              <div className="rounded-md">
                {/* <img
                  src={require("../Assets/social wall.png")}
                  className="w-full"
                  alt=""
                /> */}
                {/* <div className="px-4 ">
                  <div className="px-2 py-2 w-[20%] float-right relative -top-32 flex items-center border bg-black rounded-md ">
                    <FiCamera className=" text-white cursor-pointer" />
                    <button className="bg-transparent px-2 border-none text-sm text-white border ">
                      Change Cover
                    </button>
                  </div>
                </div> */}

                <div className="flex px-4 pt-5 gap-5 text-sm ">
                  <div>
                    <button
                      className={`text-[15px] hover:text-theme-color  ${activeTab === 0 && "text-theme-color underline"
                        } `}
                      onClick={() => handleTabChange(0)}
                    >
                      Event details
                    </button>
                  </div>
                  <div>
                    <button
                      className={`text-[15px] hover:text-theme-color  ${activeTab === 1 && "text-theme-color underline"
                        }`}
                      onClick={() => handleTabChange(1)}
                    >
                      Privacy Policy
                    </button>
                  </div>
                  <div>
                    <button
                      className={`text-[15px] hover:text-theme-color  ${activeTab === 2 && "text-theme-color underline"
                        }`}
                      onClick={() => handleTabChange(2)}
                    >
                      Terms and Condition
                    </button>{" "}
                  </div>
                  {/* <div>
                    <button
                      className="text-[15px] hover:text-theme-color hover:underline "
                      onClick={() => handleTabChange(3)}>
                      Reports
                    </button>{" "}
                  </div> */}
                </div>
              </div>
              <div>
                {activeTab === 0 && <EventDetails />}

                {activeTab === 1 && <EventPrivacyPolicy />}
                {activeTab === 2 && <EventTermsCondition />}
              </div>
            </div>
            {/*---- right-side container ------- */}
            <div className="w-[30%] h-[638px] rounded-md bg-white shadow-lg">
              <div>
                <div className="text-center grid justify-items-center  p-5">
                  <label
                    htmlFor="file-input"
                    className="relative cursor-pointer"
                  >
                    <input
                      type="file"
                      className="absolute w-full h-full left-1/2 right-1/2 -translate-x-1/2 -translate-y-1/2 z-[-1] opacity-0"
                      id="file-input"
                      accept="image/jpg, image/jpeg, image/png, image/webp"
                      onChange={(e) => {
                        onImageChange(e);
                      }}
                    />
                    <div className="border-1 border-black  rounded-full relative">
                      <img
                        src={
                          adminInfo?.image === ""
                            ? dummyImage
                            : image !== null && !imageError
                              ? image
                              : adminInfo?.image
                        }
                        alt=""
                        className="w-[100px] h-[100px] rounded-full "
                      />
                      <div className="absolute bg-white rounded-full  right-1 bottom-1 p-1 shadow-md">
                        <FiCamera className="text-black text-[16px]" />
                      </div>
                    </div>
                  </label>
                  {imageError !== "" ? (
                    <span className="text-red-400 text-[10px]">
                      {imageError}
                    </span>
                  ) : null}
                  <p className="text-[11px] font-normal text-gray-400 mt-1">
                    Maximum Upload size: 1Mb
                  </p>
                  <h3 className="text-[16px] font-medium">{adminInfo?.Name}</h3>
                  <p className="font-normal text-[13px] text-[#747474]">
                    {adminInfo?.role}
                  </p>
                </div>
                {/* <span className="text-xs text-red-600 font-normal">
                  {imageError ? imageError : ""}
                </span>
                <div className={image === null && !imageError ? "" : "hidden"}>
                  <img
                    src={image === null ? adminInfo.image : image}
                    alt="banner"
                    className="w-32 h-28 pt-2 object-contain"
                  />
                </div>

                <div className={image != null && !imageError ? "" : "hidden"}>
                  <img
                    src={image}
                    alt="banner"
                    className="w-32 h-28 pt-2 object-contain"
                  />
                </div> */}
              </div>
              <div>
                <form className="px-2">
                  <div className="py-2">
                    <label
                      htmlFor="full-name"
                      className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                    >
                      Full Name
                    </label>
                    <input
                      type="text "
                      id="full-name"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="full-name"
                      value={adminInfo?.Name}
                      disabled
                    />
                  </div>
                  <div className="py-2">
                    <label
                      htmlFor="full-name"
                      className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                    >
                      Business name
                    </label>
                    <input
                      type="text"
                      id="business-name"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="business-name"
                      value={adminInfo?.organization}
                      disabled
                    />
                  </div>
                  <div className="py-2">
                    <label
                      htmlFor="phone-number"
                      className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      id="phone-number"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="phone-number"
                      value={adminInfo?.phone}
                      disabled
                    />
                  </div>
                  <div className="py-2">
                    <label
                      htmlFor="email"
                      className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                    >
                      Email
                    </label>
                    <input
                      type="text "
                      id="email"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="email"
                      value={adminInfo?.email}
                      disabled
                    />
                  </div>
                  <div className="py-2">
                    <label
                      htmlFor="password"
                      className="text-[14px] font-normal text-[#202223] px-1  pb-1"
                    >
                      Password
                    </label>
                    <Link to="">
                      <span
                        className="text-[#fb923c] text-sm font-normal float-right"
                        onClick={() => {
                          setIsPassModal(true);
                        }}
                      >
                        Change password
                      </span>
                    </Link>
                    <input
                      type="text "
                      id="password"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B] text-[14px] font-normal shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="password"
                      placeholder="******"
                    />
                  </div>
                </form>
                <div className="text-center p-3">
                  <button
                    className="text-[14px] font-normal border bg-[#fb923c] px-3 text-white p-1 rounded-md"
                    disabled={imageError !== ""}
                    onClick={() => {
                      handleUpdate();
                    }}
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* <div className="flex w-full bg-white">
        <div className="w-full px-8 py-2 overflow-y-scroll h-screen space-y-3 pt-4">
          <div className="flex">
            <h1 className=" text-xl text-[#202223] font-semibold">
              Event Information
            </h1>
          </div>
          //============
          <h1 className="text-theme-color text-2xl hover:bg-orange-400xl">
            Speaker info{" "}
          </h1>
          //=========
          <div className="flex space-x-10">
            <div className="w-1/2 space-y-3 pt-4">
              <h2 className="font-medium text-lg">Event details</h2>
              <hr />
              <form className="">
                <div className="py-2">
                  <label
                    htmlFor="event-name"
                    className="text-sm font-medium text-[#202223] px-1  pb-1"
                  >
                    Event Name*
                  </label>
                  <input
                    type="text "
                    id="event-name"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    name="eventName"
                    disabled
                    value={state?.eventName}
                  />
                </div>
                <div className="flex space-x-5 py-1">
                  <div className="w-1/2">
                    <label
                      htmlFor="start-date"
                      className="pt-2 px-1 text-sm font-medium text-[#202223] pb-1"
                    >
                      Start at*
                    </label>
                    <input
                      type="date"
                      id="start-date"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="eventStartDate"
                      disabled
                      value={formatedStartDate}
                    />
                  </div>
                  <div className="w-1/2">
                    <label
                      htmlFor="end-date"
                      className="pt-2  px-1 text-sm font-medium text-[#202223]  pb-1"
                    >
                      End at*
                    </label>
                    <input
                      type="date"
                      id="end-date"
                      className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                      name="eventEndDate"
                      value={formatedEndDate}
                      disabled
                    />
                  </div>
                </div>
                <div className="py-1">
                  <label
                    htmlFor="location"
                    className="pt-2 text-sm font-medium text-[#202223]  px-1  pb-1"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    id="location"
                    className="w-full border py-1 px-3  border-gray-300 shadow-sm rounded-md focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    name="location"
                    disabled
                    value={state?.event_venue}
                  />
                </div>
                <div className="py-2">
                  <label className="text-sm font-medium text-[#202223] pt-5  pb-1">
                    Banner image
                  </label>
                  <div className="w-full h-28 border-2 border-gray-400 text-center pt-10 border-dashed hover:border-theme-color rounded-md">
                    <label
                      htmlFor="choose_banner"
                      accept="image/png, image/gif, image/jpeg, image/svg"
                      className="text-theme-color cursor-pointer px-2 py-1  text-sm "
                    >
                      <span className="bg-theme-light-color px-2 py-1">
                        {bannerImage === null
                          ? "Choose banner image"
                          : state?.bannerImage?.name}
                      </span>
                      <p className="text-sm pt-1 text-gray-400">
                        Accepts .jpg and .png
                      </p>
                      <input
                        id="choose_banner"
                        type="file"
                        className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md"
                        name="bannerimage"
                        onChange={(e) => {
                          onBannerImageChange(e);
                        }}
                      />
                    </label>
                  </div>
                  <span className="text-xs text-red-600 font-normal">
                    {bannerImageError ? bannerImageError : ""}
                  </span>
                  <div
                    className={
                      bannerImage === null && !bannerImageError ? "" : "hidden"
                    }
                  >
                    <img
                      src={
                        bannerImage === null ? state?.bannerimage : bannerImage
                      }
                      alt="banner"
                      className="w-32 h-28 pt-2 object-contain"
                    />
                  </div>

                  <div
                    className={
                      bannerImage != null && !bannerImageError ? "" : "hidden"
                    }
                  >
                    <img
                      src={bannerImage}
                      alt="banner"
                      className="w-32 h-28 pt-2 object-contain"
                    />
                  </div>
                </div>
                <div className="py-2">
                  <p>Icon/Logo</p>
                  <div className="w-full text-center pt-10 border-2 border-dashed border-[#BABFC3] rounded-lg hover:border-theme-color">
                    <label
                      htmlFor="uploadLogo"
                      accept="image/png, image/gif, image/jpeg, image/svg"
                      className="text-theme-color cursor-pointer px-2 py-1  text-sm "
                    >
                      <span className="bg-theme-light-color px-2 py-1">
                        {image === null ? "Choose logo" : state?.image?.name}
                      </span>
                      <p className="text-sm pt-1 text-gray-400">
                        Accepts .jpg and .png
                      </p>
                      <input
                        id="uploadLogo"
                        type="file"
                        className="text-theme-color hidden bg-orange-200 px-1 text-sm rounded-md "
                        name="image"
                        onChange={(e) => {
                          onImageChange(e);
                        }}
                      />
                    </label>
                  </div>
                  <span className="text-xs text-red-600 font-normal">
                    {imageError ? imageError : ""}
                  </span>
                  <div
                    className={image === null && !imageError ? "" : "hidden"}
                  >
                    <img
                      src={image === null ? state?.image : image}
                      alt="banner"
                      className="w-32 h-28 pt-2 object-contain"
                    />
                  </div>

                  <div className={image != null && !imageError ? "" : "hidden"}>
                    <img
                      src={image}
                      alt="banner"
                      className="w-32 h-28 pt-2 object-contain"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div className="w-1/2 pt-4 space-y-1">
              <h2 className="pb-2  font-medium text-lg">Others</h2>
              <hr />
              <p className="font-medium text-lg pt-2 pb-2 ">Personal details</p>
              <hr className="pb-2" />

              <label className="text-sm font-medium text-[#202223]">
                Organiser Name
              </label>
              <input
                type="text"
                className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c]  focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                name="OrganizerName"
                disabled
                value={state?.OrganizerName}
              />
              <div className="flex space-x-5 py-2">
                <div className="w-1/2">
                  <label className="pt-2 text-sm font-medium text-[#202223] px-1  pb-1">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    name="phone"
                    disabled
                    value={state?.phone}
                    //---------
                    onChange={(e) => {
                      setState({ ...state, phone: e.target.value });
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <label className="pt-2 text-sm font-medium text-[#202223] px-1 pb-1">
                    Business Name
                  </label>
                  <input
                    type="text"
                    className="w-full border  py-1 px-3 placeholder-[#4B4B4B]  shadow-sm rounded-md  focus-visible:border-[#fb923c] focus-visible:outline-none  focus-visible:shadow-focusshadow focus-visible:border-1"
                    name="business"
                    disabled
                    value={state?.business}
                  />
                </div>
              </div>
              <h1 className="text-lg pt-2 pb-2">Access Information</h1>
              <p className="text-xs text-gray-500 pb-3">
                These details will be use by Admin to the access protal.
              </p>
              <hr />
              <div className="py-1">
                <label className="text-sm font-medium text-[#202223]">
                  Email
                </label>
                <input
                  className="border border-[#AEB4B9] focus:border-theme-color w-full rounded-md placeholder-[#4B4B4B] py-1 px-3"
                  disabled
                  value={state?.email}
                />
              </div>
              <div className="py-1">
                <label className="pt-2 pb-1  text-sm font-medium text-[#202223]">
                  Password
                </label>
                <Link to="">
                  <span
                    className="float-right text-sm font-normal text-theme-color"
                    onClick={() => {
                      setIsPassModal(true);
                    }}
                  >
                    Change password
                  </span>
                </Link>
                <input
                  type="password"
                  className="border border-[#AEB4B9] focus:border-theme-color  w-full rounded-md placeholder-[#4B4B4B] py-1 px-3"
                  disabled
                  placeholder="******"
                />
              </div>
            </div>
          </div>
          <div className="pt-2">
            <button
              type="button"
              className=" px-4 py-2 w-48 text-sm font-medium  bg-theme-color hover:bg-orange-500 text-white rounded"
              onClick={() => handleUpdate()}
            >
              Update
            </button>
          </div>
          //------------
          <button className="bg-theme-color px-7 text-sm py-1 rounded hover:bg-orange-400 text-white ml-3">
            Save
          </button>
          //---------
        </div>
      </div> */}
    </>
  );
};

export default EventInformation;
