import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import dummyImage from "../Assets/dummyimage.jpg";
import { FaCrown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";
import { DataContext } from "../../context/DataState";
import Pagination from "../Pagination/Pagination";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const SpeakersOverviewViewPage = () => {
  const {
    getSingleSpeakertApi,
    singleSpeakerReport,
    pageCount,
    totalCount,
    globalLoader,
    checkResponse,
  } = useContext(DataContext);
  const location = useLocation();
  console.log(location, "location");
  const attendee = location.state.element || location.state;
  const id = attendee._id;

  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const timeconversion = (date) => {
    let nDate = String(date);

    return nDate.slice(11, 16);
  };
  const limit = 10;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  useEffect(() => {
    getSingleSpeakertApi(id, 1, limit);
  }, []);
  
  return (
    <>
      <div className="w-full px-7  overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link
            to={`${!location.state.element ? "/speaker_list" : "/reports"}`}
          >
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">User Information </h1>
          </div>
        </div>
        {globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
            <div className="flex gap-5 bg-white">
              <div className=" w-1/2  m-0 bg-white p-3 rounded">
                <div className=" space-y-3 ">
                  <h2 className="font-semibold text-lg ">User details</h2>
                  <hr className=" border-1 border-[#BDBDBD]" />
                </div>

                <div className=" w-full mt-5 flex gap-3">
                  <div className="text-[#202223]  w-[20%] ">
                    <p className="text-sm  font-medium">User Photo</p>
                    <img
                      src={
                        singleSpeakerReport?.speakerInfo?.image === "" ||
                        singleSpeakerReport?.speakerInfo?.image === undefined
                          ? dummyImage
                          : singleSpeakerReport?.speakerInfo?.image
                      }
                      alt="user-photo"
                      className="w-14 h-14"
                    />
                  </div>
                  <div className="text-[#202223] w-[35%]  ">
                    <p className="text-sm  font-medium">First name </p>
                    <p className="border outline-none px-2 py-2 rounded py-2 text-sm font-normal mt-2 border-[#AEB4B9] w-full">
                      {singleSpeakerReport?.speakerInfo?.speaker_firstname}
                    </p>
                  </div>
                  <div className="text-[#202223] w-[35%] ">
                    <p className="text-sm font-medium">Last name </p>
                    <p className="border px-2 py-2 outline-none rounded text-sm font-normal mt-2 border-[#AEB4B9] w-full">
                      {singleSpeakerReport?.speakerInfo?.speaker_lastname}
                    </p>
                  </div>
                </div>

                <div className=" w-full mt-5 flex gap-3">
                  <div className="text-[#202223] flex-1 w-full ">
                    <p className="text-sm font-medium">Email address </p>
                    <p className="border outline-none text-sm font-normal mt-2 px-2 py-2 rounded border-[#AEB4B9] w-full">
                      {singleSpeakerReport?.speakerInfo?.email}
                    </p>
                  </div>
                  <div className="text-[#202223] flex-1 w-full">
                    <p className="text-sm font-medium">Organization</p>
                    <p className="border outline-none text-sm font-normal mt-2 rounded px-2 py-2 border-[#AEB4B9] w-full">
                      {singleSpeakerReport?.speakerInfo?.organization}
                    </p>
                  </div>
                </div>
              </div>

              <div className=" w-1/2  m-0 bg-white p-3 rounded">
                <div className="text-[#202223] flex-1 w-full mt-12">
                  <p className="text-sm font-medium pt-2">Position</p>
                  <p className="border outline-none text-sm font-normal mt-2 rounded px-2 py-2 border-[#AEB4B9] w-full">
                    {singleSpeakerReport?.speakerInfo?.position}
                  </p>
                </div>

                <div className="text-[#202223]  w-full mt-4 ">
                  <label
                    className="block relative mb-2 text-sm font-medium"
                    typeof="description"
                    htmlFor="description"
                  >
                    Biography
                  </label>
                  <textarea
                    rows="4"
                    value={singleSpeakerReport?.speakerInfo?.Biography}
                    className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-[#AEB4B9] "
                  />
                </div>
              </div>
            </div>

            <div className="bg-white rounded-t mt-3 px-3">
              <h1 className="text-[#141824] font-semibold text-base p-3">
                Speaker
              </h1>
              <table className=" table-auto bg-white text-start shadow-md  rounded-lg ">
                <thead className="cursor-pointer">
                  <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                    <th className="w-[15%] px-4 py-2 rounded relative">
                      Agenda Name
                    </th>

                    <th className="w-[15%] px-4 py-2 ">Date</th>
                    <th className="w-[15%] px-4 py-2 ">Start Time</th>
                    <th className="w-[15%] px-4 py-2">End Time</th>
                    <th className="w-[7%] px-4 py-2 relative">Check In</th>
                    <th className="w-[10%] px-4 py-2 relative">My schedule</th>
                  </tr>
                </thead>
                <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                  {singleSpeakerReport?.agendaInfo?.docs.length == 0 &&
                  checkResponse ? (
                    <tr className="bg-white ">
                      {" "}
                      <h1 className="my-3 ml-4 w-[10rem] text-sm text-gray-800  ">
                        No data found
                      </h1>
                    </tr>
                  ) : (
                    singleSpeakerReport?.agendaInfo?.docs.map((item) => {
                      return (
                        <>
                          <tr className=" border-t text-left text-sm text-[#202223] font-normal  hover:shadow-tableRowsShadow  cursor-pointer">
                            <td className="p-2">{item?.agenda_name}</td>

                            <td className=" py-2 px-4">
                              {" "}
                              {dateConversion(item?.startDate)[0]}
                            </td>

                            <td className=" py-2 px-4">
                              {" "}
                              {item?.endTime !== undefined
                                ? timeconversion(item?.startTime)
                                : ""}
                            </td>
                            <td className=" py-2 px-4">
                              {item?.endTime !== undefined
                                ? timeconversion(item?.endTime)
                                : ""}
                            </td>
                            <td
                              className={`${
                                item.attendee_id.length > 0 ? "underline" : ""
                              } text-theme-color  py-4 flex justify-center`}
                            >
                              <Link
                                to={`${
                                  item.attendee_id.length > 0 ? "/checkin" : ""
                                }`}
                                state={{ item: item }}
                              >
                                <span>{item.attendee_id.length}</span>
                              </Link>
                            </td>

                            <td className=" py-2 px-4 text-center">33</td>
                          </tr>
                        </>
                      );
                    })
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}

        {singleSpeakerReport?.agendaInfo?.docs.length != 0 &&
          !checkResponse && (
            <div className="flex justify-between items-center ">
              <span className="font-[150] text-sm ">{`showing ${firstResultCount} to ${
                pageCount === 1 ? totalCount : secondResultCount
              } of ${totalCount} results`}</span>
              <Pagination
                firstResultCount={firstResultCount}
                setFirstResultCount={setFirstResultCount}
                secondResultCount={secondResultCount}
                setSecondResultCount={setSecondResultCount}
                limit={limit}
                api={getSingleSpeakertApi}
                id={id}
              />
            </div>
          )}
      </div>
    </>
  );
};
export default SpeakersOverviewViewPage;
