import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import { FiTrash, FiEdit2 } from "react-icons/fi";
import DeleteModal from "../DeleteModal/DeleteModal";
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { FaSearch } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'

const Announcement = () => {
  const {
    globalLoader,
    announcementList,
    deleteAnnouncementApi,
    deleteBtn,
    setDeleteBtn,
    deleteBtnId,
    setDeleteBtnId,
    announcementPaginationApi,
    totalCount,
    pageCount,
    checkResponse,
    currentPage,
    searchAnnouncementApi,
   
  } = useContext(DataContext);

  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [searchInput, setSearchInput] = useState("");

  const onKeyUpHandler = (e) => {
    searchAnnouncementApi(e.target.value, currentPage);
  };

  useEffect(() => {
    announcementPaginationApi(1, limit);
  }, []);

  console.log(announcementList, "announcement List");
  console.log(globalLoader, "global loader");
  console.log(checkResponse, "check response");
  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteAnnouncementApi}
          deleteId={deleteBtnId}
          currentPage={currentPage}
          limit={limit}
          secondResultCount={secondResultCount}
          setSecondResultCount={setSecondResultCount}
        />
      ) : (
        ""
      )}
      <div className="w-full  px-8 pt-[26px] overflow-y-scroll h-screen space-y-4">
        <h1 className="text-xl text-[#202223] font-semibold">Announcement</h1>
        <div className="flex items-center justify-between">
          <div className="">
            <Link to="/add-announcement">
              <button className="border  bg-theme-color text-white px-4 py-2 rounded text-sm">
                Add New Announcement
              </button>
            </Link>
          </div>
          <div className="float-right flex">
            <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
            <input
              type="text"
              placeholder="Search"
              className="py-2 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
              value={searchInput}
              onChange={(e) => {
                setSearchInput(e.target.value);
              }}
              onKeyUp={(e) => {
                onKeyUpHandler(e);
              }}
            />
          </div>
        </div>

        {announcementList.length === 0 && checkResponse === true ? (
          <NoDataModal />
        ) : globalLoader ? (
          <GlobalLoader />
        ) : (
          <>
            <table className="w-full table-auto bg-white shadow-md rounded-md ">
              <thead className="cursor-pointer">
                <tr className="text-left  bg-theme-light-color text-theme-color text-xs font-normal">
                  <th className=" w-[20%] p-2 rounded-tl-md ">Title</th>
                  <th className=" w-[45%] p-2">Description</th>
                  <th className="w-[20%] p-2 ">Last Modified</th>
                  <th className="w-[10%] p-2 ">Status</th>
                  <th className="w-[15%] text-center rounded-tr-md p-2">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                {announcementList?.map((item) => {
                  return (
                    <tr
                      className="border-t cursor-pointer group hover:shadow-tableRowsShadow"
                      key={item?._id}
                    >
                      <td className="p-2 text-sm text-[#202223] leading-5">
                        <p>{item?.title}</p>
                      </td>
                      <td className="p-2 text-sm text-[#202223] leading-5">
                        <p>{item?.description}</p>
                      </td>
                      <td className="p-2 text-sm">
                        {" "}
                        <p>{`${
                          item?.Date === undefined && item?.Time === undefined
                            ? ""
                            : item.Date + " " + item?.Time
                        } `}</p>
                      </td>
                      <td className="p-2">
                        <span
                          className={` px-2 py-1 rounded-xl text-black text-[12px] ${
                            item?.status === "Scheduled"
                              ? "bg-[#FFEA8A]"
                              : "bg-[#AEE9D1]"
                          } `}
                        >
                          {item?.status}
                        </span>
                      </td>
                      <td className="">
                        <div className="flex items-center  justify-center  gap-2 text-black">
                          <Link
                            to={`/update-announcement/${item?._id}`}
                            state={item}
                          >
                            <FiEdit2 
                              data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                             <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                          </Link>

                          <FiTrash
                           data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                            className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                            onClick={() => {
                              setDeleteBtn(true);
                              setDeleteBtnId(item?._id);
                            }}
                          />
                            <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                        </div>
                      </td>
                    </tr>
                  );
                })}

                                 
                   



                   
              </tbody>
            </table>
            {
              !checkResponse &&   <div className="flex justify-between items-start ">
              <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
                pageCount === 1 ? totalCount : secondResultCount
              } of ${totalCount} results`}</span>
              <Pagination
                firstResultCount={firstResultCount}
                setFirstResultCount={setFirstResultCount}
                secondResultCount={secondResultCount}
                setSecondResultCount={setSecondResultCount}
                limit={limit}
                api={announcementPaginationApi}
              />
            </div>
            }
          
          </>
        )}
      </div>
    </>
  );
};

export default Announcement;
