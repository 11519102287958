import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { FaSearch ,FaFilter} from "react-icons/fa";
import NoContactModal from "./NoContactModal";

const AddContact=()=>{
  const location=useLocation()
  const isContactActive=location.state
  
  return (
    <>
     <div className="w-full px-7   overflow-y-scroll h-screen space-y-3 ">
        <div className="flex pt-3">
          <Link to={""}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2">
            <h1 className=" text-xl px-2 ">HDFC Bank </h1>
          </div>
        </div>
        <div className=" flex justify-between space-x-2 pt-4 items-start flex-wrap">
            <div className="flex gap-1">
              
              <button
                className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm"
                
              >
               Send Email
              </button>
              <Link to={"/import-attendees"}>
                <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                  Import
                </button>
              </Link>
              <Link to={""}>
                <button className="border   border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white  rounded text-sm">
                 Add Contacts
                </button>
              </Link>
            </div>
            <div className="">
            <div className="flex">
              
              <button
                className="border flex gap-2 items-center  border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white rounded-l text-sm"
                
              >
              <FaFilter className="text-gray-500"/><span>Status</span>
              </button>
              <Link to={""}>
                <button className="border  flex gap-2 items-center border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium bg-white   text-sm">
                <FaFilter className="text-gray-500"/><span>Registration</span>
                </button>
              </Link>
              <Link to={""}>
              
               
                <button className="border flex gap-2 items-center border-[#BABFC3]  px-4 py-2 text-[#202223] font-medium rounded-r bg-white text-sm">
               <FaSearch className="text-gray-500"/><span>Search</span>
                </button>
              
               
              </Link>
            </div>
            </div>
           
          </div>
          <NoContactModal isContactActive={isContactActive}/>
      </div>
    </>
  )
  }
  export default AddContact;