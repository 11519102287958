import React, { useContext, useEffect, useState } from "react";
import { FaSearch, FaSortUp, FaSortDown } from "react-icons/fa";
import { AiOutlineEye } from "react-icons/ai";

import { Link } from "react-router-dom";

import { FiTrash, FiEdit2 } from "react-icons/fi";
import { ToastContainer } from "react-toastify";
import Pagination from "../Pagination/Pagination";

import { DataContext } from "../../context/DataState";
import GlobalLoader from "../GlobalLoader/GlobalLoader";
import DeleteModal from "../DeleteModal/DeleteModal";
import NoDataModal from "../NoDatatModal/NoDataModal";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { Tooltip } from 'react-tooltip'

const AddAgenda = () => {
  const {
    setAgendaList,
    agendaPaginationApi,
    agendaList,
    globalLoader,
    deleteBtn,
    setDeleteBtn,
    deleteAgendaApi,
    deleteBtnId,
    setDeleteBtnId,
    searchAgendaApi,
    totalCount,
    pageCount,
    currentPage,
    setCurrentPage,
    checkResponse,
    changeAgendaStatusApi
  } = useContext(DataContext);
  const [searchInput, setSearchInput] = useState("");
  const [isActiveSort, setIsActiveSort] = useState(false);
  const [activeSortProperty, setActiveSortProperty] = useState(false);
  const limit = 15;
  const [firstResultCount, setFirstResultCount] = useState(1);
  const [secondResultCount, setSecondResultCount] = useState(limit);
  const [toggle,setToggle]=useState(null)
  const previewObj = {
    title: "Title",
    date: "Date",
    startTime: "Start Time",
    endTime: "End Time",
  };
  let handleResponse=(id)=>{
    if(toggle===id){
        setToggle(!null);
        return false 
    }
   setToggle(id); 
  }
  
  const dateConversion = (date) => {
    const nDate = new Date(date).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    });

    return nDate.split(",");
  };

  const timeconversion = (date) => {
    let nDate = String(date);

    return nDate.slice(11, 16);
  };
  timeconversion();
  const onKeyUpHandler = (e) => {
    searchAgendaApi(e.target.value);
  };

  const sortData = (property) => {
    let isAscending = true;
    if (activeSortProperty === property) {
      isAscending = !isActiveSort;
    }

    const sortedData = [...agendaList].sort((a, b) => {
      const comparison = a[property].localeCompare(b[property]);
      return isAscending ? comparison : -comparison;
    });

    setAgendaList(sortedData);
    setActiveSortProperty(property);
    setIsActiveSort(isAscending);
  };
  const getSortArrowClassName = (property, direction) => {
    if (isActiveSort && activeSortProperty === property) {
      return direction === "asc" ? "text-black" : "text-gray-500";
    }
    return direction === "desc" ? "text-black" : "text-gray-500";
  };

  useEffect(() => {
    setCurrentPage(1);
    agendaPaginationApi(1, limit);
  }, []);
console.log(checkResponse,"response")
  return (
    <>
      {deleteBtn ? (
        <DeleteModal
          deleteApi={deleteAgendaApi}
          deleteId={deleteBtnId}
          currentPage={currentPage}
          limit={limit}
          secondResultCount={secondResultCount}
          setSecondResultCount={setSecondResultCount}
        />
      ) : (
        ""
      )}
      <div className="flex w-full">
        <div className="w-full px-7 py-2 overflow-y-scroll h-screen space-y-4">
          <div className="pt-3 ">
            <h1 className="text-xl text-[#202223] font-semibold ">Agenda</h1>
            <div className=" flex justify-between space-x-2 pt-4">
              <div className="space-x-1">
                <Link to="/new_session">
                  <button className="border shadow-btnshd bg-theme-color text-white   px-4 py-2 rounded text-sm">
                    Add New Agenda
                  </button>
                </Link>
               
              </div>
              <div className="float-right flex">
                <FaSearch className="search-icon relative top-[0.7rem]  left-6 text-gray-500 " />
                <input
                  type="text"
                  placeholder="Search"
                  className="py-1 px-8 text-sm border placeholder-[#202223] text-[#202223] border-[#AEB4B9] rounded   outline-none"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value);
                  }}
                  onKeyUp={(e) => {
                    onKeyUpHandler(e);
                  }}
                />
              </div>
            </div>
          </div>
          {/*--------- table list--------- */}
          {agendaList?.length === 0 && checkResponse === true ? (
            <NoDataModal />
          ) : globalLoader ? (
            <GlobalLoader />
          ) : (
            <>
              <table className="w-full table-auto bg-white shadow-md  rounded-lg">
                <thead className="cursor-pointer">
                  <tr className=" text-left  bg-theme-light-color text-theme-color text-xs font-normal ">
                    <th className="w-[20%] py-2 px-4 rounded-tl-md relative">
                      Session Name
                      <div className="px-[87px]">
                        <span className="">
                          <FaSortUp
                            className={`top-[30%] absolute ${getSortArrowClassName(
                              "agenda_name",
                              "asc"
                            )}`}
                            onClick={() => sortData("agenda_name")}
                          />
                          <FaSortDown
                            className={`top-[40%] absolute ${getSortArrowClassName(
                              "agenda_name",
                              "desc"
                            )}`}
                            onClick={() => sortData("agenda_name")}
                          />
                        </span>
                      </div>
                    </th>
                    {/* <th className="w-[15% py-2 px-2 ">Description</th> */}
                    <th className="w-[15%] py-2 px-4 relative">
                      Speakers
                      <div className="px-[60px]">
                        <span className="">
                          <FaSortUp
                            className={`top-[30%] absolute ${getSortArrowClassName(
                              "speaker",
                              "asc"
                            )}`}
                            onClick={() => sortData("speaker")}
                          />
                          <FaSortDown
                            className={`top-[40%] absolute ${getSortArrowClassName(
                              "speaker",
                              "desc"
                            )}`}
                            onClick={() => sortData("speaker")}
                          />
                        </span>
                      </div>
                    </th>
                    <th className="w-[10%] py-2 px-4">Date</th>
                    <th className="w-[10%]  py-2 px-4">Time</th>
                    <th className="w-[10%] py-2 px-4">Location</th>
                    <th className="w-[10%] text-center py-2 px-4">Check In</th>
                    <th className="w-[6%] text-start px-4">Status</th>
                    <th className="w-[8%] text-start rounded-tr-md py-2 px-2">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="[&>*:nth-child(even)]:bg-[#FAFBFB]">
                 
                  {agendaList.map((item) => {
                    return (
                      <>
                        <tr
                          className="border-t text-left text-sm text-[#202223] font-normal hover:shadow-tableRowsShadow  cursor-pointer group"
                          key={item?._id}
                        >
                          <td className=" py-2 px-4">{item?.agenda_name}</td>
                          {/* <td className="py-2 px-4 ">{item?.description}</td> */}
                          <td className="py-2 px-4">
                            {" "}
                            <div className="flex items-center">
                              {item?.speaker
                                .slice(0, 3)
                                .map((element, index) => {
                                  return (
                                    <div
                                      className="relative right-2 "
                                      key={index}
                                    >
                                      <img
                                        src={element?.image}
                                        className="w-10 h-10  rounded-full object-fill speaker profile"
                                        alt=""
                                      />
                                    </div>
                                  );
                                })}

                              {item?.speaker?.length >= 4 && (
                                <span className="text-xs whitespace-nowrap text-[#202223]  font-medium">{`+ ${
                                  item?.speaker?.length - 3
                                } more`}</span>
                              )}
                            </div>
                          </td>
                          <td className="py-2 px-4">
                            {dateConversion(item?.startDate)[0]}
                          </td>
                          <td className="py-2 px-4 ">
                            {item?.startTime !== undefined ||
                            item?.endTime !== undefined
                              ? timeconversion(item?.startTime) +
                                " - " +
                                timeconversion(item?.endTime)
                              : ""}
                          </td>
                          <td className="py-2 px-4">{item?.Location}</td>

                          <td
                            className={`${
                              item.attendee_id?.length > 0 ? "underline" : ""
                            } text-theme-color   pt-4 flex justify-center`}
                          >
                            <Link
                              to={`${
                                item.attendee_id?.length > 0 ? "/checkin" : ""
                              }`}
                              state={item}
                            >
                              <span>{item.attendee_id?.length}</span>
                            </Link>
                          </td>
                          <td className="py-2 px-4">
                            <div className="flex justify-center items-center">
                              <div>
                                <label class="relative inline-flex items-center me-5 cursor-pointer">
                                  <input
                                    type="checkbox"
                                    value=""
                                    class="sr-only peer"
                                    checked={toggle==true?true:toggle==false?false:item?.status=="Active"?true:false}
                                    onChange={(e)=>{
                                     
                                    
                                    changeAgendaStatusApi(e.target.checked,item._id,currentPage,
                                      limit,
                                      secondResultCount,
                                      setSecondResultCount)
                                    
                                    }
                                   
                                  }
                                  />
                                  <div class="w-10 h-6 bg-gray-200 rounded-full peer dark:bg-gray-400 peer-focus:ring-4 peer-focus:ring-white  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
                                  {/* <span class="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">Orange</span> */}
                                </label>
                              </div>
                            </div>
                          </td>

                          <td className="">
                            {" "}
                            <div className="flex items-center  justify-center   text-black">
                            <Link to={"/agendaoverview-view"} state={item}>
                            <HiOutlineChartSquareBar
                             data-tooltip-id="my-tooltip" data-tooltip-content="Report"
                            
                            size={30} className='hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] ' />
                            <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </Link>
                              <Link to={`/agenda/${item?._id}`} state={item}>
                                <AiOutlineEye 
                                  data-tooltip-id="my-tooltip" data-tooltip-content="View"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[32px] " />
                                 <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                              </Link>
                              <Link
                                to={`/update-agenda/${item?._id}`}
                                state={item}
                              >
                                <FiEdit2 
                                 data-tooltip-id="my-tooltip" data-tooltip-content="Edit"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] " />
                                 <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                              </Link>
                              <FiTrash
                                data-tooltip-id="my-tooltip" data-tooltip-content="Delete"
                                className="hover:text-theme-color hover:bg-theme-light-color hover:rounded-full px-[6px] py-0 text-[28px] "
                                onClick={() => {
                                  setDeleteBtn(true);
                                  setDeleteBtnId(item?._id);
                                }}
                              />
                               <Tooltip  place="top" id="my-tooltip"  className="custom-tooltip" />
                            </div>
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              {
                 !checkResponse &&  <div className="flex justify-between items-start ">
                <span className="font-[150] text-sm mt-1">{`showing ${firstResultCount} to ${
                  pageCount === 1 ? totalCount : secondResultCount
                } of ${totalCount} results`}</span>

                <Pagination
                  firstResultCount={firstResultCount}
                  setFirstResultCount={setFirstResultCount}
                  secondResultCount={secondResultCount}
                  setSecondResultCount={setSecondResultCount}
                  limit={limit}
                  api={agendaPaginationApi}
                />
              </div>
              }
             
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AddAgenda;
