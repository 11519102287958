import { React, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { DataContext } from "../../context/DataState";
import { ToastContainer } from "react-toastify";

const AddAnnouncements = () => {
  const [count, setCount] = useState(0);
  const { addAnnouncementApi, disableSave,isSpinner } = useContext(DataContext);
  const [state, setState] = useState({
    title: "",
    description: "",
    Date: "",
    Time: "",
  });
  const [checkbox, setCheckbox] = useState(false);

  let todayDate=new Date().toISOString().split('T')[0]
 
  

  const handleChange = (e) => {
    setState({ ...state, description: e.target.value });
    const textLength = e.target.value.split("").length;
    setCount(textLength);
  };

  const btnDisabled = state.title !== "" && state.description !== "";
  const handleSubmit = () => {
    if (!isSpinner) addAnnouncementApi(state);
  };

  return (
    <>
      <div className="w-full  px-8 pt-[26px] overflow-y-scroll h-screen space-y-4">
        <div className="flex">
          <Link to={"/announcement"}>
            <div className="w-8 pt-1">
              <button className="border p-2">
                <FaArrowLeft />
              </button>
            </div>
          </Link>
          <div className="p-2 ">
            <h1 className="px-2 text-xl">Add Announcement</h1>
          </div>
        </div>
        <div className="flex flex-col p-3 border-[#D1D9E7] bg-white rounded-lg ">
        <form className="">
          <div>
            <label
              className="block mb-2 text-sm"
              typeof="heading"
              htmlFor="heading"
            >
              Heading *
            </label>
            <input
              type="name"
              placeholder="Enter your heading"
              className="mt-1 block w-full px-3 py-2 bg-white border h-9 placeholder:text-slate-400 text-sm rounded-md   placeholder-slate-400  focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-300"
              value={state.title}
              onChange={(e) => {
                setState({ ...state, title: e.target.value });
              }}
            />
          </div>
          <div className="mt-3">
            <label
              className="block relative mb-2 text-sm"
              typeof="description"
              htmlFor="description"
            >
              Description *
            </label>
            <textarea
              placeholder="Enter description about your heading"
              rows="8"

              className="mt-1 block w-full  px-3 py-1 bg-white placeholder:text-slate-400 text-sm border resize-none rounded-md   placeholder-slate-400 focus:outline-none focus:border-theme-color  focus:ring-theme-color focus-visible:border-[#fb923c]  focus-visible:outline-none shadow-sm   focus-visible:shadow-focusshadow focus-visible:border  border-gray-200 "
              maxLength={180}
              value={state.description}
              onChange={(e) => {
               
                handleChange(e);
              }}
            />
            <div className="mt-1 flex justify-between" >
            <span className="text-sm text-[#6D7175]  ">
              Provide description
            </span>
            <span className="bottom-2 text-sm ">
                      {count}/180
                    </span>
            </div>
            
          </div>

          <div className="flex pt-4 gap-2 items-center">
            <h3 className="text-black text-[16px] font-medium capitalize leading-tight ">
              Schedule for later
            </h3>

            <input
              type="checkbox"
              className="accent-theme-color w-4 h-4 rounded-md text-sm"
              name=""
              id=""
              onChange={(e) => {
                e.target.checked ? setCheckbox(true) : setCheckbox(false);
              }}
            />
          </div>
          <div>
            <input
              type="datetime-local"
              className={`border p-2 rounded-md mt-2 w-1/3 ${
                !checkbox ? "hidden" : ""
              }`}
              min={todayDate+ " 00:00:00"}
              name=""
              id=""
              onChange={(e) => {
                const Date = e.target.value.slice(0, 10);

                const Time = e.target.value.slice(11, 16);
                setState({ ...state, Date: Date, Time: Time });
              }}
            />
          </div>
        </form>
        <div className="pt-6 flex gap-3">
          <Link to={"/announcement"}>
            <button className="border-2 px-8 text-sm py-2 hover:bg-gray-300 rounded">
              Back
            </button>
          </Link>
          <button type="button" 
disabled={!btnDisabled}
onClick={() => {
 handleSubmit();
}}
className="py-2 px-4 w-24 flex justify-center items-center disabled:opacity-50  bg-[#FF8531] hover:bg-orange-400  text-white  transition ease-in duration-200 text-center text-base font-semibold shadow-md  rounded max-w-md">
   <svg width="40" height="20" fill="currentColor" className={`${isSpinner? "" :"hidden"} mr-2 animate-spin`} viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
        <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
        </path>
    </svg>
    Save
</button>
        </div>

        </div>
        
      </div>
    </>
  );
};

export default AddAnnouncements;
